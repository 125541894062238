import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {BlockUI} from "primereact/blockui";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {ProdutoAutoComplete} from "../../../components/autocomplete/produtoAutoComplete";
import {estoqueMovimentoService} from "../../../service/estoqueMovimentoService";
import {useAuth} from "../../../context/AuthContext";
import {Button} from "../../../components/button";
import {InputNumber} from "../../../components/inputnumber";
import {UsuarioAutoComplete} from "../../../components/autocomplete/usuarioAutoComplete";
import {InputText} from "../../../components/inputtext";
import moment from "moment";
import {InformationDialog} from "../../../components/dialog/InformationDialog";
import {DialogContext} from "../../../utils/dialogContext";
import {estoqueService} from "../../../service/estoqueService";

export function EditarEstoqueRecondicionado() {

    const {id} = useParams();
    const {usuario} = useAuth();
    const navigate = useNavigate();
    const {showDialog} = useContext(DialogContext);
    const [messages, setMessages] = useState({});
    const [blocked, setBlocked] = useState(false);
    const [movimento, setMovimento] = useState({
        ...estoqueMovimentoService.criar(),
        responsavel: usuario
    });

    useEffect(() => {
        if (id !== "new") {
            setBlocked(true);
            estoqueMovimentoService.buscar(id).then(movimento => {
                setMovimento(movimento);
                setBlocked(false);
            });
        } else {
            setBlocked(true);
            estoqueService.listar([`codigo=3`]).then(estoques => {
                handleChange({name: "estoque", value: estoques[0]});
                setBlocked(false);
            });
        }
    }, [id]);

    function handleChange(event) {
        setMovimento(prevMovimento => ({...prevMovimento, [event.name]: event.value}));
    }

    function handleVoltar() {
        navigate(-1);
    }

    function handleSalvar() {
        const messages = estoqueMovimentoService.validar(movimento);
        if (messages.isEmpty()) {
            setBlocked(true);
            estoqueMovimentoService.salvar(movimento).then(handleVoltar);
        } else {
            setMessages(messages);
            showDialog(<InformationDialog header="Aviso" message="Por favor, preencha os campos obrigatórios."/>);
        }
    }

    return (
        <BlockUI blocked={blocked}>
            <Panel header="Movimento de Estoque de Peça Recondicionada">
                <PanelContent>
                    <InputText col={2} showTime label="Data" value={moment(movimento.data).format("DD/MM/YYYY HH:mm")} readOnly/>
                    <InputNumber invalid={messages.quantidade} required readOnly={movimento.id} label="Quantidade" col={1} name="quantidade" value={movimento.quantidade} onChange={handleChange}/>
                    <ProdutoAutoComplete invalid={messages.produto} required readOnly={movimento.id} col={5} name="produto" value={movimento.produto} onChange={handleChange}/>
                    <UsuarioAutoComplete readOnly value={movimento.responsavel} label="Responsável" col={4}/>
                    <InputText readOnly={movimento.id} col={12} label="Discriminação (Detalhamento do Movimento)" value={movimento.descricao} name="descricao" onChange={handleChange}/>
                </PanelContent>
                <PanelFooter>
                    <Button disabled={movimento.id || blocked} label="Salvar" icon="fas fa-save" success onClick={handleSalvar}/>
                    <Button onClick={handleVoltar} secondary label="Voltar" icon="fas fa-arrow-left"/>
                </PanelFooter>
            </Panel>
        </BlockUI>
    );

}
