import React, {useEffect, useState} from 'react';
import {FormEdit} from "../../components/form/Edit";
import {useParams} from "react-router-dom";
import {InputText} from "../../components/inputtext";
import {produtoService} from '../../service/produtoService';
import {useAuth} from "../../context/AuthContext";
import {FiltroEquipamentoEspecial, yesNoOptions} from "../../service/ordemServicoService";
import {SelectButton} from "../../components/selectbutton";

export function EditarProduto() {

    const {id} = useParams();
    const {roles} = useAuth();
    const [produto, setProduto] = useState(produtoService.criar());

    function handleChange(event) {
        setProduto({...produto, [event.name]: event.value});
    }

    useEffect(() => {
        if (id !== "new") {
            produtoService.buscar(id).then(setProduto);
        }
    }, [id]);

    return (
        <FormEdit podeDeletar={false} podeSalvar={!!roles.PROC} descricao="Produto" service={produtoService} value={produto} blocked={id !== "new" && !produto.id}>
            <InputText col={2} label="Produto" name="produto" value={produto?.vwProduto?.produtoId} readOnly/>
            <InputText col={6} label="Descrição" name="descricao" value={produto?.vwProduto?.descricao} readOnly/>
            <InputText col={2} label="NCM" name="ncm" value={produto?.vwProduto?.ncm} readOnly/>
            <InputText col={2} label="Unidade" name="unidade" value={produto?.vwProduto?.unidade} readOnly/>
            <InputText col={4} label="Part Number" name="partNumber" value={produto?.vwProduto?.partNumber} readOnly/>
            <InputText col={2} label="Saldo Disponível Geral" name="saldoDisponivel" value={produto?.vwProduto?.saldoDisponivel} readOnly/>
            <InputText col={2} label="Saldo Disponível Laboratório" name="saldoDisponivelLaboratorio" value={produto?.vwProduto?.saldoDisponivelLaboratorio} readOnly/>
            <InputText col={2} label="Saldo Disponível Técnico" name="saldoDisponivelTecnico" value={produto?.vwProduto?.saldoDisponivelTecnico} readOnly/>
            <SelectButton name="equipamentoEspecial" label="Equipamento Especial" options={yesNoOptions} col={2} value={produto.equipamentoEspecial} onChange={handleChange}/>
        </FormEdit>
    );

}
