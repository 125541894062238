import React, {useState} from "react";
import {Dialog, DialogFooter} from "../../../components/dialog";
import {PanelContent} from "../../../components/panel";
import {Button} from "../../../components/button";
import {DataTable} from "../../../components/datatable";
import {Column} from "primereact/column";
import moment from "moment";
import {If} from "../../../components/conditional/If";

export function HistoricoMovimentacaoEstoqueRecondicionado({movimentos}) {

    const [visible, setVisible] = useState(true);

    function handleClose() {
        setVisible(false);
    }

    return (
        <Dialog visible={visible} style={{width: "950px"}} onHide={handleClose} header="Histórico de Movimentação de Estoque Recondicionado">
            <PanelContent>
                <div className="col-12">
                    <DataTable value={movimentos}>
                        <Column sortable style={{width: "10em", textAlign: "center"}} header="Data" field="data" body={m => moment(m.data).format("DD/MM/YYYY HH:mm")}/>
                        <Column sortable field="quantidade" style={{width: "10em"}} header="Movimento" body={m => (
                            <div>
                                <If condition={m.quantidade > 0}>
                                    <div style={{textAlign: "right"}}>{Math.abs(m.quantidade)} <i className="fas fa-plus-circle" style={{color: "#4CAF50"}}/></div>
                                </If>
                                <If condition={m.quantidade < 0}>
                                    <div style={{textAlign: "right"}}>{Math.abs(m.quantidade)} <i className="fas fa-minus-circle" style={{color: "#F44336"}}/></div>
                                </If>
                            </div>
                        )}/>
                        <Column sortable header="Responsável" field="responsavel.autoComplete"/>
                        <Column style={{textAlign: "right"}} sortable header="OS" field="os" body={m => m.os || "Manual"}/>
                        <Column sortable header="Discriminação" field="descricao" body={m => m.descricao || "Registro manual"}/>
                    </DataTable>
                </div>
            </PanelContent>
            <DialogFooter>
                <Button icon="fas fa-times" onClick={handleClose} label="Fechar" secondary/>
            </DialogFooter>
        </Dialog>
    );

}
