import React, {useContext, useEffect, useState} from "react";
import {Column} from "primereact/column";
import {ordemServicoItemPecaStatus, ordemServicoItemService} from "../../service/ordemServicoItemService";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {InputNumber} from "../../components/inputnumber";
import {DataTable} from "../../components/datatable";
import {useAuth} from "../../context/AuthContext";
import {Button} from "../../components/button";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {DialogContext} from "../../utils/dialogContext";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {produtoService} from "../../service/produtoService";
import {BlockUI} from "primereact/blockui";
import {InputText} from "../../components/inputtext";

export function EditarPecas({ordemServico, setOrdemServico, messages}) {

	const {roles} = useAuth();
	const {showDialog} = useContext(DialogContext);
	const [blocked, setBlocked] = useState(false);
	const [sequencia, setSequencia] = useState(0);

	useEffect(() => {
		if (ordemServico.itens[0].pecas?.length) {
			setSequencia(Math.max(...ordemServico.itens[0].pecas.map(osip => osip.sequencia) || 0));
		}
	}, [ordemServico.id]);

	function bloquearEdicao(osip) {
		return (
			ordemServico.operacao === "LABORATORIO"
			&& (
				(!roles.OSSU && ordemServico.itens[0].orcamentos?.some(osio => osio.status === "APROVADO"))
				|| ordemServico.faturamentoConfirmado
				|| ["MOVIMENTADO", "CANCELADO"].includes(osip.status)
			)
		);
	}

	const [pecasSelecionadas, setPecasSelecionadas] = useState([]);

	const pecaControlSet = (
		<div>
			<Button disabled={(!roles.OSSU && ordemServico.itens[0].orcamentos?.some(osio => osio.status === "APROVADO")) || ordemServico.faturamentoData} icon="pi pi-plus" onClick={adicionarPeca}/>
			<Button disabled={(!roles.OSSU && ordemServico.itens[0].orcamentos?.some(osio => osio.status === "APROVADO")) || !pecasSelecionadas?.length || ordemServico.faturamentoData} onClick={removerPecas} danger icon="pi pi-minus"/>
		</div>
	);

	function adicionarPeca() {
		setOrdemServico(prevOrdemServico => {
			const peca = ordemServicoItemService.criarPeca();
			peca.sequencia = sequencia + 1;
			prevOrdemServico.itens[0].pecas.push(peca);
			return {...prevOrdemServico, _alterado: true};
		});
		setSequencia(sequencia + 1);
	}

	function removerPecasSelecionadas() {
		if (pecasSelecionadas.some(pc => ["MOVIMENTADO", "CANCELADO"].includes(pc.status))) {
			showDialog(<InformationDialog header="Informação" message="Não é possível remover itens com movimentação de estoque"/>);
			return;
		}
		setOrdemServico(prevOrdemServico => {
			prevOrdemServico.itens[0].pecas = prevOrdemServico.itens[0].pecas.filter(osi => !pecasSelecionadas.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
			if (!prevOrdemServico.itens[0].id) {
				prevOrdemServico.itens[0].pecas.forEach((osi, i) => osi.sequencia = i + 1);
			}
			return {...prevOrdemServico, _alterado: true};
		});
		setPecasSelecionadas([]);
	}

	function removerPecas() {
		if (pecasSelecionadas.some(ps => ps.produto || ps.quantidade || ps.valorUnitario)) {
			showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover as peças selecionadas?" onYes={() => {
				removerPecasSelecionadas();
			}}/>);
		} else {
			removerPecasSelecionadas();
		}
	}

	const pecaHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={5} header={pecaControlSet}/>
			</Row>
			<Row>
				<Column selectionMode="multiple" headerStyle={{width: "3em"}}/>
				<Column style={{width: "1em"}} header="Item" />
				<Column style={{width: "3em"}} header="Status"/>
				<Column header="Dados Principais"/>
				<Column style={{width: "14em"}} header="Quantidade"  alignHeader={"center"}/>
			</Row>
		</ColumnGroup>
	);

	const pecaFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={5} footer={pecaControlSet}/>
			</Row>
		</ColumnGroup>
	);

	async function handleChangePeca(event) {
		switch (event.name) {
			case "quantidade":
			case "produto":
				const peca = ordemServico.itens[0].pecas[event.index];
				peca[event.name] = event.value;
				if (peca.produto?.id) {
					setBlocked(true);
					const produto = await produtoService.buscar(peca.produto?.id);
					const resposta = await produtoService.buscarPreco({
						produto: peca.produto.id,
						cliente: ordemServico.cliente?.id,
						ordemServico: ordemServico.id,
						contrato: ordemServico.contrato?.id,
						equipamento: ordemServico.itens[0].equipamento?.id,
						data: ordemServico.inicio,
						mauUso: ordemServico.itens[0].flags?.includes("MAU_USO"),
						quantidade: ordemServico.itens[0].pecas[event.index].quantidade
					});
					setOrdemServico(prevOrdemServico => {
						try {
							peca.valorUnitario = resposta.valorUnitario;
							peca.valorTabela = resposta.valorTabela;
							peca.origemPreco = resposta.origem;
							peca.valorTotal = peca.valorUnitario * peca.quantidade;
							peca.valorFranquia = peca.valorTabela * peca.quantidade;
							peca.produto = produto;
							if (resposta.listaPrecoItemId) {
								peca.listaPrecoItem = {id:resposta.listaPrecoItemId};
							}
							
							if (prevOrdemServico.operacao === "CAMPO" && peca.status === "SOLICITADO") {
								if (peca.quantidade <= produto.vwProduto.saldoDisponivelTecnico) {
									peca.status = "MOVIMENTADO";
								} else if (peca.quantidade > produto.vwProduto.saldoDisponivel) {
									peca.status = "COMPRAS";
								}
							}
						} catch (error) {
							console.error(error);
						} finally {
							setBlocked(false);
						}
						return {...prevOrdemServico, _alterado: true};
					});
					return;
				}
				break;
		}
		setOrdemServico(prevOrdemServico => {
			prevOrdemServico.itens[0].pecas[event.index][event.name] = event.value;
			return {...prevOrdemServico, _alterado: true};
		});
	}

	return (
		<div className="col-12">
			<BlockUI blocked={blocked}>
				<DataTable paginator
				           selection={pecasSelecionadas}
				           onSelectionChange={e => setPecasSelecionadas(e.value)}
				           value={ordemServico.itens[0].pecas}
				           headerColumnGroup={pecaHeaderGroup}
				           footerColumnGroup={pecaFooterGroup}
				           selectionMode="checkbox"
				           header="Peças"
				>
					<Column selectionMode="multiple"/>
					<Column header="Item" field="sequencia" style={{textAlign: "center"}}/>
					<Column header="Status" field="status" style={{textAlign: "center"}} body={(osip, c) => {
						return (
							<span>
								{ordemServicoItemPecaStatus[osip?.status]}<br/>
								{osip.estoque === "RECONDICIONADAS" ? <><i title="Peça recondicionada" className="fas fa-lock" style={{color: "#FFC107"}}/><br/></> : null}
							</span>
						);
					}}/>
					<Column header="Dados Principais" style={{flexDirection: "column", alignItems: "start"}} field="produto" body={(osip, column) => (
						<div className="grid">
							<ProdutoAutoComplete invalid={messages?.pecas?.[column.rowIndex]?.produto} readOnly={bloquearEdicao(osip)} required col={ordemServico.operacao === "CAMPO" ? 8 : 12} label="Produto" index={column.rowIndex} onChange={handleChangePeca} name="produto" value={osip.produto}/>
							<InputText reduced index={column.rowIndex} label="Identificação da peça" value={osip.identificacao} onChange={handleChangePeca} name="identificacao"/>
						</div>
					)}/>
					<Column header="Quantidade" style={{flexDirection: "column", alignItems: "start"}} field="quantidade" body={(osip, column) => (
						<>
							<div style={{width: "100%", padding: "0 .5em .5em .5em", whiteSpace: "nowrap"}}>
								<table style={{minWidth: "auto", width: "100%"}}>
									<tr>
										<td>Novas:</td>
										<td style={{textAlign: "right"}}>{osip.produto?.vwProduto?.saldoDisponivel}</td>
									</tr>
									<tr>
										<td>Recondicionadas:</td>
										<td style={{textAlign: "right"}}>{osip.produto?.vwProduto?.saldoRecondicionadas}</td>
									</tr>
								</table>
							</div>
							<InputNumber invalid={messages?.pecas?.[column.rowIndex]?.quantidade} readOnly={bloquearEdicao(osip)} required index={column.rowIndex} onChange={handleChangePeca} name="quantidade" value={osip.quantidade}/>
						</>
					)}/>
				</DataTable>
			</BlockUI>
		</div>
	);

}
