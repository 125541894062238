import {Checkbox} from "../../components/checkbox";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import React from "react";
import {TabPanel, TabView} from "primereact/tabview";
import {Fieldset} from "primereact/fieldset";

export function RoleCheckboxes(props) {

    function toggleAllRoles(k) {
        const value = !props.perfil.roles.some(r => r.startsWith(k));
        props.handleChangeAllRoles({name: k, value});
    }

    const checkboxes = Object.keys(descricoesRoles).map(k => {
        const sel = descricoesRoles[k];
        if (sel instanceof Object) {
            const checks = {
                descricao: <div onClick={() => toggleAllRoles(k)}>{sel.descricao}</div>
            };
            if (sel.a) checks.a = <Checkbox name={`${k}A`} value={props.perfil.roles.includes(`${k}A`)} onChange={props.handleChangeRoles}/>;
            if (sel.b) checks.b = <Checkbox name={`${k}B`} value={props.perfil.roles.includes(`${k}B`)} onChange={props.handleChangeRoles}/>;
            if (sel.c) checks.c = <Checkbox name={`${k}C`} value={props.perfil.roles.includes(`${k}C`)} onChange={props.handleChangeRoles}/>;
            if (sel.d) checks.d = <Checkbox name={`${k}D`} value={props.perfil.roles.includes(`${k}D`)} onChange={props.handleChangeRoles}/>;
            return checks;
        }
        return {
            descricao: <div onClick={() => toggleAllRoles(k)}>{descricoesRoles[k]}</div>,
            a: <Checkbox name={`${k}A`} value={props.perfil.roles.includes(`${k}A`)} onChange={props.handleChangeRoles}/>,
            b: <Checkbox name={`${k}B`} value={props.perfil.roles.includes(`${k}B`)} onChange={props.handleChangeRoles}/>,
            c: <Checkbox name={`${k}C`} value={props.perfil.roles.includes(`${k}C`)} onChange={props.handleChangeRoles}/>,
            d: <Checkbox name={`${k}D`} value={props.perfil.roles.includes(`${k}D`)} onChange={props.handleChangeRoles}/>
        };
    });

    return (
        <div className="col-12">
            <label>{props.label}</label>
            {props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : <small style={{color: props.perfil.roles?.length ? "transparent" : "#888"}} className="block">Você precisa selecionar ao menos uma opção</small>}
            <TabView>
                <TabPanel header="Permissões Comuns">
                    <DataTable paginator={false} value={checkboxes}>
                        <Column header="Descrição" field="descricao"/>
                        <Column header="Criar" field="a"/>
                        <Column header="Buscar" field="b"/>
                        <Column header="Editar" field="c"/>
                        <Column header="Excluir" field="d"/>
                    </DataTable>
                </TabPanel>
                <TabPanel header="Permissões Extras">
                    <div className="grid">
                        <div className="col-6">
                            <Fieldset legend="Ordens de Serviço">
                                <div className="grid">
                                    <Checkbox col={6} name="OSSH" label="Trocar status manualmente" value={props.perfil.roles.includes("OSSH")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSE" label="Escolher fluxo de operação" value={props.perfil.roles.includes("OSSE")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSF" label="Trocar etapa manualmente" value={props.perfil.roles.includes("OSSF")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSG" label="Escolher ou trocar responsável" value={props.perfil.roles.includes("OSSG")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSI" label="Alterar datas de início e fim" value={props.perfil.roles.includes("OSSI")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSJ" label="Lançar OS preventiva" value={props.perfil.roles.includes("OSSJ")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSK" label="Lançar OS corretiva" value={props.perfil.roles.includes("OSSK")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSX" label="Lançar OS de projeto" value={props.perfil.roles.includes("OSSX")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSL" label="Visualizar OS de outros responsáveis" value={props.perfil.roles.includes("OSSL")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSM" label="Visualizar OS preventiva" value={props.perfil.roles.includes("OSSM")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSN" label="Visualizar OS corretiva" value={props.perfil.roles.includes("OSSN")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSO" label="Visualizar valores das OS" value={props.perfil.roles.includes("OSSO")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSP" label="Coletor de movimentação física" value={props.perfil.roles.includes("OSSP")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSQ" label="Estoque e compras de OS" value={props.perfil.roles.includes("OSSQ")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSR" label="Impressão de etiquetas de OS" value={props.perfil.roles.includes("OSSR")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSS" label="Editar equipamento da OS" value={props.perfil.roles.includes("OSSS")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSST" label="Editar tipo da OS" value={props.perfil.roles.includes("OSST")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSU" label="Alterar peças mesmo com orçamento gerado" value={props.perfil.roles.includes("OSSU")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSOA" title="Habilita o botão de aprovar orçamento na O.S" label="Aprovar Orçamento" value={props.perfil.roles.includes("OSOA")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSV" label="Alterar ordens de serviço de outros responsáveis" value={props.perfil.roles.includes("OSSV")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="OSSW" label="Alterar previsão de atendimento (agendamento)" value={props.perfil.roles.includes("OSSW")} onChange={props.handleChangeRoles}/>
                                </div>
                            </Fieldset>
                            <div style={{height: "1em"}}/>
                            <Fieldset legend="BackOffice">
                                <div className="grid">
                                    <Checkbox col={6} name="BOFA" label="Recepção" value={props.perfil.roles.includes("BOFA")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="BOFB" label="Expedição" value={props.perfil.roles.includes("BOFB")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="BOFC" label="Estoque" value={props.perfil.roles.includes("BOFC")} onChange={props.handleChangeRoles}/>
                                </div>
                            </Fieldset>
                            <div style={{height: "1em"}}/>
                            <Fieldset legend="Laboratório">
                                <div className="grid">
                                    <Checkbox col={6} name="LABA" label="Laudo" value={props.perfil.roles.includes("LABA")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="LABB" label="Manutenção" value={props.perfil.roles.includes("LABB")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="LABC" label="Limpeza" value={props.perfil.roles.includes("LABC")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="LABD" label="Teste" value={props.perfil.roles.includes("LABD")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="LABT" label="Técnico de Laboratório" value={props.perfil.roles.includes("LABT")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="LABG" label="Gestor de laboratório" value={props.perfil.roles.includes("LABG")} onChange={props.handleChangeRoles}/>
                                </div>
                            </Fieldset>
                            <Fieldset legend="Campo">
                                <div className="grid">
                                    <Checkbox col={6} name="CAMA" label="Ordens de Serviço de Campo" value={props.perfil.roles.includes("CAMA")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="CAMB" label="Agendas de OS de Campo" value={props.perfil.roles.includes("CAMB")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="CAMT" label="Técnico de Campo" value={props.perfil.roles.includes("CAMT")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="CAMG" label="Gestor de Campo" value={props.perfil.roles.includes("CAMG")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="RAGE" label="Relatório de Agendas" value={props.perfil.roles.includes("RAGE")} onChange={props.handleChangeRoles}/>
                                </div>
                            </Fieldset>
                        </div>
                        <div className="col-6">
                            <Fieldset legend="Selos do IPEM">
                                <div className="grid">
                                    <Checkbox col={6} name="SIPE" label="Buscar selos de outros usuários" value={props.perfil.roles.includes("SIPE")} onChange={props.handleChangeRoles}/>
                                </div>
                            </Fieldset>
                            <div style={{height: "1em"}}/>
                            <Fieldset legend="Faturamentos">
                                <div className="grid">
                                    <Checkbox col={6} name="FATE" label="Buscar faturamentos de outros vendedores" value={props.perfil.roles.includes("FATE")} onChange={props.handleChangeRoles}/>
                                </div>
                            </Fieldset>
                            <div style={{height: "1em"}}/>
                            <Fieldset legend="Relatórios">
                                <div className="grid">
                                    <Checkbox col={6} name="RELB" label="Ordem de Serviços" value={props.perfil.roles.includes("RELB")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="RPRB" label="Produtividade" value={props.perfil.roles.includes("RPRB")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="RORC" label="Orçamentos" value={props.perfil.roles.includes("RORC")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="RPEC" label="Peças" value={props.perfil.roles.includes("RPEC")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="ROSC" label="OS - Cliente" value={props.perfil.roles.includes("ROSC")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="BKORC" label="Backlog de Orçamentos" value={props.perfil.roles.includes("BKORC")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="ROSE" label="OS por Etapa" value={props.perfil.roles.includes("ROSE")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="BKSLA" label="Backlog de SLA" value={props.perfil.roles.includes("BKSLA")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="ROPE" label="Relatório de Operações" value={props.perfil.roles.includes("ROPE")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="RRES" label="Relatório de Restrições" value={props.perfil.roles.includes("RRES")} onChange={props.handleChangeRoles}/>
                                    <Checkbox col={6} name="RKPIL" label="Relatório KPI Laboratório" value={props.perfil.roles.includes("RKPIL")} onChange={props.handleChangeRoles}/>
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        </div>
    );
}

const descricoesRoles = {
    USR: "Usuário",
    PRF: "Perfil",
    PRO: "Produto",
    MAR: "Marcas de Produto",
    PRT: "Tipos de Produto",
    EMP: "Empresa",
    CLI: "Cliente",
    OSS: "Ordem de Serviço",
    CON: "Contrato",
    TDC: "Tipo de Cláusula",
    EQP: "Equipamento",
    PDV: "Pedido de Venda",
    EST: "Estoque",
    EMV: {
        descricao: "Movimentação de Estoque",
        b: true,
        c: true
    },
    LTP: "Lista de Preço",
    OSE: "Etapas de Ordem de Serviço",
    ANS: "Modelos de SLA",
    SIP: "Selos do IPEM",
    EML: "Conta Email",
    ICM: "Alíquota de ICMS",
    PRA: "Prateleiras",
    CAL: "Calendários",
    COM: {
        descricao: "Compras",
        b: true
    },
    FAT: {
        descricao: "Faturamento",
        b: true
    },
    TIC: "Tipo de Comissão",
    ACS: "Acompanhamento",
    ACV: "Acompanhamento Consultor",
    FCS: "Fechamento de Comissão",
};
