import moment from 'moment';
import {buildValidator, isCNPJ, isNotNull, isRequired} from '../utils/fieldValidator';
import {baseService} from './baseService';
import { api } from './api';

const resourceUrl = '/clientes'

export const clienteService = {
    criar: () => {
        return {
            id: null,
            cnpj: "",
            razaoSocial: "",
            nomeFantasia: "",
            eventos: []
        };
    },

    criarEvento: (eventos) => {
		return {
			id: null,
			forma: 'MANUAL',
			observacoes: '',
			registro: moment().format('YYYY-MM-DDTHH:mm:ss'),
			usuario: null,
			item: eventos?.length +1,
            _key: Math.random() * 1000,
			tipo: 'REGISTRO_ACOMPANHAMENTO',
		}
	},
    criarContato: () => {
		return {
			id: null,
			// item: contatos?.length +1,
            _key: Math.random() * 1000
		}
	},
    validar: (cliente) => {
        const messages = buildValidator();
        messages.razaoSocial = isRequired(cliente.razaoSocial);
        messages.nomeFantasia = isRequired(cliente.nomeFantasia);
        // messages.cpfCnpj = isCNPJ(cliente.cpfCnpj);

        if (!messages.contatos) {
			messages.contatos = [...Array(cliente.contatos.length)].map((_, i) => ({
				nome: null
			}))
		}
        
        for (let i = 0; i < cliente.contatos.length; ++i) {	
			messages.contatos[i].nome = isNotNull(cliente.contatos[i].nome);

            if (!cliente.contatos[i].email && !cliente.contatos[i].telefone1 && !cliente.contatos[i].telefone2) {
                messages.contatos[i].telefone1 = "É obrigatório o preenchimento de um contato.";
            }
		}        
        return messages;
    },
    ...baseService(resourceUrl),
    async listarClientes(params) {
        const query = [];
        if (params.textoDeBusca?.length) query.push(`textoDeBusca=${params.textoDeBusca}`);
        if (params.tipo?.length) query.push(`tipo=${params.tipo}`);
        if (!params.visualizarDesativados) {
            query.push("status=ATIVO");
        }
        return await clienteService.listar(query);
    }
};
