import React, {useEffect, useState} from "react";
import {Dialog} from "../dialog";
import {ProgressBar} from "primereact/progressbar";

export function ProgressDialog({onProgress}) {

    const [progresso, setProgresso] = useState(0);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        if (onProgress) {
            onProgress(setProgresso);
        }
    }, [onProgress]);

    useEffect(() => {
        if (progresso >= 1) {
            setVisible(false);
        }
    }, [progresso]);

    return (
        <Dialog closable={false} visible={visible} style={{width: "450px"}} header="Processando">
            <div>Processando itens. Por favor, aguarde...</div>
            <ProgressBar showValue={false} value={progresso * 100}/>
        </Dialog>
    )

}
