import React, {useContext, useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {useNavigate, useParams} from "react-router-dom";
import {labelRestricao, ordemServicoService} from "../../../service/ordemServicoService";
import {BlockUI} from "primereact/blockui";
import {InputNumber} from "../../../components/inputnumber";
import {ClienteAutoComplete} from "../../../components/autocomplete/ClienteAutoComplete";
import {InputMask} from "../../../components/inputmask";
import {InputText} from "../../../components/inputtext";
import {InputCheckbox} from "../../../components/toogleinputtext";
import {ProdutoAutoComplete} from "../../../components/autocomplete/produtoAutoComplete";
import {acessorios} from "../../OrdensServico/EditarOrdemServicoItemDadosPrincipais";
import {Button} from "../../../components/button";
import {FauxInput} from "../../../components/fauxinput";
import {equipamentoService} from "../../../service/equipamentoService";
import {ordemServicoItemService} from "../../../service/ordemServicoItemService";
import {InputButton} from "../../../components/inputbutton";
import {InformationDialog} from "../../../components/dialog/InformationDialog";
import {DialogContext} from "../../../utils/dialogContext";
import {SelecionarEquipamento} from "../../__Commons/SelecionarEquipamento";
import {If} from "../../../components/conditional/If";
import {useAuth} from "../../../context/AuthContext";
import {OrdemServicoTimeline} from "../../../components/cards/OrdemServicoTimeline";
import {TabPanel, TabView} from "primereact/tabview";
import {Acompanhamento} from "../../__Commons/Acompanhamento";
import {Calendar} from "../../../components/calendar";
import {RegistrarEvento} from "../../__Commons/RegistrarEvento";
import {eventoService} from "../../../service/eventoService";
import {imprimirEtiquetaLaudoZebra, imprimirEtiquetaOsZebra} from "../../../service/etiquetaService";
import {FotoVideo} from "../../__Commons/FotoVideo";
import {AvancarEtapa} from "../../__Commons/AvancarEtapa";
import {findRestricoesBloqueantes} from "../../../utils/ordemServicoUtils";
import {EditarEquipamentoBackup} from "../../__Commons/EditarEquipamentoBackup";
import {ClienteContratoDropdown} from "../../../components/autocomplete/ClienteContratoDropdown";

export function BackOfficeRecepcaoEditar() {

	const navigate = useNavigate();
	const {usuario, roles} = useAuth();
	const [messages, setMessages] = useState();
	const [serial, setSerial] = useState(null);
	const {showDialog} = useContext(DialogContext);
	const [loading, setLoading] = useState(0);
	const [ordemServico, setOrdemServico] = useState({
		...ordemServicoService.criar(),
		responsavel: usuario,
		itens: [
			{...ordemServicoItemService.criar(), equipamento: equipamentoService.criar()},
		]
	});
	const {id} = useParams();

	useEffect(() => {
		if (id !== "new") {
			setLoading("Carregar");
			ordemServicoService.buscar(id).then(ordemServico => {
				if (!ordemServico.itens?.length) {
					ordemServico.itens = [
						ordemServicoItemService.criar()
					];
				}
				if (!ordemServico.itens[0].equipamento) {
					ordemServico.itens[0].equipamento = equipamentoService.criar();
				}
				setOrdemServico(ordemServico);
				setLoading(0);
			});
		}
	}, [id]);

	async function handleChange(event) {
		setOrdemServico(prevOrdemServico => {
			return ({...prevOrdemServico, [event.name]: event.value, _alterado: true});
		});
	}

	function handleChangeItem(event) {
		setOrdemServico(prevOrdemServico => {
			prevOrdemServico.itens[0][event.name] = event.value;
			return {...prevOrdemServico, _alterado: true};
		});
	}

	function handleChangeEquipamento(event) {
		setOrdemServico(prevOrdemServico => {
			prevOrdemServico.itens[0].equipamento[event.name] = event.value;
			prevOrdemServico.itens[0].equipamento._alterado = true;
			return {...prevOrdemServico, _alterado: true};
		});
	}

	function handleChangeAcessorio(event) {
		setOrdemServico(prevOrdemServico => {
			prevOrdemServico.itens[0].acessorios.filter(ia => ia.tipo === event.option).forEach(ia => ia[event.name] = event.value);
			return {...prevOrdemServico, _alterado: true};
		});
	}

	function handleChangeToggleAcessorio(event) {
		setOrdemServico(prevOrdemServico => {
			if (!prevOrdemServico.itens[0].acessorios.some(ia => ia.tipo === event.name)) {
				prevOrdemServico.itens[0].acessorios.push({tipo: event.name, identificacao: ""});
			} else {
				prevOrdemServico.itens[0].acessorios = prevOrdemServico.itens[0].acessorios.filter(ia => ia.tipo !== event.name);
			}
			return {...prevOrdemServico, _alterado: true};
		});
	}

	async function buscarEquipamento() {
		setLoading("BuscarEquipamento");
		const equipamentos = await equipamentoService.listar([`serial=${ordemServico.itens[0].equipamento.serial}`]);
		switch (equipamentos.length) {
			case 0:
				showDialog(<InformationDialog message="Não foi encontrado nenhum equipamento com o número de série informado"/>);
				break;
			case 1:
				const e = await equipamentoService.buscar(equipamentos[0].id);
				setOrdemServico(prevOrdemServico => {
					prevOrdemServico.itens[0].equipamento = e;
					return {...prevOrdemServico, _alterado: true};
				});
				validarMensagens();
				break;
			default:
				showDialog(<SelecionarEquipamento equipamentos={equipamentos} onModalClose={async e => {
					e = await equipamentoService.buscar(e.id);
					setOrdemServico(prevOrdemServico => {
						prevOrdemServico.itens[0].equipamento = e;
						return {...prevOrdemServico, _alterado: true};
					});
					validarMensagens();
				}}/>);
				break;
		}
		setLoading(0);
	}

	async function handleSalvar() {
		const messages = ordemServicoService.validarBackofficeRecepcao(ordemServico);
		setMessages(messages);
		if (!messages.isEmpty()) {
			showDialog(<InformationDialog message="Por favor, confira as mensagens de validação e tente novamente."/>);
			return;
		}
		setLoading("Salvar");
		if (!ordemServico.id) {
			if (!ordemServico.acompanhamentos.length) {
				ordemServico.acompanhamentos.push({
					...ordemServicoService.criarAcompanhamento(),
					etapa: ordemServico.etapa,
					observacoes: "Ordem de serviço iniciada",
					responsavel: usuario
				});
			}
			ordemServico.clienteFaturamento = ordemServico.cliente;
		}
		const os = await ordemServicoService.salvarOrdemServico({...ordemServico, postBack: true}, usuario);
		if (usuario.bancadaTrabalho?.id) {
			await ordemServicoService.registrarMovimentacao(usuario, {numero: os.numero, prateleira: usuario.bancadaTrabalho?.id});
		}
		if (id === "new") {
			navigate(`/backoffice/recepcao/${os.id}`);
		} else {
			setOrdemServico(await ordemServicoService.buscar(os.id));
			setLoading(0);
		}
	}

	function validarMensagens() {
		setMessages(ordemServicoService.validarBackofficeRecepcao(ordemServico));
	}

	function handleVoltar() {
		navigate(-1);
	}

	function registrarEvento() {
		showDialog(<RegistrarEvento onModalClose={async evento => {
			const acompanhamento = ordemServico.acompanhamentos[ordemServico.acompanhamentos.length - 1];
			evento = await eventoService.salvar({...evento, postBack: true});
			await ordemServicoService.salvarAcompanhamentoEvento({eventos: evento.id, acompanhamento: acompanhamento.id});
			setOrdemServico(prevOrdemServico => {
				acompanhamento.eventos.push(evento);
				return {...prevOrdemServico, _alterado: true};
			});
		}}/>);
	}

	async function handleConclusao() {
		if (!ordemServico.responsavel?.id) {
			showDialog(<InformationDialog message="A ordem de serviço não pode avançar sem ter um responsável atribuído"/>);
			return;
		}
		const restricoes = findRestricoesBloqueantes(ordemServico);
		if (restricoes.length) {
			showDialog(<InformationDialog header="Informação" message={(
				<div>
					<div>Por favor, verifique as restrições antes de concluir esta etapa:</div>
					{restricoes.map(osr => <div className="os-restricao" key={osr}><i className="fas fa-exclamation-triangle"/> {labelRestricao[osr]}</div>)}
				</div>
			)}/>);
		} else {
			showDialog(<AvancarEtapa status="RECEPCAO" ordensServico={[ordemServico]} onModalClose={() => navigate("/backoffice/recepcao")}/>);
		}
	}

	const disableActions = (
		!ordemServico.responsavel?.id
		|| !roles.BOFA
		|| (ordemServico.id && ordemServico.responsavel?.id !== usuario.id && !roles.OSSV)
	);

	function editarEquipamentoBackup() {
		showDialog(<EditarEquipamentoBackup equipamentoBackup={ordemServico.itens[0].equipamentoBackup} onModalClose={backup => {
			setOrdemServico(prevOrdemServico => {
				prevOrdemServico.itens[0].equipamentoBackup = backup;
				prevOrdemServico._alterado = true;
				return {...prevOrdemServico};
			});
		}}/>);
	}

	function atualizarSerial() {
		setSerial(ordemServico.itens[0].equipamento.serial);
		validarMensagens();
	}

	return (
		<BlockUI blocked={loading}>
			<Panel header={`BackOffice - Recepção: Ordem de Serviço ${ordemServico.numero || ""}`}>
				<OrdemServicoTimeline ordemServico={ordemServico}/>
				<TabView>
					<TabPanel leftIcon="fas fa-database" header="Dados Principais">
						<PanelContent>
							<InputNumber readOnly value={ordemServico.numero} col={2} label="Nº"/>
							<Calendar required invalid={messages?.inicio} showTime col={2} name="inicio" onChange={handleChange} value={ordemServico.inicio} label="Data da Entrada"/>
							<ClienteAutoComplete onBlur={validarMensagens} invalid={messages?.cliente} required readOnly={ordemServico.id} col={5} value={ordemServico.cliente} name="cliente" onChange={handleChange}/>
							<ClienteContratoDropdown tipo={ordemServico.tipo} autoSelect serial={serial} equipamento={ordemServico.itens[0].equipamento} cliente={ordemServico.cliente} col={3} value={ordemServico.contrato} onChange={handleChange}/>
							<InputMask readOnly col={2} name="cep" label="CEP"  mask='99999-999' value={ordemServico.cliente?.vwCliente?.cep}/>
							<InputText readOnly col={5} label="Endereço" value={ordemServico.cliente?.vwCliente?.endereco}/>
							<InputText readOnly col={2} label="Município" value={ordemServico.cliente?.vwCliente?.municipio}/>
							<InputText readOnly col={1} label="UF" value={ordemServico.cliente?.vwCliente?.uf}/>
							<FauxInput readOnly label="SLA" col={2} value={ordemServico.contrato?.acordoNivelServico?.descricao || <span style={{color: "#F44336"}}><i className="fas fa-file-circle-xmark"/> Sem SLA definido</span>}/>
							<InputText col={2} name="numeroChamado" label="Nº do Chamado" value={ordemServico.numeroChamado} onChange={handleChange}/>
							<InputText col={2} name="numeroPedidoCliente" label="Nº do Pedido do Cliente" value={ordemServico.numeroPedidoCliente} onChange={handleChange}/>
							<InputText col={2} name="numeroPedidoCompra" label="Nº do Pedido de Compra" value={ordemServico.numeroPedidoCompra} onChange={handleChange}/>
							<InputText col={3} name="solicitanteNome" label="Nome do Solicitante" value={ordemServico.solicitanteNome} onChange={handleChange}/>
							<InputText col={3} name="responsavelInstrumentos" label="Responsável pelo Equipamento" value={ordemServico.responsavelInstrumentos} onChange={handleChange}/>
							<InputButton required={ordemServico.id} invalid={messages?.equipamento?.serial} onBlur={atualizarSerial} buttonDisabled={!ordemServico.itens[0].equipamento.serial?.length} onButtonClick={buscarEquipamento} value={ordemServico.itens[0].equipamento.serial} buttonIcon="fas fa-search" buttonLabel="Buscar" col={3} label="Nº de Série" name="serial" onChange={handleChangeEquipamento}/>
							<ProdutoAutoComplete onBlur={validarMensagens} invalid={messages?.equipamento?.produto} required label="Modelo do Equipamento" name="produto" col={4} onChange={handleChangeEquipamento} value={ordemServico.itens[0].equipamento.produto}/>
							<InputText col={2} name="numeroAtivo" label="Nº do Ativo" value={ordemServico.itens[0].equipamento.numeroAtivo} onChange={handleChangeEquipamento}/>
							<InputText col={3} name="descricao" label="Identificação" value={ordemServico.itens[0].equipamento.descricao} onChange={handleChangeEquipamento}/>
							<label style={{display: "block", width: "100%", marginLeft: "6px"}}>Acessórios</label>
							{
								acessorios.map(a => (
									<InputCheckbox placeholder="Nº de Identificação"
									               col={a.col || 3}
									               label={a.label}
									               inputName="identificacao"
									               inputValue={ordemServico.itens[0].acessorios.filter(ia => ia.tipo === a.name)[0]?.identificacao}
									               onInputChange={handleChangeAcessorio}
									               checkboxName={a.name}
									               checkboxValue={ordemServico.itens[0].acessorios.some(osia => osia.tipo === a.name)}
									               onCheckboxChange={handleChangeToggleAcessorio}
									/>
								))
							}
							<div style={{height: "15px", width: "100%"}}/>
							<InputText col={7} name="complemento" label="Complemento (Detalhamento do Equipamento)" value={ordemServico.itens[0].complemento} onChange={handleChangeItem}/>
							<InputButton readOnly buttonLabel="Alterar" buttonIcon="fas fa-edit" col={5} label="Equipamento Backup" value={ordemServico.itens[0].equipamentoBackup?.equipamento?.autoComplete || ""} onButtonClick={editarEquipamentoBackup}/>
							<InputText multiline style={{height: "10em"}} col={12} name="problemaIndicado" label="Problema Indicado" value={ordemServico.itens[0].problemaIndicado} onChange={handleChangeItem}/>
						</PanelContent>
					</TabPanel>
					<TabPanel disabled={!ordemServico.id} leftIcon="fas fa-camera" header="Fotos & Vídeos">
						<FotoVideo ordemServico={ordemServico} setOrdemServico={setOrdemServico}/>
					</TabPanel>
					<TabPanel disabled={!ordemServico.id} leftIcon="fas fa-clock" header="Histórico">
						<Acompanhamento ordemServico={ordemServico}/>
					</TabPanel>
				</TabView>
				<PanelFooter>
					<If condition={ordemServico.id} children={<Button icon="fas fa-barcode" onClick={() => imprimirEtiquetaOsZebra(ordemServico)}/>}/>
					<If condition={ordemServico.id} children={<Button icon="fas fa-barcode" onClick={() => imprimirEtiquetaLaudoZebra(ordemServico)} clear/>}/>
					<If condition={ordemServico.id} children={<Button label="Registrar Evento" warning icon="fa-solid fa-file-lines" onClick={registrarEvento}/>}/>
					<If condition={ordemServico.id} children={<i className="fa-solid fa-circle" style={{lineHeight: "36px", color: "rgba(0, 0, 0, .1)", margin: "0 1em"}}/>}/>
					<If condition={ordemServico.id} children={<Button disabled={disableActions || ordemServico._alterado} label="Concluído" icon="fa-solid fa-check" onClick={handleConclusao}/>}/>
					<If condition={roles.OSSC} children={<Button disabled={disableActions || !ordemServico._alterado} icon="fas fa-save" success label="Salvar" onClick={handleSalvar}/>}/>
					<Button icon="fas fa-arrow-left" secondary label="Voltar" onClick={handleVoltar}/>
				</PanelFooter>
			</Panel>
		</BlockUI>
	);

}
