import {buildValidator} from "../utils/fieldValidator";
import {api} from "./api";
import {baseService} from "./baseService";

const resourceUrl = "/produtos";

export const produtoService = {
    criar: () => {
        return {
            id: null,
            codigo: "",
            descricao: "",
            fatorUnitario: 0,
            percentualIpi: 0,
            cst: "",
            empresa: null
        };
    },
    validar: produto => {
        return buildValidator();
    },
    verificarPath: async (path) => {
        return api.get(`${resourceUrl}/verificar-path`, {
            params: {path: path}
        }).then(res => res.data);
    }, 
    async calcularImpostos(criterio) {        
        return await api.post(`${resourceUrl}/calcular-impostos`, criterio).then(response => response.data);
    },
    async buscarPreco(criterio) {
        return await api.post(`${resourceUrl}/preco`, criterio).then(response => response.data);
    },
    async listarProdutos(params) {
        const query = [
            "status=ATIVO",
            "estoqueRecondicionado"
        ];
        if (params.produto?.length) {
            query.push(`textoDeBusca=${params.produto}`);
        } else if (params.tipo?.length) {
            query.push(`textoDeBusca=${params.tipo}`);
        }
        return await produtoService.listar([`query=${query.join(";")}`]);
    },
    ...baseService(resourceUrl),
    async listarProduto(params) {
        const query = [];
        if (params.descricao?.length) query.push(`textoDeBusca=${params.descricao}`);
        if (params.equipamentoEspecial?.length) query.push(`equipamentoEspecial=${params.equipamentoEspecial}`);
        if (!params.visualizarDesativados) {
            query.push("status=ATIVO");
        }
        return await produtoService.listar(query);
    }
};
