import React, {useMemo, useState} from "react";
import moment from "moment";
import "./WeekSchedule.scss";
import classNames from "classnames";
import {formatNumber} from "chart.js/helpers";
import {If} from "../conditional/If";
import {formatMinutes} from "../../utils/numberFormatter";
import {printRestricao} from "../cards/CardOrdemServico";
import {useCalendarioPadrao} from "../../utils/CalendarioContext";
import {PrintTipoUsuario} from "../../pages/Campo/TabelaOrdemServico";
import {useAuth} from "../../context/AuthContext";

const namesDaysOfWeek = [
    "DOM",
    "SEG",
    "TER",
    "QUA",
    "QUI",
    "SEX",
    "SAB"
];

export const SCHEDULE_RENDER_DAYS = 5;

export function WeekSchedule({ordensServico, start, onDoubleClick, selection, onSelectionChange, single}) {

    const {roles} = useAuth();
    const startDate = moment(start);
    const {feriados} = useCalendarioPadrao();

    function handleSelection(ordemServico) {
        if (single) {
            if (selection.some(ss => ss.id === ordemServico.id)) {
                onSelectionChange([]);
            } else {
                onSelectionChange([ordemServico]);
            }
            return;
        }
        if (selection.some(ss => ss.id === ordemServico.id)) {
            onSelectionChange(selection.filter(ss => ss.id !== ordemServico.id));
        } else {
            selection.push(ordemServico);
            onSelectionChange([...selection]);
        }
    }

    const columns = useMemo(() => {
        const columns = [];
        for (let i = 0; i < SCHEDULE_RENDER_DAYS; ++i) {
            const momentHoje = startDate.clone().add(i, "days");
            const oss = (ordensServico || []).filter(os => os.previsaoAtendimento && isSameDay(os.previsaoAtendimento, startDate.clone().add(i, "days")));
            const agendas = [];
            if (roles.CAMG) {
                const rTemp = oss?.map(os => os.responsavel);
                const responsaveis = rTemp?.filter((r, i) => rTemp.findIndex(rs => rs.id === r.id) === i).sort((a, b) => a?.nome?.localeCompare(b?.nome));
                if (responsaveis?.length) {
                    agendas.push(responsaveis?.map(r => <WeekScheduleResponsavel selection={selection} onClick={handleSelection} onDoubleClick={onDoubleClick} hoje={moment().format("YYYY-MM-DD") === momentHoje.format("YYYY-MM-DD")} responsavel={r} ordensServico={oss.filter(os => os.responsavel.id === r.id)}/>));
                }
            } else {
                agendas.push(...oss?.map(os => <WeekScheduleOrdemServico selected={selection.some(ss => ss.id === os.id)} ordemServico={os} onClick={() => handleSelection(os)} onDoubleClick={() => onDoubleClick(os)}/>));
            }
            const feriadosHoje = feriados.filter(cf => cf.referencia === momentHoje.format("YYYY-MM-DD") || (cf.anual && moment(cf.referencia).format("MM-DD") === momentHoje.format("MM-DD")));
            columns.push(
                <div className="week-schedule-day" key={i}>
                    <div className={`week-schedule-day-header ${feriadosHoje.length ? "feriado" : ""} ${moment().format("YYYY-MM-DD") === momentHoje.format("YYYY-MM-DD") ? "today" : ""}`}>
                        <div>{namesDaysOfWeek[momentHoje.get("weekday")]}</div>
                        <div style={{fontSize: "2em", fontWeight: "bold"}}>{momentHoje.format("DD")}</div>
                        <If condition={feriadosHoje.length}>
                            {feriadosHoje.map(f => <div>{f.nome}</div>)}
                        </If>
                    </div>
                    <div className="week-schedule-total-day">({oss?.length || "Sem"} agenda{oss?.length === 1 ? "" : "s"})</div>
                    <div className="week-schedule-day-content">
                        <If condition={agendas?.length} children={agendas} otherwise={<div style={{textAlign: "center"}}>Sem Agendas</div>}/>
                    </div>
                </div>
            );
        }
        return columns;
    }, [start, ordensServico, selection, feriados]);

    return (
        <div className="week-schedule">
            {columns}
        </div>
    );

}

function isSameDay(date1, date2) {
    return moment(date1).format("YYYY-MM-DD") === date2.format("YYYY-MM-DD");
}

export function WeekScheduleResponsavel({responsavel, hoje, ordensServico, selection, onDoubleClick, onClick}) {

    const [expanded, setExpanded] = useState(false);

    function toggleExpanded() {
        setExpanded(prevExpanded => !prevExpanded);
    }

    const classes = classNames({
        "week-schedule-responsavel-header": true,
        hoje,
        expanded
    });

    return (
        <div>
            <div className={classes} onClick={toggleExpanded}>
                <span style={{fontWeight: "bold", whiteSpace: "nowrap"}}>{responsavel.nome}</span><br/>
                {ordensServico?.length} agendas
                <i className={`week-schedule-responsavel-expander fas fa-circle-chevron-down ${expanded ? "expanded" : ""}`}/>
            </div>
            <If condition={expanded}>
                {ordensServico?.map(os => (
                    <WeekScheduleOrdemServico
                        selected={selection?.some(ss => ss.id === os.id)}
                        key={os.id}
                        ordemServico={os}
                        onDoubleClick={() => onDoubleClick(os)}
                        onClick={() => onClick(os)}
                    />
                ))}
            </If>
        </div>
    );

}

export function WeekScheduleOrdemServico({ordemServico, onDoubleClick, selected, onClick}) {

    const {roles} = useAuth();

    return (
        <div className={`week-schedule-ordem-servico ${selected ? "selected" : ""} ${classNames({"tabela-os": true, [ordemServico.cor?.toLowerCase()]: true})}`} onClick={onClick} onDoubleClick={onDoubleClick}>
            <div>
                <i className="fas fa-scroll" style={{color: "#009688"}}/> <b>{formatNumber(ordemServico.numero)}</b> - {ordemServico.tipo}
                <span style={{float: "right"}}><i className="fas fa-clock"/> {moment(ordemServico.previsaoAtendimento).format("HH:mm")}</span>
            </div>
            <div><i className="fas fa-user-circle" style={{color: "#3F51B5"}}/> {ordemServico.cliente?.autoComplete}</div>
            <div><i className="fas fa-computer" style={{color: "#607D8B"}}/> {ordemServico.equipamento?.autoComplete}</div>
            <div><PrintTipoUsuario usuario={ordemServico.responsavel}/> {ordemServico.responsavel?.autoComplete}</div>
            <If condition={!roles.CAMT}>
                <div>{ordemServico.restricoes?.map(r => printRestricao(ordemServico, r))}</div>
            </If>
            <div>
                <If condition={ordemServico.distanciaPercorrida}>
                    <i className="fas fa-road" style={{color: "#2196F3"}}/>&nbsp;<span title="Distância percorrida">{ordemServico.distanciaPercorrida}Km</span> (<span title="Tempo em deslocamento">{formatMinutes(ordemServico.tempoDeslocamentoEmMinutos)}</span>)
                </If>
                <If condition={ordemServico.tempoParadoEmMinutos}>
                    &nbsp;|&nbsp;<i className="fas fa-hourglass" style={{color: "#FF9800"}}/>&nbsp;<span title="Tempo em espera">{formatMinutes(ordemServico.tempoParadoEmMinutos)}</span>
                </If>
            </div>
        </div>
    );

}
