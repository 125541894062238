import React, {useEffect, useMemo, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {contratoService} from "../../../service/contratoService";
import {ProgressBar} from "primereact/progressbar";
import {If} from "../../../components/conditional/If";
import moment from "moment";
import {SelectButton} from "../../../components/selectbutton";
import {Button} from "../../../components/button";
import saveAs from "file-saver";
import {TabPanel, TabView} from "primereact/tabview";
import {PanelContent} from "../../../components/panel";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";

export function OrdemServicoProjetoEquipamentos({projeto}) {

    const quantidadeEquipamentos = projeto.projetoItens?.map(pi => pi.quantidade).reduce((a, b) => a + b, 0);
    const [ordensServico, setOrdensServico] = useState([]);
    const [status, setStatus] = useState("");
    const momentInicio = moment(projeto.inicio);
    const momentFim = moment(projeto.fim);
    const momentNow = moment();
    const executados = ordensServico?.filter(os => os.status !== "PENDENTE").length || 0;
    const percentual = Math.ceil((executados / quantidadeEquipamentos) * 100);
    const percentualTempo = Math.round(momentInicio.diff(momentNow, "minutes") / momentInicio.diff(momentFim, "minutes") * 100);

    useEffect(() => {
        contratoService.listarEquipamentosAtendidos(projeto.id).then(setOrdensServico);
    }, [projeto.id]);

    function imprimirCsv() {
        contratoService.listarEquipamentosAtendidosCsv(projeto.id).then(data => {
            saveAs(new Blob([data], {type: "text/csv"}), `Projeto-${projeto.numero}-${moment().format("YYMMDDHHmmss")}.csv`);
        });
    }

    const unidades = useMemo(() => {
        const unidades = ordensServico?.map(os => os.cliente).filter((os, i, a) => a.findIndex(x => x.id === os.id) === i);
        for (const unidade of unidades) {
            unidade.pendentes = ordensServico.filter(os => os.cliente?.id === unidade.id && os.status === "PENDENTE").length;
            unidade.executados = ordensServico.filter(os => os.cliente?.id === unidade.id && ["REVISADO", "EXECUTADO"].includes(os.status)).length;
            unidade.malsucedidos = ordensServico.filter(os => os.cliente?.id === unidade.id && os.status === "MALSUCEDIDO").length;
        }
        return unidades;
    }, [ordensServico]);

    const modelos = useMemo(() => {
        const modelos = ordensServico?.map(os => os.equipamento?.produto).filter((os, i, a) => a.findIndex(x => x.id === os.id) === i);
        for (const modelo of modelos) {
            modelo.pendentes = ordensServico.filter(os => os.equipamento?.produto?.id === modelo.id && os.status === "PENDENTE").length;
            modelo.executados = ordensServico.filter(os => os.equipamento?.produto?.id === modelo.id && ["REVISADO", "EXECUTADO"].includes(os.status)).length;
            modelo.malsucedidos = ordensServico.filter(os => os.equipamento?.produto?.id === modelo.id && os.status === "MALSUCEDIDO").length;
        }
        return modelos;
    }, [ordensServico]);

    function trocarStatus(event) {
        switch (event.value) {
            case "AGENDADO":
                ordensServico.forEach(os => os._hidden = !os.previsaoAtendimento || os.status !== "PENDENTE");
                setOrdensServico([...ordensServico]);
                break;
            case "PENDENTE":
                ordensServico.forEach(os => os._hidden = os.status !== "PENDENTE");
                setOrdensServico([...ordensServico]);
                break;
            case "EXECUTADO":
                ordensServico.forEach(os => os._hidden = !["EXECUTADO", "REVISADO"].includes(os.status));
                setOrdensServico([...ordensServico]);
                break;
            case "MALSUCEDIDO":
                ordensServico.forEach(os => os._hidden = os.status !== "MALSUCEDIDO");
                setOrdensServico([...ordensServico]);
                break;
            default:
                ordensServico.forEach(os => os._hidden = false);
                setOrdensServico([...ordensServico]);
                break;
        }
        setStatus(event.value);
    }

    const controlSet = (
        <div className="grid" style={{paddingTop: ".5em"}}>
            <div className="col-2 xm">
                <Button icon="fas fa-print" label="Imprimir CSV" onClick={imprimirCsv}/>
            </div>
            <div className="col-3"/>
            <SelectButton col={7} options={optionsStatus} name="status" value={status} onChange={trocarStatus}/>
        </div>
    );

    const unidadesHeaderGroup = (
        <ColumnGroup>
            <Row>
                <Column sortable field="autoComplete" header="Unidade" rowSpan={2}/>
                <Column style={{textAlign: "center"}} header="Status" colSpan={3}/>
            </Row>
            <Row>
                <Column sortable field="pendente" style={{textAlign: "right", width: "12em"}} header="Pendentes"/>
                <Column sortable field="executados" style={{textAlign: "right", width: "12em"}} header="Executados"/>
                <Column sortable field="malsucedidos" style={{textAlign: "right", width: "12em"}} header="Malsucedidos"/>
            </Row>
        </ColumnGroup>
    );

    const unidadesFooterGroup = (
        <ColumnGroup>
            <Row>
                <Column/>
                <Column style={{textAlign: "right"}} footer={ordensServico.filter(os => os.status === "PENDENTE").length}/>
                <Column style={{textAlign: "right"}} footer={ordensServico.filter(os => ["EXECUTADO", "REVISADO"].includes(os.status)).length}/>
                <Column style={{textAlign: "right"}} footer={ordensServico.filter(os => os.status === "MALSUCEDIDO").length}/>
            </Row>
            <Row>
                <Column style={{textAlign: "right"}} colSpan={4} footer={<span>Total executado: {ordensServico.filter(os => os.status !== "PENDENTE").length}</span>}/>
            </Row>
        </ColumnGroup>
    );

    return (
        <>
            <If condition={projeto.id && projeto.inicio && projeto.fim}>
                <div className="col-12">
                    <div>
                        <span style={{float: "left"}}>{momentInicio.format("DD/MM/YYYY")} até {momentFim.format("DD/MM/YYYY")}</span>
                        <span style={{float: "right"}}>{percentualTempo}% do tempo transcorrido</span>
                    </div>
                    <div style={{clear: "both"}}/>
                    <ProgressBar color={percentualTempo > percentual ? "#F44336" : "#4CAF50"} showValue={false} value={percentualTempo}/>
                    <div style={{height: "2px"}}/>
                    <ProgressBar color={percentualTempo > percentual ? "#F44336" : "#4CAF50"} showValue={false} value={percentual}/>
                    <div>
                        <span style={{float: "left"}}>{executados} de {quantidadeEquipamentos} equipamentos</span>
                        <span style={{float: "right"}}>{percentual}% do projeto realizado</span>
                    </div>
                </div>
            </If>
            <div className="col-12">
                <TabView>
                    <TabPanel header="Consolidado (por Unidade)">
                        <PanelContent>
                            <div className="col-12 xm">
                                <DataTable
                                    headerColumnGroup={unidadesHeaderGroup}
                                    footerColumnGroup={unidadesFooterGroup}
                                    rows={50}
                                    paginator
                                    value={unidades}
                                    emptyMessage="Nenhum atendimento registrado"
                                >
                                    <Column sortable header="Unidade" field="autoComplete"/>
                                    <Column sortable style={{textAlign: "right"}} header="Pendentes" field="pendentes"/>
                                    <Column sortable style={{textAlign: "right"}} header="Executados" field="executados"/>
                                    <Column sortable style={{textAlign: "right"}} header="Malsucedidos" field="malsucedidos"/>
                                </DataTable>
                            </div>
                        </PanelContent>
                    </TabPanel>
                    <TabPanel header="Consolidado (por Modelo de Equipamento)">
                        <PanelContent>
                            <div className="col-12 xm">
                                <DataTable
                                    headerColumnGroup={unidadesHeaderGroup}
                                    footerColumnGroup={unidadesFooterGroup}
                                    rows={50}
                                    paginator
                                    value={modelos}
                                    emptyMessage="Nenhum atendimento registrado"
                                >
                                    <Column sortable header="Modelo" field="autoComplete"/>
                                    <Column sortable style={{textAlign: "right"}} header="Pendentes" field="pendentes"/>
                                    <Column sortable style={{textAlign: "right"}} header="Executados" field="executados"/>
                                    <Column sortable style={{textAlign: "right"}} header="Malsucedidos" field="malsucedidos"/>
                                </DataTable>
                            </div>
                        </PanelContent>
                    </TabPanel>
                    <TabPanel header="Detalhado">
                        <PanelContent>
                            <div className="col-12 xm">
                                <DataTable header={controlSet} rows={50} paginator value={ordensServico.filter(os => !os._hidden)} emptyMessage="Nenhum atendimento registrado">
                                    <Column sortable header="Status" style={{textAlign: "center"}} field="status" body={os => <PrintStatus ordemServico={os}/>}/>
                                    <Column sortable header="Técnico" field="responsavel.autoComplete"/>
                                    <Column sortable header="Unidade" field="cliente.autoComplete"/>
                                    <Column sortable header="Equipamento" field="equipamento.autoComplete"/>
                                    <Column sortable style={{textAlign: "center", width: "12em"}} header="Início" field="inicio" body={os => os.inicio && moment(os.inicio).format("DD/MM/YYYY HH:mm")}/>
                                    <Column sortable style={{textAlign: "center", width: "12em"}} header="Fim" field="fim" body={os => os.fim && moment(os.fim).format("DD/MM/YYYY HH:mm")}/>
                                </DataTable>
                            </div>
                        </PanelContent>
                    </TabPanel>
                </TabView>
            </div>
        </>
    );

}

function PrintStatus({ordemServico}) {
    switch (ordemServico.status) {
        case "REVISADO":
        case "EXECUTADO":
            return <i title="Finalizado com Sucesso" style={{color: "#4CAF50"}} className="fas fa-check-circle"/>;
        case "MALSUCEDIDO":
            return <i title="Malsucedido" style={{color: "#F44336"}} className="fas fa-exclamation-triangle"/>;
        default:
            return <i title="Aguardando Execução" style={{color: "#FF9800"}} className="fas fa-hourglass"/>;
    }
}

const optionsStatus = [
    {label: <span style={{whiteSpace: "nowrap"}}><i className="fas fa-asterisk"/> Todos</span>, value: ""},
    {label: <span style={{whiteSpace: "nowrap"}}><i className="fas fa-calendar-day"/> Agendados</span>, value: "AGENDADO"},
    {label: <span style={{whiteSpace: "nowrap"}}><i className="fas fa-hourglass"/> Pendentes</span>, value: "PENDENTE"},
    {label: <span style={{whiteSpace: "nowrap"}}><i className="fas fa-check-circle"/> Executados</span>, value: "EXECUTADO"},
    {label: <span style={{whiteSpace: "nowrap"}}><i className="fas fa-exclamation-triangle"/> Malsucedidos</span>, value: "MALSUCEDIDO"}
];
