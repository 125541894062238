import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import React, {useCallback, useEffect, useState} from 'react';
import {ProdutoAutoComplete} from '../../../components/autocomplete/produtoAutoComplete';
import {Button} from '../../../components/button';
import {DataTable} from '../../../components/datatable';
import {InputCurrency, InputNumber} from '../../../components/inputnumber';
import {produtoService} from '../../../service/produtoService';
import {formatCurrency, formatPercentage} from '../../../utils/numberFormatter';
import {withDialog} from "../../../utils/dialogContext";
import {ConfirmDialogV2} from "../../../components/confirmdialog";
import {isBiggerThanZero, isBiggerThanZeroValor, isEntityRequired, isNotNull} from '../../../utils/fieldValidator';
import {BlockUI} from 'primereact/blockui';
import {PanelContent} from '../../../components/panel';
import {codigoTributarioService} from "../../../service/codigoTributarioService";
import {Dropdown} from "../../../components/dropdown";

export const EditarPedidoVendaItens = withDialog(({pedidoVenda, setPedidoVenda, messages, disabled, showDialog, setMessages}) => {

    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [blocked, setBlocked] = useState(false);
    const [codigosTributarios, setCodigosTributarios] = useState([]);

    useEffect(() => {
        if (pedidoVenda.cfop && pedidoVenda.tipoMovimento) {
            codigoTributarioService.listarCodigosTributarios({cfop: pedidoVenda?.cfop.codigo, tipoMovimento: pedidoVenda.tipoMovimento.codigo}).then(codigosTributarios => {
                setCodigosTributarios(codigosTributarios.map(ct => ({label: ct.codigoTributario, value: ct.codigoTributario})));
            });
        }
    }, [pedidoVenda.cfop, pedidoVenda.sistema, pedidoVenda.tipoMovimento]);
   
    const  validarItem = (e, index, data) =>  {
        let erro;
        let itens = [];
        
		switch (e?.target.name) {
            case 'quantidade':
                erro = isBiggerThanZero(data.quantidade)
                itens = [...messages.itens]
                itens[index] = {...messages.itens[index], quantidade: erro}
                break;
            case 'valorUnitario':
                erro = isBiggerThanZeroValor(data.valorUnitario)
                itens = [...messages.itens]
                itens[index] = {...messages.itens[index], valorUnitario: erro}
                break;
            case 'produto':
                erro = isEntityRequired(data.produto)
                itens = [...messages.itens]
                itens[index] = {...messages.itens[index], produto: erro}
                break;
            case 'codigoTributario':
                erro = isNotNull(data.codigoTributario)
                itens = [...messages.itens]
                itens[index] = {...messages.itens[index], codigoTributario: erro}
                break;
            default:
                break;
        }
        setMessages((messages) => {
            return {
                ...messages,
                itens
            }
        });
	}

    const handleChange = useCallback((rowIndex, event) => {        
        const itens = pedidoVenda.itens.map((item, index) => {            
            if (rowIndex === index) {
                item[event.name] = event.value;
                switch (event.name) {
                    case "quantidade":
                    case "valorUnitario":
                        item.valorTotal = item.quantidade * item.valorUnitario;
                        item.valorTotalItem = item.quantidade * item.valorUnitario;
                        break;
                    default:
                        break;
                }
                return {...item} ;
            } else {
                return item;
            }
        });        
        setPedidoVenda({...pedidoVenda, itens});
    }, [pedidoVenda, setPedidoVenda]);

    const handleSelectProduto = useCallback((rowIndex, event) => {
        let item = pedidoVenda.itens[rowIndex];
        if (event.originalEvent?.type === "blur") return;
		if (event.value.id) {
            setBlocked(true);
            produtoService.buscar(event.value?.id).then(produto => {
                produtoService.calcularImpostos({
                    uf: pedidoVenda.cliente.vwCliente.uf,
                    procedencia: produto.vwProduto.procedencia,
                    percentualIpi: produto.vwProduto.percentualIpi,
                    percentualIcms: pedidoVenda.cliente.vwCliente.percentualIcms
                }).then(impostos => {
                    produtoService.buscarPreco({cliente: pedidoVenda.cliente?.id, produto:event.value?.id}).then(preco => {
                        item.produto = produto;
                        item.unidade = produto?.vwProduto?.unidade;
                        item.valorUnitario = preco.valorUnitario;
                        if (preco.listaPrecoItemId) {
                            item.listaPrecoItem = preco.listaPrecoItemId;
                        }
                        
                        item.percentualCofins = impostos.percentualCofins;
                        item.percentualPis = impostos.percentualPis;
                        item.percentualIpi = impostos.percentualIpi;
                        item.percentualIcms = impostos.percentualIcms;
                        pedidoVenda.itens.filter(i => i.sequencia === item.sequencia).forEach(I => Object.assign(I, item));
                        setBlocked(false);
                        setPedidoVenda({...pedidoVenda});
                    });
                });
            });
		}
    }, [pedidoVenda, setPedidoVenda]);

    const body = (rowData, props) => {           
        switch (props.field) { 
            case 'quantidade':
                return <InputNumber 
                    disabled={disabled} 
                    value={rowData.quantidade} 
                    min={0} name="quantidade" 
                    invalid={messages.itens?.[props.rowIndex]?.quantidade}
                    onChange={(e) => handleChange(props.rowIndex, e)}
                    onBlur={(e) => validarItem(e, props.rowIndex, rowData)}
                />;
            case 'valorUnitario':
                return <InputCurrency 
                    disabled={disabled}  
                    value={rowData.valorUnitario} 
                    min={0} 
                    name="valorUnitario" 
                    onChange={(e) => handleChange(props.rowIndex, e)} 
                    invalid={messages.itens?.[props.rowIndex]?.valorUnitario}
                    onBlur={(e) => validarItem(e, props.rowIndex, rowData)}
                />;
            case 'produto':
                return <ProdutoAutoComplete 
                    disabled={disabled}  
                    col={12} 
                    value={rowData.produto} 
                    onChange={(e) => handleChange(props.rowIndex, e)} 
                    onSelect={(e) => handleSelectProduto(props.rowIndex, e)} 
                    name="produto" 
                    label={null} 
                    invalid={messages.itens?.[props.rowIndex]?.produto}
                    onBlur={(e) => validarItem(e, props.rowIndex, rowData)}
                />;
            case 'codigoTributario':
                return (
                    <Dropdown disabled={disabled}
                              options={codigosTributarios}
                              name="codigoTributario"
                              label={null}
                              value={rowData.codigoTributario}
                              onChange={(e) => handleChange(props.rowIndex, e)}
                              invalid={messages.itens?.[props.rowIndex]?.codigoTributario}
                              onBlur={(e) => validarItem(e, props.rowIndex, rowData)}
                    />
                );
            default:
                return null;
        }
    };
    
    const impostos = (rowData, column) => {
		if (pedidoVenda.sistema !== 'OMEGA'){
			return ( 
				<div className="campo-impostos">
					{/* <div>Valor IPI</div><div title={`Percentual: ` + Number(rowData["percentualIpi"]) * 100 + `%`} style={{ textAlign: "right" }}>
						{formatCurrency(rowData["valorIpi"])}
					</div> */}
                    <div><b>IPI</b></div><div  style={{ textAlign: "right" }}>
						{formatPercentage(rowData?.percentualIpi * 100)}
					</div>
                    <div><b>ICMS</b></div><div  style={{ textAlign: "right" }}>
						{formatPercentage(rowData?.percentualIcms * 100)}
					</div>
                    <div><b>PIS</b></div><div  style={{ textAlign: "right" }}>
						{formatPercentage(rowData?.percentualPis * 100)}
					</div>
                    <div><b>COFINS</b></div><div  style={{ textAlign: "right" }}>
						{formatPercentage(rowData?.percentualCofins * 100)}
					</div>  
				</div>
			);
            
		} else {
			return(
				<div className="campo-impostos">
					<div>Valor IPI</div><div title={`Percentual: ` + Number(rowData["percentualIpi"]) * 100 + `%`} style={{ textAlign: "right" }}>
						{formatCurrency(rowData["valorIpi"])}
					</div>					
					<div>ICMS %</div><div style={{ textAlign: "right" }}>{formatPercentage(rowData.percentualIcms * 100 )}</div>					
				</div>
			);
		}
	}

    function adicionar() {        
        const adicionarItens = [
            {                       
                sequencia: pedidoVenda.itens?.reduce((maior, objeto) => (objeto.sequencia > maior ? objeto.sequencia : maior), 0) + 1,
                produto:null,
                cfop: null,
                tipoMovimento:null,
                valorUnitario: 0,
                valorTotal: 0,
                quantidade: 0,
                percentualIcms:0,    
                percentualIpi:0,
                valorIcms:0, 
                valorIpi:0,
                valorTotalItem:0,
                deposito:1,
                estabelecimento: 1,
                _key: Math.random() * 1000,
            },...pedidoVenda.itens 
        ];
        setPedidoVenda({...pedidoVenda, itens: adicionarItens});
        setMessages(messages => {
            return {
                ...messages,
                itens: [{quantidade:null, produto:null, codigosTributarios: null, valorUnitario: null}, ...messages.itens]
            }
        })
    }

    function excluir() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os itens selecionados?" onYes={() => {
            pedidoVenda.itens = pedidoVenda.itens.filter(osi => !itensSelecionados.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
            if (!pedidoVenda.id) {
                pedidoVenda.itens.forEach((osi, i) => osi.sequencia = i + 1);
            }
            handleChange({name: "itens", value: pedidoVenda.itens});
            setItensSelecionados([]);
        }}/>);
	}

    const valorTotalGeral = () => {
        let total = 0;
        for(let item of pedidoVenda.itens) {
            total += item.valorTotal;
        }
        return formatCurrency(total);
    }

    let footerGroup =   <ColumnGroup>
                            <Row>
                                <Column footer="Total " colSpan={8} footerStyle={{textAlign: 'right'}}/>                            
                                <Column footer={valorTotalGeral} footerStyle={{textAlign: 'right'}} />
                                <Column />
                            </Row>
                            <Row>
                                <Column colSpan={10} footer={
                                    <div>
                                        <Button
                                            title='Adicionar item'
                                            success
                                            disabled={disabled}
                                            icon='fa-solid fa-plus'
                                            onClick={adicionar}
                                            />
                                        <Button
                                            title='Excluir item'
                                            danger='true'
                                            icon='fa-solid fa-x'
                                            onClick={excluir}
                                            disabled={disabled || itensSelecionados.length === 0}
                                        />
                                    </div>
                                }/>
                            </Row>                            
                        </ColumnGroup>;           
                        
    


    return (
        <BlockUI blocked={blocked}>
            <PanelContent>
                <div className='col-12 p-0'>
                    <DataTable
                        size="small"
                        emptyMessage='Nenhum item adicionado.' 
                        paginator={true}                        
                        rows={10}
                        footerColumnGroup={footerGroup}
                        selectionMode="checkbox"
                        selection={itensSelecionados}
                        onSelectionChange={e => setItensSelecionados(e.value)}                    
                        value={pedidoVenda.itens} 
                        valuelength={pedidoVenda.itens?.length}                        
                    >
                        <Column selectionMode="multiple" headerStyle={{width: '1em'}}/>
                        <Column header="Item" style={{ width: '2em', textAlign: 'center'}} field="sequencia" name="sequencia"/>
                        <Column header="Produto"  style={{ width: '*'}}  field="produto" name="produto" body={body}/>
                        <Column header="Unidade"  alignHeader={'center'} style={{ width: '3em',textAlign: 'center'}} field="unidade" name="unidade"/>                        
                        <Column header="Procedência" alignHeader={'center'} style={{ width: '2em', textAlign: 'center'}} name="procedencia" body={p => p.produto?.vwProduto?.procedencia}/>
                        <Column header="Quantidade"  alignHeader={'center'} style={{ width: '10em'}} field="quantidade" name="quantidade" body={body}/>
                        <Column header="Valor Unitário"  alignHeader={'center'}  style={{ width: '12em'}} field="valorUnitario" name="valorUnitario" body={body}/>
                        <Column header="Impostos"  alignHeader={'center'} style={{ width: '15em'}} name="impostos" body={impostos}/>
			            <Column header="Valor Total"  style={{ width: '12em', textAlign: 'right'}} field="valorTotal" name="valorTotal" body={item => formatCurrency(item.valorTotal)}/>
                        <Column header="Código Tributário"  style={{ width: '10em'}} field="codigoTributario" name="codigoTributario" body={body}/>                        
                    </DataTable>
                </div>
            </PanelContent>
        </BlockUI>
    );

});