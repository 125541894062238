import React, {useState} from 'react';
import {AutoComplete} from '.';
import { estoqueService } from '../../service/estoqueService';


export function EstoqueAutoComplete(props) {

	const [estoques, setEstoques] = useState([]);
	const [emptyMessage, setEmptyMessage] = useState("")

	function completeMethod(event) {
		const query = [];
		if (event.query.length) query.push(`textoDeBusca=${encodeURIComponent(event.query)}`);
		estoqueService.autocomplete(query).then(res => {
			setEstoques(res);
			if (!res[0]) {
				setEmptyMessage("Nenhum registro encontrado");
			} else {
				setEmptyMessage("");
			}
		});
	}

	return (
		<AutoComplete
			col={3}
			placeholder="Código ou descrição"
			label={props.label}
			name="estoque"
			value={props.value}
			onChange={props.onChange}
			completeMethod={completeMethod}
			suggestions={estoques}
			invalid={emptyMessage}
			icon="fa-solid fa-receipt"
			{...props}
		/>
	);

}
