import {buildValidator} from "../utils/fieldValidator";
import {api} from "./api";
import {baseService} from "./baseService";

const resourceUrl = "/ordens-servico/etapas";

export const ordemServicoEtapaService = {
    criar: () => {
        return {
            id: null,
            _key: Math.random(),
            descricao: "",
            proximas: [],
            status: null
        };
    },
    validar: etapa => {
        return buildValidator();
    },
    validarAcompanhamento: acompanhamento => {
        const messages = buildValidator();
        if (!acompanhamento.etapa?.id) messages.etapa = "Campo obrigatório";
        if (!acompanhamento.responsavel?.id) messages.responsavel = "Campo obrigatório";
        return messages;
    },
    verificarPath: async (path) => {
        return api.get(`${resourceUrl}/verificar-path`, {
            params: {path: path}
        }).then(res => res.data);
    },
    async listarDescricoes(params) {
        return await api.get(`${resourceUrl}/descricoes?${params.join("&")}`).then(response => response.data);
    },
    ...baseService(resourceUrl)
};

export const optionsFluxoFlags = [
    {label: "Padrão para Corretivas", value: "PADRAO_CORRETIVA"},
    {label: "Padrão para Preventivas", value: "PADRAO_PREVENTIVA"},
    {label: "Padrão para Projetos", value: "PADRAO_PROJETO"},
    {label: "Alternativa para Corretivas", value: "CORRETIVA"},
    {label: "Alternativa para Preventivas", value: "PREVENTIVA"},
    {label: "Alternativa para Projetos", value: "PROJETO"}
];

export const optionsFluxoOperacoes = [
    {label: "Laboratório", value: "LABORATORIO"},
    {label: "Campo", value: "CAMPO"},
];
