import React, {useContext, useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {DataTable} from "../../../components/datatable";
import {BlockUI} from "primereact/blockui";
import {Column} from "primereact/column";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {Button} from "../../../components/button";
import {InputNumber} from "../../../components/inputnumber";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {AjusteEstoqueRecondicionada} from "./AjusteEstoqueRecondicionada";
import {DialogContext} from "../../../utils/dialogContext";
import {InputText} from "../../../components/inputtext";
import {SelectButton} from "../../../components/selectbutton";
import {formatNumber} from "chart.js/helpers";
import {estoqueMovimentoService} from "../../../service/estoqueMovimentoService";
import {HistoricoMovimentacaoEstoqueRecondicionado} from "./HistoricoMovimentacaoEstoqueRecondicionado";
import {useAuth} from "../../../context/AuthContext";
import {useQueryParams} from "../../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../../utils/defaultQueryParams";
import {useQueryClientEstoqueRecondicionado} from "../../../utils/query-clients/queryClients";
import {RefetchIndicator} from "../../../components/refetchindicator/RefetchIndicator";

export function EstoqueRecondicionado() {

    const {roles} = useAuth();
    const {showDialog} = useContext(DialogContext);
    const [produtos, setProdutos] = useState([]);
    const {query, handleSearch} = useQueryClientEstoqueRecondicionado();
    const {params, handleChange} = useQueryParams(QueryParamsKeys.EstoqueRecondicionado);

    useEffect(() => {
        if (query.data) {
            setProdutos(query.data);
        }
    }, [query.data]);

    function handleChangeProduto(event, produto) {
        setProdutos(prevProdutos => {
            prevProdutos.filter(p => p.id === produto.id).forEach(p => p.saldo = event.value);
            return [...prevProdutos];
        });
    }

    function atualizarSaldoProdutos() {
        showDialog(<AjusteEstoqueRecondicionada onSearch={handleSearch} produtos={produtos.filter(p => p.saldo != null)}/>);
    }

    const controlSet = (
        <div className="grid grid-nogutter p-fluid">
            <Button disabled={!produtos.some(p => p.saldo != null)} onClick={atualizarSaldoProdutos} success label="Salvar Alterações"/>
        </div>
    );

    const headerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column/>
                <Column colSpan={2} header={(
                    <table>
                        <tbody>
                            <tr>
                                <td>Total de registros:</td>
                                <td style={{textAlign: "right"}}>{formatNumber(produtos.length)}</td>
                            </tr>
                        </tbody>
                    </table>
                )}/>
            </Row>
            <Row>
                <Column sortable field="produtoId" rowSpan={2} header="Produto"/>
                <Column sortable field="saldoRecondicionadas" header="Saldo"/>
                <Column rowSpan={2} style={{width: "5em", textAlign: "center"}} header="Ações"/>
            </Row>
            <Row>
                <Column style={{width: "15em"}} header={controlSet}/>
            </Row>
        </ColumnGroup>
    );

    const footerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column/>
                <Column style={{width: "15em"}} footer={controlSet}/>
                <Column/>
            </Row>
            <Row>
                <Column/>
                <Column colSpan={2} footer={(
                    <table>
                        <tbody>
                        <tr>
                            <td>Total de registros:</td>
                            <td style={{textAlign: "right"}}>{formatNumber(produtos.length)}</td>
                        </tr>
                        </tbody>
                    </table>
                )}/>
            </Row>
        </ColumnGroup>
    );

    function handleHistory(event) {
        estoqueMovimentoService.listar([`produto=${event.data.id}`, "estoque=3"]).then(movimentos => {
            showDialog(<HistoricoMovimentacaoEstoqueRecondicionado movimentos={movimentos}/>);
        });
    }

    return (
        <BlockUI blocked={query.isLoading}>
            <Panel header="Estoque de Peças Recondicionadas">
                <PanelContent>
                    <InputText label="Produto" placeholder="o código do produto" col={3} name="descricao" value={params.descricao} onChange={handleChange}/>
                    <SelectButton label="Tipo de Produto" value={params.tipo} name="tipo" options={optionsTiposProdutos} onChange={handleChange} col={3}/>
                </PanelContent>
                <PanelFooter>
                    <Button loading={query.isLoading || query.isRefetching} disabled={query.isLoading || query.isRefetching} label="Procurar" icon="fas fa-search" onClick={handleSearch}/>
                </PanelFooter>
            </Panel>
            <DataTable headerColumnGroup={headerColumnGroup} footerColumnGroup={footerColumnGroup} value={produtos}>
                <Column sortable header="Produto" field="autoComplete"/>
                <Column header="Saldo" field="saldoRecondicionadas" body={p => (
                    <div className="grid grid-nogutter p-fluid">
                        <InputNumber readOnly={!roles.EMVC} col={12} showButtons onChange={e => handleChangeProduto(e, p)} reduced value={p.saldo || p.saldoRecondicionadas}/>
                    </div>
                )}/>
                <Column header="Ações" body={p => (
                    <div style={{textAlign: "center"}}>
                        <Button title="Histórico de Movimentação do Produto" icon="fas fa-file-text" onClick={() => handleHistory({data: p})}/>
                    </div>
                )}/>
            </DataTable>
            <RefetchIndicator isRefetching={query.isRefetching}/>
        </BlockUI>
    );

}

const optionsTiposProdutos = [
    {label: "Todos", value: ""},
    {label: "ZCD", value: "ZCD"},
    {label: "TCB", value: "TCB"}
];
