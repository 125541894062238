import React, {useContext, useMemo} from "react";
import {calendarioService} from "../service/calendarioService";
import {useQuery} from "@tanstack/react-query";

const CalendarioContext = React.createContext({feriados: []});

export function CalendarioProvider({children}) {
    const query = useQuery({
        queryKey: ["Calendario-Padrao"],
        queryFn: () => calendarioService.padrao(),
        staleTime: 60000
    });
    const value = useMemo(() => ({feriados: query.data?.feriados || []}), [query.data?.feriados]);
    return <CalendarioContext.Provider value={value} children={children}/>;
}

export function useCalendarioPadrao() {
    return useContext(CalendarioContext);
}
