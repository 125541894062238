import React, {useState} from "react";
import {withDialog} from "../../utils/dialogContext";
import {Dropdown} from "../../components/dropdown";
import {InputCurrency, InputNumber} from "../../components/inputnumber";
import {ProdutoAutoComplete} from "../../components/autocomplete/produtoAutoComplete";
import {ServicoAutoComplete} from "../../components/autocomplete/servicoAutoComplete";
import {EquipamentoAutoComplete} from "../../components/autocomplete/equipamentoAutoComplete";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {Button} from "../../components/button";
import {contratoService, optionsModoPeriodicidade} from "../../service/contratoService";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {isBiggerThanZero, isEntityRequired, isRequired} from "../../utils/fieldValidator";
import {CadastrarEquipamentosLote} from "./CadastrarEquipamentosLote";

const headersTipo = {
	produtos: "Produto",
	servicos: "Serviço",
	equipamentos: "Equipamento"
};

function emptyParams() {
	return {
		modoPeriodicidade: null,
		periodicidade: null,
		quantidade: null,
		quantidadeMauUso: null,
		quantidadeEquipamento: null
	};
}

export const EditarContratoCoberturaEquipamento = withDialog(({showDialog, messages, setMessages, contrato, setContrato}) => {

	const [itensSelecionados, setItensSelecionados] = useState([]);
	const [params, setParams] = useState(emptyParams());

	function adicionarItem() {
		contrato.equipamentos.unshift({
			...contratoService.criarItem(),
			quantidade: contrato.padraoEquipamentoQuantidade,
			quantidadeMauUso: contrato.padraoEquipamentoQuantidadeMauUso
		});
		setContrato({...contrato});
	}

	function removerItens() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os itens selecionados?" onYes={() => {
			contrato.equipamentos = contrato.equipamentos.filter(osi => !itensSelecionados.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
			setContrato({...contrato});
			setItensSelecionados([]);
		}}/>);
	}

	function handleChangeContrato(event) {
		setContrato(prevContrato => ({...prevContrato, [event.name]: event.value}));
	}

	function handleChange(event) {
		contrato.equipamentos[event.index][event.name] = event.value;
		setContrato({...contrato});
	}

	function handleChangeParams(event) {
		setParams({...params, [event.name]: event.value});
		for (const item of contrato.equipamentos) {
			item[event.name] = event.value;
		}
		setContrato({...contrato});
	}

	function onBlurParams() {
		setParams(emptyParams());
	}

	const controlSet = (
		<div>
			<Button icon="pi pi-plus" onClick={adicionarItem}/>
			<Button disabled={!itensSelecionados?.length} onClick={removerItens} danger icon="pi pi-minus"/>
			<Button style={{width: "250px", whiteSpace: "nowrap"}} label="Cadastrar Equipamentos em Lote" icon="fas fa-save" onClick={handleCadastrarEquipamentosLote}/>
		</div>
	);

	function handleCadastrarEquipamentosLote() {
		showDialog(
			<CadastrarEquipamentosLote 
				onModalClose={equipamentos => {
					if (equipamentos?.length) {
						if (!contrato.equipamentos) {
							contrato.equipamentos = [];
						}
						contrato.equipamentos = contrato.equipamentos.filter(e => !equipamentos.some(x => x.id === e.id));
						for (const equipamento of equipamentos) {
							contrato.equipamentos.unshift({equipamento});
						}
						setContrato({...contrato});
					}
				}}
				contrato={contrato}
			/>
		);
	}

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={3} header={controlSet}/>
				<Column header={(
					<div style={{textAlign: "right"}}>
						Padrão<br/>
						Novos Equipamentos
					</div>
				)}/>
				<Column header={<InputNumber onChange={handleChangeContrato} reduced value={contrato.padraoEquipamentoQuantidade} name="padraoEquipamentoQuantidade"/>}/>
				<Column header={<InputNumber onChange={handleChangeContrato} reduced value={contrato.padraoEquipamentoQuantidadeMauUso} name="padraoEquipamentoQuantidadeMauUso"/>}/>
			</Row>
			<Row>
				<Column rowSpan={3} selectionMode="multiple" style={{width: "3em"}}/>
				<Column rowSpan={3} header={headersTipo.equipamentos}/>
				<Column rowSpan={2} colSpan={2} header={<div style={{textAlign: "center"}}>Periodicidade</div>}/>
				<Column colSpan={2} header={<div style={{textAlign: "center"}}>Limites</div>}/>
			</Row>
			<Row>
				<Column header="Total"/>
				<Column header={<span>Por<br/>Mau Uso</span>}/>
			</Row>
			<Row>
				<Column style={{width: "8em"}} header={<InputNumber reduced onBlur={onBlurParams} name="periodicidade" value={params.periodicidade} onChange={handleChangeParams}/>}/>
				<Column style={{width: "15em"}} header={<Dropdown reduced onBlur={onBlurParams} options={optionsModoPeriodicidade} name="modoPeriodicidade" value={params.modoPeriodicidade} onChange={handleChangeParams}/>}/>
				<Column style={{width: "8em"}} header={<InputNumber reduced onBlur={onBlurParams} name="quantidade" value={params.quantidade} onChange={handleChangeParams}/>}/>
				<Column style={{width: "8em"}} header={<InputNumber reduced onBlur={onBlurParams} name="quantidadeMauUso" value={params.quantidadeMauUso} onChange={handleChangeParams}/>}/>
			</Row>
		</ColumnGroup>
	);

	const footerGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={11} footer={controlSet}/>
			</Row>
		</ColumnGroup>
	);

	function validarItens() {
		messages.equipamentos = [...Array(contrato.equipamentos.length)].map((_, i) => ({
			periodicidade: contrato.equipamentos[i].modoPeriodicidade === "CONTRATO" ? null : isBiggerThanZero(contrato.equipamentos[i].periodicidade, "Obrigatório"),
			modoPeriodicidade: isRequired(contrato.equipamentos[i].modoPeriodicidade),
			equipamento: isEntityRequired(contrato.equipamentos[i].equipamento),
		}));
		setMessages({...messages});
	}

	return (
		<div className="col-12">
			<DataTable selectionMode="checkbox"
			           selection={itensSelecionados}
			           onSelectionChange={e => setItensSelecionados(e.value)}
			           footer={null}
			           headerColumnGroup={headerGroup}
			           footerColumnGroup={footerGroup}
			           value={contrato.equipamentos}
			>
				<Column selectionMode="multiple"/>
				<Column style={{flexDirection: "column", alignItems: "start"}} field="tipo" header="" body={(cc, c) => (
					<EquipamentoAutoComplete 
						invalid={messages.equipamentos?.[c.rowIndex]?.equipamento} 
						col={12} 
						index={c.rowIndex} 
						name="equipamento" 
						onBlur={validarItens} 
						value={cc.equipamento} 
						onChange={handleChange} 
						label={null}
						repetidos={contrato?.equipamentos?.map(equipamento => {
							return equipamento.equipamento?.serial
						})}
					/>
				)}/>
				<Column style={{flexDirection: "column", alignItems: "start", width: "8em"}} field="periodicidade" header="&nbsp;" body={(cc, c) => <InputNumber invalid={messages.equipamentos?.[c.rowIndex]?.periodicidade} onBlur={validarItens} disabled={cc.modoPeriodicidade === "CONTRATO"} label={null} value={cc.modoPeriodicidade === "CONTRATO" ? "" : cc.periodicidade} index={c.rowIndex} name="periodicidade" onChange={handleChange}/>}/>
				<Column style={{flexDirection: "column", alignItems: "start"}} field="modoPeriodicidade" header="Periodicidade" body={(cc, c) => <Dropdown invalid={messages.equipamentos?.[c.rowIndex]?.modoPeriodicidade} onBlur={validarItens} options={optionsModoPeriodicidade} label={null} value={cc.modoPeriodicidade} index={c.rowIndex} name="modoPeriodicidade" onChange={handleChange}/>}/>
				<Column style={{flexDirection: "column", alignItems: "start"}} field="quantidade" header="Total" body={(cc, c) => <InputNumber invalid={messages.equipamentos?.[c.rowIndex]?.quantidade} onBlur={validarItens} label={null} value={cc.quantidade} index={c.rowIndex} name="quantidade" onChange={handleChange}/>}/>
				<Column style={{flexDirection: "column", alignItems: "start"}} field="quantidadeMauUso" header="Por Mau Uso" body={(cc, c) => <InputNumber invalid={messages.equipamentos?.[c.rowIndex]?.quantidadeMauUso} onBlur={validarItens} label={null} value={cc.quantidadeMauUso} index={c.rowIndex} name="quantidadeMauUso" onChange={handleChange}/>}/>
			</DataTable>
		</div>
	);

});
