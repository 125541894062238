import {saveAs} from 'file-saver';
import moment from "moment";
import {FilterMatchMode} from 'primereact/api';
import {BlockUI} from "primereact/blockui";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {SelectButton} from "primereact/selectbutton";
import {TabPanel} from "primereact/tabview";
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {Button} from "../../components/button";
import {Calendar} from "../../components/calendar";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {DataTable} from "../../components/datatable";
import {Dropdown} from "../../components/dropdown";
import {OrdemServicoFluxoDropdown} from "../../components/dropdown/ordemServicoFluxoDropdown";
import {OrdemServicoTipoDropdown} from "../../components/dropdown/ordemServicoTipoDropdown";
import {FormTab} from '../../components/formtab';
import {InputMask} from "../../components/inputmask";
import {InputText} from "../../components/inputtext";
import {PanelContent} from "../../components/panel";
import {useAuth} from "../../context/AuthContext";
import {isStacked, useBreakpoint} from "../../context/BreakpointContext";
import {arquivoService} from "../../service/arquivoService";
import {contratoService} from "../../service/contratoService";
import {imprimirEtiquetaLaudoZebra, imprimirEtiquetaOsZebra} from "../../service/etiquetaService";
import {eventoService} from "../../service/eventoService";
import {ordemServicoFluxoService} from "../../service/ordemServicoFluxoService";
import {ordemServicoItemService} from "../../service/ordemServicoItemService";
import {iconStatus, labelStatus, optionsStatus, ordemServicoService} from "../../service/ordemServicoService";
import {useDialog, withDialog} from "../../utils/dialogContext";
import {buildValidator, isEmpty, isEntityRequired, isNotNull, isRequired, isRequiredEmail} from "../../utils/fieldValidator";
import {formatCurrency} from "../../utils/numberFormatter";
import {EditarEvento} from "../Eventos/EditarEvento";
import {EditarAcompanhamento} from "./EditarAcompanhamento";
import {EditarLaudo} from "./EditarLaudo";
import {EditarOrdemServicoItem, EditarOrdemServicoItemFields} from "./EditarOrdemServicoItem";
import "./styles.scss";
import {InformationDialog} from '../../components/dialog/InformationDialog';
import {EditarOrdemServicoLaudo} from "./EditarOrdemServicoLaudo";
import {CondicaoPagamentoAutoComplete} from '../../components/autocomplete/CondicaoPagamentoAutoComplete';
import {EditarOrdemServicoNotaFiscal} from "./EditarOrdemServicoNotaFiscal";
import {ordemServicoNotaFiscalService} from "../../service/ordemServicoNotaFiscalService";
import {InputTextarea} from '../../components/inputtextarea';
import {Checkbox} from '../../components/checkbox';
import {VisualizarMovimentosEstoque} from "./VisualizarMovimentosEstoque";
import {Acompanhamento} from "../__Commons/Acompanhamento";
import {SLA} from "../__Commons/SLA";
import {If} from '../../components/conditional/If';
import {TrocarContrato} from "./TrocarContrato";
import {ClienteContratoDropdown} from "../../components/autocomplete/ClienteContratoDropdown";
import {Tooltip} from "primereact/tooltip";

const ordemServicoItemStatus = {
	PENDENTE: <i className="fa-solid fa-hourglass" style={{color: "#FF9800"}}/>,
	EXECUTADO: <i className="fa-solid fa-check" style={{color: "#4CAF50"}}/>,
	REVISADO: <i className="fa-solid fa-check-double" style={{color: "#4CAF50"}}/>
};

const optionsItemStatus = [
	{label: <i className="fas fa-asterisk" title="Todos"/>, value: null},
	{label: <i className="fas fa-hourglass" title="Pendentes"/>, value: "PENDENTE"},
	{label: <i className="fas fa-check" title="Executados"/>, value: "EXECUTADO"},
	{label: <i className="fas fa-check-double" title="Revisados"/>, value: "REVISADO"},
	{label: <i className="fas fa-exclamation-triangle" title="Malsucedidos"/>, value: "MALSUCEDIDO"}
];

export function renderPosicaoPrevisaoAtendimento(ospa) {
	if (ospa.inicio) {
		if (ospa.fim) {
			if (moment(ospa.previsao).isBefore(moment(ospa.fim))) {
				return <i className="fa-solid fa-circle" style={{color: "#F44336"}}/>;
			}
			return <i className="fa-solid fa-circle" style={{color: "#4CAF50"}}/>;
		}
		if (ospa.previsao) {
			if (moment(ospa.previsao).isBefore(moment())) {
				return <i className="fa-solid fa-circle" style={{color: "#F44336"}}/>;
			}
		}
	}
	return <i className="fa-solid fa-hourglass-half" style={{color: "#B0BEC5"}}/>;
}

export function EditarOrdemServico() {

	const {id} = useParams();
	const {showDialog} = useDialog();
	const navigate = useNavigate();
	const {roles, usuario} = useAuth();
	const {breakpoint} = useBreakpoint();
	const isLargeDevice = breakpoint === "lg" || breakpoint === "md";
	const [loading, setLoading] = useState(false);
	const [etapas, setEtapas] = useState([]);
	const [ordemServico, setOrdemServico] = useState({...ordemServicoService.criar(), responsavel: usuario});
	const [item, setItem] = useState(null);
	const [itemIndex, setItemIndex] = useState(-1);
	const [laudo, setLaudo] = useState(null);
	const [acompanhamento, setAcompanhamento] = useState(null);
	const [itensSelecionados, setItensSelecionados] = useState([]);
	const [messages, setMessages] = useState({...buildValidator(), itens: []});
	const [evento, setEvento] = useState(null);
	const [fluxos, setFluxos] = useState([]);
	const [blocked, setBlocked] = useState(false);
	const [itemStatus, setItemStatus] = useState(null);
	const [trocarTipo, setTrocarTipo] = useState(false);
	const [trocarFluxo, setTrocarFluxo] = useState(false);
	const [trocarEtapa, setTrocarEtapa] = useState(false);

	async function handleChange(event) {
		switch (event.name) {
			case "tipo":
				switch (event.value) {
					case "CORRETIVA":
						switch (ordemServico.itens?.length) {
							case 0:
								const item = ordemServicoItemService.criar();
								item.sequencia = ordemServico.itens?.map(osi => osi.sequencia).reduce((a, b) => Math.max(a, b), 0) + 1;
								ordemServico.itens.push(item);
								break;
							case 1:
								break;
							default:
								if (event.value === "CORRETIVA") {
									showDialog(<InformationDialog message="Não é possível trocar pra corretiva uma ordem de serviço com mais de um item"/>);
									return;
								}
								break;
						}
						break;
					case "PREVENTIVA":
					case "PROJETO":
						if (ordemServico.itens?.length === 1 && !ordemServico.itens[0].equipamento?.serial?.length) {
							ordemServico.itens = [];
						}
						break;
					default:
						break;
				}
				break;
			case "_fluxo":
				if (event.value) {
					ordemServico._fluxoAlterado = true;
					ordemServico.etapas = [...event.value.etapas];
					ordemServico.etapa = ordemServico.etapas.sort((a, b) => a.sequencia - b.sequencia)[0];
					ordemServico.operacao = event.value.operacao;
					setEtapas(ordemServico.etapas.map(e => ({label: e.descricao, value: e})));
				} else {
					ordemServico.etapas = [];
					ordemServico.etapa = null;
					setEtapas([]);
				}
				break;
			case "contrato":
				if (event.value?.id) {
					ordemServico.condicaoPagamento = event.value.condicaoPagamento;
				}
				if (ordemServico.id && ordemServico.contrato?.id !== event.value?.id && ordemServico.itens?.some(i => i.pecas?.length || i.servicos?.length)) {
					showDialog(<TrocarContrato ordemServico={ordemServico} novoContrato={event.value} onModalClose={setOrdemServico}/>);
					return;
				}

				ordemServico[event.name] = event.value;
				return setOrdemServico({...ordemServico});
			case "responsavel":
				if (event.value?.id) {
					event.value._manual = true;
				}
				break;
			case "status":
				ordemServico._status = "manual";
				break;
			case "etapa":
				ordemServico._etapaAlterada = true;
				if (ordemServico.operacao === "LABORATORIO" && event.value?.status === "FATURAMENTO" && !ordemServico.notaFiscal?.numero && !ordemServico.naoGerarRetorno) {
					showDialog(<InformationDialog header="Informação" message="Não é possível encaminhar a ordem de serviço para faturamento sem nota fiscal de entrada."/>);
					return;
				}
				break;
			default:
				break;
		}
		ordemServico[event.name] = event.value;
		setOrdemServico({...ordemServico, _alterado: !event.automatico});
	}

	useEffect(() => {
		ordemServicoFluxoService.listar(["query="]).then(setFluxos);
		if (id !== "new") {
			ordemServicoService.buscarOrdemServico(id).then(ordemServico => {
				setEtapas(ordemServico.etapas.map(e => ({label: e.descricao, value: e})));
				if (ordemServico.acompanhamentos) {
					ordemServico.acompanhamentos = ordemServico.acompanhamentos.sort((a, b) => {
						if (a.inicio !== b.inicio) {
							return a.inicio.localeCompare(b.inicio);
						}
						return a.fim ? 1 : b.fim ? -1 : 0;
					});
				}
				setOrdemServico(ordemServico);
			});
		}
	}, [id]);

	function renderCamposCorretiva() {
		return (
			<EditarOrdemServicoItemFields index={0}
			                              ordemServico={ordemServico}
			                              setOrdemServico={setOrdemServico}
			                              messages={messages}
			                              setMessages={setMessages}
			                              item={ordemServico.itens[0]}
			                              setItem={setItem}
			                              handleChange={event => {
										      ordemServico.itens[0][event.name] = event.value;
										      setOrdemServico({...ordemServico});
										  }}
			                              handleSalvar={handleSalvar}
			                              setLaudo={setLaudo}
			/>
		);
	}

	async function editarItem(event) {
		setItemIndex(event.index);
		setBlocked(true);
		setItem(await ordemServicoItemService.buscar(event.data.id));
		setBlocked(false);
	}

	function adicionarItem() {
		const item = ordemServicoItemService.criar();
		item.ordemServico = {id: ordemServico.id};
		const itemIndex = ordemServico.itens.length;
		item.sequencia = ordemServico.itens.map(osi => osi.sequencia).reduce((a, b) => Math.max(a, b), 0) + 1;
		setItemIndex(itemIndex);
		setItem(item);
	}

	const removerItens = useCallback(() => {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja excluir os itens selecionados?" onYes={async () => {
			ordemServico.itens = ordemServico.itens.filter(osi => !itensSelecionados.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
			if (!ordemServico.id) {
				ordemServico.itens.forEach((osi, i) => osi.sequencia = i + 1);
			}
			setOrdemServico({...ordemServico});
			setMessages(ordemServicoService.validar(ordemServico));
			for (const itemSelecionado of itensSelecionados) {
				await ordemServicoItemService.excluir(itemSelecionado.id);
			}
			setItensSelecionados([]);
		}}/>);
	}, [itensSelecionados, ordemServico, showDialog]);

	function adicionarPorContrato() {
		setBlocked(true);
		
		contratoService.listarEquipamentosPorContrato(ordemServico.contrato.id, ordemServico.cliente?.id).then(async equipamentos => {
			let i = 1;
			for (const equipamento of equipamentos) {
				ordemServico.itens.push(await ordemServicoItemService.salvar({...ordemServicoItemService.criar(), ordemServico, sequencia: i++, equipamento, postBack: true}));
			}
			setOrdemServico({...ordemServico});
			setBlocked(false);
		});
	}

	function alternarStatusItens(status) {
		for (const item of itensSelecionados) {
			item.status = status;
			item._alterado = true;
		}
		setOrdemServico({...ordemServico});
		setItensSelecionados([]);
	}


	const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');
	
    const onGlobalFilterChange = (e) => {
        const value = e.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

	function renderCamposPreventiva() {
		const itemControlSet = (
			<div className="py-2">
				<div className="mb-2">
					<InputText 
						col={6} 
						value={globalFilterValue} 
						onChange={onGlobalFilterChange} 
						placeholder="Código, Modelo, nº de série ou descrição" 
						reduced
						class_names={{
							'padding-0': 'true'
						}}						
					/>
				</div>
				<div>
					<Button disabled={!ordemServico.id} title="Adicionar Item" marginRight onClick={adicionarItem} icon="pi pi-plus"/>
					<Button disabled={!ordemServico.id || !itensSelecionados?.length} title="Remover Item" onClick={removerItens} danger icon="pi pi-minus"/>
					<Button title="Marcar Item como Pendente" disabled={!itensSelecionados?.length} onClick={() => alternarStatusItens("PENDENTE")} warning icon="fa-solid fa-hourglass"/>
					<Button title="Marcar Item como Executado" disabled={!itensSelecionados?.length} onClick={() => alternarStatusItens("EXECUTADO")} success icon="fa-solid fa-check"/>
					<If condition={ordemServico.tipo !== "PROJETO"}>
						<Button title="Marcar Item como Executado, Limpo e Revisado" disabled={!itensSelecionados?.length} onClick={() => alternarStatusItens("REVISADO")} success icon="fa-solid fa-check-double"/>
					</If>
					<Button title="Marcar item como Malsucedido" disabled={!itensSelecionados?.length} onClick={() => alternarStatusItens("MALSUCEDIDO")} danger icon="fa-solid fa-exclamation-triangle"/>
					{["PREVENTIVA", "PROJETO"].includes(ordemServico.tipo) && ordemServico.contrato?.id && !ordemServico.itens?.length ? <Button disabled={!ordemServico.id} title="Adicionar Todos os Equipamentos do Contrato" warning onClick={adicionarPorContrato} icon="fa-solid fa-signature"/> : null}
					<div style={{float: "right", minWidth: "300px"}}>
						<SelectButton options={optionsItemStatus.filter(is => ordemServico.tipo !== "PROJETO" || is.value !== "REVISADO")} value={itemStatus} onChange={e => setItemStatus(e.value)}/>
					</div>
				</div>
			</div>
		);
		const itemHeaderGroup = (
			<ColumnGroup>
				<Row>
					<Column colSpan={7} header={itemControlSet}/>
					<Column headerStyle={{textAlign: "right"}} header={
						<div>{formatCurrency(ordemServico?.itens?.map(osi => osi.valorTotal || 0).reduce((a, b) => a + b, 0))}</div>
					}/>
				</Row>
				<Row>
					<Column selectionMode="multiple" headerStyle={{width: "3em"}}/>
					<Column style={{width: "3em"}} header=""/>
					<Column style={{width: "7em"}} alignHeader={'center'} header="Nº OS Omega"/>
					<Column style={{width: "6em"}} alignHeader={'center'} header="Item"/>
					<Column header="Código"/>
					<Column header="Modelo"/>
					<Column header="Nº de Série"/>
					<Column style={{width: "15em"}} alignHeader={'center'} header="Valor Total"/>
				</Row>
			</ColumnGroup>
		);
		const itemFooterGroup = (
			<ColumnGroup>
				<Row>
					<Column colSpan={7} footer={itemControlSet}/>
					<Column footerStyle={{textAlign: "right"}} footer={formatCurrency(ordemServico?.itens?.map(osi => osi.valorTotal || 0).reduce((a, b) => a + b, 0))}/>
				</Row>
			</ColumnGroup>
		);
		return (
			<PanelContent>
				<div className="col-12 p-0 blockable-panel">
					<DataTable value={ordemServico.itens?.filter(osi => !itemStatus || osi.status === itemStatus)}
					           header={isStacked() ? null : itemControlSet}
					           footer={isStacked() ? null : itemControlSet}
					           headerColumnGroup={itemHeaderGroup}
					           footerColumnGroup={itemFooterGroup}
					           emptyMessage="Nenhum item adicionado"
					           selection={itensSelecionados}
					           onSelectionChange={e => setItensSelecionados(e.value)}
					           selectionMode="checkbox"
					           onRowDoubleClick={editarItem}
					           dataKey="id"
					           filters={filters}
					           globalFilterFields={['equipamento.produto.codigo', 'equipamento.serial', 'equipamento.produto.descricao', 'valorTotal']}
					>
						<Column selectionMode="multiple"/>
						<Column header="Status" style={{textAlign: "center"}} field="status" body={osi => ordemServicoItemStatus[osi.status]}/>
						<Column header="Nº OS Omega" style={{textAlign: "right"}} field="numero" name="numero"/>
						<Column header="Item" field="sequencia" style={{textAlign: "right"}} body={(osi, col) => {
							const item = messages.itens[col.rowIndex];
							if (item && (item.equipamento || item.pecas?.some(osip => osip.produto || osip.quantidade))) {
								return <span><i className="fa-solid fa-circle" style={{marginRight: ".5em", verticalAlign: "middle", float: "left", color: "#F44336"}}/> {osi.sequencia}</span>;
							}
							return osi.sequencia;
						}}/>
						<Column header="Código" field="equipamento.produto.codigo" body={osi => osi.equipamento?.produto?.codigo}/>
						<Column header="Modelo" field="equipamento.produto.descricao" body={osi => osi.equipamento?.produto?.descricao || "Não identificado"}/>
						<Column header="Nº de Série" field="equipamento.serial" filter filterPlaceholder="Search by name" body={osi => osi.equipamento?.serial}/>
						<Column header="Valor Total" field="valorTotal" style={{textAlign: "right"}} body={osi => formatCurrency(osi.valorTotal)}/>
					</DataTable>
					{ordemServico.id ? null : <div className="blockable-panel-blocker"/>}
				</div>
			</PanelContent>
		);
	}

	function salvarItem(item) {
		const totalPecas = item.pecas.filter(pecas => pecas.status !== 'CANCELADO').map(osip => osip.valorTotal || 0).reduce((a, b) => a + b, 0);
		const totalServicos = item.servicos.map(osis => osis.valorTotal || 0).reduce((a, b) => a + b, 0);
		item.valorTotal = totalPecas + totalServicos;
		const index = ordemServico.itens?.findIndex(osi => (osi._key && item._key === osi._key) || (osi.id && item.id === osi.id));
		if (index > -1) {
			ordemServico.itens[index] = item;
		} else {
			ordemServico.itens.push(item);
		}
		setOrdemServico({...ordemServico});
		setMessages(ordemServicoService.validar(ordemServico));
		setItem(null);
	}

	async function salvarAcompanhamento(acompanhamento) {
		const index = ordemServico.acompanhamentos.findIndex(osa => (osa._key && osa._key === acompanhamento._key) || (osa.id && osa.id === acompanhamento.id));
		if (index > -1) {
			ordemServico.acompanhamentos[index] = acompanhamento;
		} else {
			ordemServico.acompanhamentos.push(acompanhamento);
		}
		setOrdemServico({...ordemServico});
		setLoading(true);
		await handleSalvar();
		handleVoltar();
	}

	async function handleSalvar() {
		console.log('INICIANDO PROCESSO DE SALVAR A ORDEM DE SERVIÇO')
		const messages = ordemServicoService.validar(ordemServico);
		setMessages(messages);
		if (!messages.isEmpty()) {
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
			return;
		}
		if (ordemServico.itens && ordemServico.notaFiscal?.numero && ordemServico.notaFiscal.itens?.length) {
			const checks = [];
			for (const item of ordemServico.itens) {
				if (item.equipamento?.id && !ordemServico.notaFiscal.itens?.some(nfi => nfi.equipamento?.id === item.equipamento.id)) {
					checks.push(`${item.equipamento.autoComplete} não pertence à nota fiscal desta OS`);
				}
			}
			if (checks.length) {
				showDialog(<InformationDialog header="Informação" message={checks.map(c => <div key={c}>{c}</div>)}/>);
				return;
			}
		}
		if (!ordemServico.itens?.some(osi => osi.orcamentos?.some(osio => osio.status === "APROVADO"))) {
			ordemServico.contratoPeriodoConsumo = null;
		}
		setBlocked(true);
		if (ordemServico.notaFiscal?.numero && (!ordemServico.notaFiscal.id || ordemServico.notaFiscal._alterado)) {
			ordemServico.notaFiscal.postBack = true;
			ordemServico.notaFiscal.cliente = ordemServico.cliente;
			ordemServico.notaFiscal = await ordemServicoNotaFiscalService.salvar(ordemServico.notaFiscal);
		} else if (!ordemServico.notaFiscal?.numero) {
			ordemServico.notaFiscal = null;
		}
		if (!ordemServico.id) {
			if (ordemServico._fluxo?.id) {
				ordemServico.fluxo = `${ordemServico._fluxo.descricao} (v${ordemServico._fluxo.versao})`;
				ordemServico.operacao = ordemServico._fluxo.operacao;
			}
			const acompanhamento = ordemServicoService.criarAcompanhamento();
			acompanhamento.etapa = ordemServico.etapa;
			acompanhamento.observacoes = "Ordem de serviço iniciada";
			acompanhamento.atribuinte = usuario;
			acompanhamento.responsavel = usuario;
			ordemServico.acompanhamentos.push(acompanhamento);
		} else {
			if (ordemServico._fluxoAlterado) {
				ordemServico.fluxo = `${ordemServico._fluxo.descricao} (v${ordemServico._fluxo.versao})`;
				ordemServico.operacao = ordemServico._fluxo.operacao;
				const acompanhamento = ordemServicoService.criarAcompanhamento();
				acompanhamento.etapa = ordemServico.etapa;
				acompanhamento.observacoes = "Fluxo alterado manualmente";
				acompanhamento.atribuinte = usuario;
				acompanhamento.responsavel = usuario;
				ordemServico.acompanhamentos[ordemServico.acompanhamentos.length - 1].tipo = "CORRECAO";
				ordemServico.acompanhamentos.push(acompanhamento);
				ordemServico._fluxoAlterado = false;
			}
			if (ordemServico._etapaAlterada) {
				const acompanhamento = ordemServicoService.criarAcompanhamento();
				acompanhamento.inicio = moment().add(1, "seconds").format("YYYY-MM-DDTHH:mm:ss");
				acompanhamento.etapa = ordemServico.etapa;
				acompanhamento.observacoes = "Etapa alterada manualmente";
				acompanhamento.atribuinte = usuario;
				acompanhamento.responsavel = usuario;
				ordemServico.acompanhamentos[ordemServico.acompanhamentos.length - 1].tipo = "CORRECAO";
				ordemServico.acompanhamentos.push(acompanhamento);
				ordemServico._etapaAlterada = false;
			}
		}
		setTrocarTipo(false);
		setTrocarFluxo(false);
		setTrocarEtapa(false);
		const acompanhamento = ordemServico.acompanhamentos[ordemServico.acompanhamentos.length - 1];
		if (acompanhamento) {
			ordemServico.etapa = acompanhamento.etapa;
			if (ordemServico.responsavel?._manual) {
				acompanhamento.atribuinte = usuario;
				acompanhamento.responsavel = ordemServico.responsavel;
			} else {
				ordemServico.responsavel = acompanhamento.responsavel;
			}
		}
		if (ordemServico.assinatura) {
			if (ordemServico.assinatura.conteudo?.conteudo?.length) {
				ordemServico.assinatura.id = (await arquivoService.salvar({...ordemServico.assinatura, postBack: true})).id;
			} else if (!ordemServico.assinatura.id) {
				ordemServico.assinatura = null;
			}
		}
		if (ordemServico.status !== "CANCELADA" && ordemServico.etapa.status && ordemServico._status !== "manual") {
			ordemServico.status = ordemServico.etapa.status;
		}
		if (ordemServico.status === "FECHADA" && !ordemServico.fim?.length) {
			ordemServico.fim = moment().format("YYYY-MM-DDTHH:mm:ss");
		}
		ordemServico._alterado = false;
		const itens = ordemServico.itens;
		return await toast.promise(
			ordemServicoService.salvar({...ordemServico, postBack: true}).then(async ordemServico => {
				if (ordemServico.tipo === "CORRETIVA") {
					const os = {
						id: ordemServico.id,
						status: ordemServico.status,
						numero: ordemServico.numero,
						notaFiscal: ordemServico.notaFiscal
					};
					ordemServico.itens[0] = await ordemServicoItemService.salvarDependencias({...itens[0], ordemServico: os, postBack: true});
				} else {
					for (const item of itens) {
						if (item._alterado) {
							await ordemServicoItemService.patch(`/${item.id}?property=status&value=${item.status}`);
						}
					}
					ordemServico.itens = itens;
				}
				await ordemServicoService.atualizarValoresOrdemServico(ordemServico.id);
				if (id !== ordemServico.id) {
					navigate(`/ordens-servico/${ordemServico.id}`);
				} else {
					setOrdemServico({...ordemServico});
				}
				setBlocked(false);
				return ordemServico;
			}),
			{
				"pending": `Salvando ordem de serviço. Aguarde...`,
				"success": `Ordem de serviço salva com sucesso!`,
				"error": {
					render(e) {
						return `Falha ao salvar ordem de serviço: ${e.data?.response?.data}`;
					}
				}
			}
		);
	}

	const acompanhamentoHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column style={{width: "3em"}}/>
				<Column style={{width: "3em"}}/>
				<Column style={{width: "3em"}}/>
				<Column style={{width: "13em"}} header="Início"/>
				<Column style={{width: "13em"}} header="Fim"/>
				<Column header="Etapa"/>
				<Column header="Encaminhado Por"/>
				<Column header="Responsável"/>
				<Column header="Observações"/>
			</Row>
		</ColumnGroup>
	);

	function registrarEvento() {
		setEvento({...eventoService.criar(), usuario: usuario.email, tipo: "REGISTRO_ACOMPANHAMENTO"});
	}

	function avancarEtapa() {
		const messages = ordemServicoService.validar(ordemServico);
		if (ordemServico.status === "FATURAMENTO" && !ordemServico.faturamentoData) {
			showDialog(<InformationDialog header="Informação" message="Para avançar do faturamento, você deve aprová-lo primeiro."/>);
			return;
		}
		if (!messages.isEmpty()) {
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
			setMessages({...messages});
		} else {
			setAcompanhamento(ordemServicoService.criarAcompanhamento());
		}
	}

	function handleVoltar() {
		if (ordemServico._alterado) {
			showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja descartar as alterações?" onYes={() => navigate(-1)}/>);
		} else {
			navigate(-1);
		}
	}

	async function salvarEvento(evento) {
		if (ordemServico.acompanhamentos?.length) {
			const acompanhamento = ordemServico.acompanhamentos[ordemServico.acompanhamentos.length - 1];
			evento = await eventoService.salvar({...evento, postBack: true});
			await ordemServicoService.salvarAcompanhamentoEvento({eventos: evento.id, acompanhamento: acompanhamento.id});
			acompanhamento.eventos.push(evento);
			setEvento(null);
		}
	}

	function headerTemplate(e) {
		let style = {};
		switch (e.index) {
			case 0:
				if (messages.responsavel || messages.cliente || messages.clienteFaturamento || messages.etapa) {
					style.color = "#F44336";
				}
				break;
			case 1:
				if (messages.itens?.some(mi => !isEmpty(mi))) {
					style.color = "#F44336";
				}
				break;
			case 2:
				break;
			case 3:
				break;
			default:
				break;
		}
		return (
			<div className={`${e?.className}`} aria-controls={`${e.ariaControls}`} onClick={e.onClick} onKeyDown={e.onKeyDown} style={style}>
				<i className={`${e?.leftIconElement?.props?.className}`}/>
				<div className={`${e?.titleClassName}`} >{e?.titleElement?.props?.children}</div>
			</div>
		);
	}

	function imprimirEtiquetaOs() {
		imprimirEtiquetaOsZebra(ordemServico);
	}

	function imprimirEtiquetaLaudo() {
		imprimirEtiquetaLaudoZebra(ordemServico);
	}

	const imprimirOSCapaCampo = () => {
		const query = [];
		query.push(`id=${ordemServico.id}`);
		setLoading(true);
		toast.promise(
			ordemServicoService.imprimirOSCapaCampo(query).then(data => {
				saveAs(new Blob([data], {type: "application/pdf"}), `OS-${ordemServico.numero}.pdf`);
			}).finally(() => setLoading(false)),
			{
				"pending": `Fazendo download do pdf. Aguarde...`,
				"success": `Download realizado com sucesso.`,
				"error": {
					render(e) {
						return `Falha ao realizar downlaod.`;
					}
				}
			}
		);
	}

	const imprimirOSCampoPreventiva = () => {
		const query = [];
		query.push(`id=${ordemServico.id}`);
		setLoading(true);
		toast.promise(
			ordemServicoService.imprimirOSCampoPreventiva(query).then(data => {
				saveAs(new Blob([data], {type: "application/pdf"}), `OS-${ordemServico.numero}.pdf`);
			}).finally(() => setLoading(false)),
			{
				"pending": `Fazendo download do pdf. Aguarde...`,
				"success": `Download realizado com sucesso.`,
				"error": {
					render(e) {
						return `Falha ao realizar downlaod.`;
					}
				}
			}
		);
		
	}

	if (acompanhamento) {
		return <EditarAcompanhamento ordemServico={ordemServico} acompanhamento={acompanhamento} setAcompanhamento={setAcompanhamento} onSalvar={salvarAcompanhamento}/>
	}

	if (evento) {
		return <EditarEvento evento={evento} setEvento={setEvento} onSalvar={salvarEvento} onVoltar={() => setEvento(null)}/>
	}

	if (laudo) {
		return <EditarLaudo ordemServico={ordemServico} setOrdemServico={setOrdemServico} laudo={laudo} setLaudo={setLaudo}/>
	}

	if (item) {
		return (
			<EditarOrdemServicoItem index={itemIndex}
			                        messages={messages}
			                        setMessages={setMessages}
			                        ordemServico={ordemServico}
			                        setOrdemServico={setOrdemServico}
			                        item={item}
			                        setItem={setItem}
			                        setLaudo={setLaudo}
			                        onSalvar={salvarItem}
			/>
		);
	}

	function headerRight() {
		if (["PREVENTIVA", "PROJETO"].includes(ordemServico.tipo)) {
			return <>{iconStatus[ordemServico.status]} <b>{labelStatus[ordemServico.status]}</b></>;
		} else {
			return (					
				<>
					<If condition={ordemServico.pedidoVendaNumero}>
						<b>{"Pedido Venda Retorno Nº " +  ordemServico.pedidoVendaNumero}</b><b className="mx-2 text-lg"> | </b>
					</If>
					<If condition={ordemServico.pedidoVendaFaturamentoNumero}>
						<b>{"Pedido Venda Serviço Nº " +  ordemServico.pedidoVendaFaturamentoNumero}</b><b className="mx-2 text-lg"> | </b>
					</If>
					<If condition={ordemServico.pedidoVendaFaturamentoProdutoNumero}>
						<b>{"Pedido Venda Produto Nº " +  ordemServico.pedidoVendaFaturamentoProdutoNumero}</b><b className="mx-2 text-lg"> | </b>						
					</If>
					<b>Nº OS Omega {ordemServico?.itens[0]?.numero}</b>
					<b className="mx-2 text-lg"> | </b>
					{iconStatus[ordemServico.status]}
					<b className="ml-1">{labelStatus[ordemServico.status]}</b>
				</>
			);
		}
	}

	function habilitarTrocaEtapa() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja trocar manualmente a etapa da ordem de serviço?" onYes={() => {
			setTrocarEtapa(true);
		}}/>);
	}

	function habilitarTrocaFluxo() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja trocar o fluxo de operação da ordem de serviço?" onYes={() => {
			setTrocarFluxo(true);
			setTrocarEtapa(true);
		}}/>);
	}

	function habilitarTrocarTipo() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja trocar o tipo da ordem de serviço?" onYes={() => {
			setTrocarTipo(true);
			setTrocarFluxo(true);
			setTrocarEtapa(true);
		}}/>);
	}

	const hasOrcamentoAberto = ordemServico.status === "FECHADA" || ordemServico.itens?.some(osi => osi.orcamentos?.some(osio => ["GERADO", "ENVIADO"].includes(osio.status)));

	return (
		<BlockUI blocked={blocked}>
			<FormTab
				podeSalvar={!!roles.OSSC}
				podeDeletar={false}
				descricao={`Ordem de Serviço${ordemServico.numero ? ` nº ${ordemServico.numero}` : ""}`}
				service={ordemServicoService}
				value={ordemServico}
				blocked={id !== "new" && !ordemServico.id}
				header_right={headerRight}
				botoes_adicionais={
					<>
						{ordemServico.id && !!roles.OSSR ? <Button disabled={loading} label={isLargeDevice ? "Etiqueta OS" : null} autowidth={!isLargeDevice.toString()} icon="fa-solid fa-barcode" onClick={imprimirEtiquetaOs}/> : null}
						{ordemServico.id && ordemServico.tipo === "CORRETIVA" && !!roles.OSSR ? <Button clear disabled={loading} label={isLargeDevice ? "Etiqueta Laudo" : null} autowidth={!isLargeDevice.toString()} secondary icon="fa-solid fa-barcode" onClick={imprimirEtiquetaLaudo}/> : null}
						{ordemServico.id && ordemServico.tipo === "PREVENTIVA" && !!roles.OSSR ? <Button clear disabled={loading} label={isLargeDevice ? "OS Campo Preventiva" : null} autowidth={!isLargeDevice.toString()} secondary icon="fa-solid fa-file-pdf" onClick={imprimirOSCampoPreventiva}/> : null}
						{ordemServico.id && ordemServico.tipo === "CORRETIVA" && !!roles.OSSR ? <Button clear disabled={loading} label={isLargeDevice ? "OS Capa Campo" : null} autowidth={!isLargeDevice.toString()} secondary icon="fa-solid fa-file-pdf" onClick={imprimirOSCapaCampo}/> : null}
						{ordemServico.id && !!roles.OSSR ? <i className="fa-solid fa-circle" style={{lineHeight: "36px", color: "rgba(0, 0, 0, .1)", margin: "0 1em"}}/> : null}
						{/*{ordemServico.id ? <Button disabled={loading} label={isLargeDevice ? "Laudo Interno" : null} autowidth={!isLargeDevice.toString()} secondary icon="fa-solid fa-file-pdf" onClick={imprimirLaudoInterno}/> : null}*/}
						{ordemServico.id ? <Button disabled={loading} label={isLargeDevice ? "Registrar Evento" : null} autowidth={!isLargeDevice.toString()} warning icon="fa-solid fa-file-lines" onClick={registrarEvento}/> : null}
						{ordemServico.id && ordemServico.operacao !== "LABORATORIO" ? <Button disabled={loading} label={isLargeDevice ? "Avançar Etapa" : null} autowidth={!isLargeDevice.toString()} icon="fa-solid fa-arrow-right" onClick={avancarEtapa}/> : null}
					</>
				}
				carregando={loading}
				onVoltar={handleVoltar}
				onSalvar={handleSalvar} 
			>
				<TabPanel headerTemplate={headerTemplate} header="Dados Principais" leftIcon="fa-solid fa-database">
					<PanelContent>
						<Dropdown disabled={!roles.OSSH || ordemServico.operacao === "LABORATORIO"} options={optionsStatus} col={2} name="status" label="Status" value={ordemServico.status} onChange={handleChange}/>
						<InputText readOnly col={1} name="numero" label="Número" value={ordemServico.numero}/>
						<Calendar showTime onChange={handleChange} readOnly={!roles.OSSI} col={2} name="inicio" label="Início" value={ordemServico.inicio}/>
						<Calendar showTime onChange={handleChange} readOnly={!roles.OSSI} col={2} name="fim" label="Fim" value={ordemServico.fim}/>
						<Calendar disabled={!roles.OSSW} onChange={handleChange} col={2} name="previsaoAtendimento" label="Previsão Atendimento" value={ordemServico.previsaoAtendimento}/>
						<OrdemServicoTipoDropdown labelRight={!!roles.OSST ? <span style={{color: "#2196F3", cursor: "pointer"}} onClick={habilitarTrocarTipo}>Trocar</span> : null} label="Tipo" required onBlur={() => {
							messages.tipo = isRequired(ordemServico.tipo);
							setMessages({...messages});
						}} disabled={ordemServico.id && !trocarTipo} name="tipo" invalid={messages.tipo} col={3} value={ordemServico.tipo} onChange={handleChange}/>
						{
							ordemServico.id && !trocarFluxo
							? <InputText labelRight={!!roles.OSSE && !trocarFluxo ? <span style={{color: "#2196F3", cursor: "pointer"}} onClick={habilitarTrocaFluxo}>Trocar</span> : null} col={4} readOnly label="Fluxo de Operação" name="fluxo" value={ordemServico.fluxo}/>
							: <OrdemServicoFluxoDropdown fluxos={fluxos} required invalid={messages._fluxo} onBlur={() => {
								messages._fluxo = isEntityRequired(ordemServico._fluxo);
								setMessages({...messages});
							}} disabled={!roles.OSSE} dataKey="id" col={4} label="Fluxo de Operação" tipo={ordemServico.tipo} name="_fluxo" value={ordemServico._fluxo} onChange={handleChange}/>
						}
						<Dropdown dataKey="id" required labelRight={!!roles.OSSF && !trocarEtapa && ordemServico.id ? <span style={{color: "#2196F3", cursor: "pointer"}} onClick={habilitarTrocaEtapa}>Trocar</span> : null} invalid={messages.etapa} onBlur={() => {
							messages.etapa = isNotNull(ordemServico.etapa);
							setMessages({...messages});
						}} disabled={(ordemServico.id || !roles.OSSF) && !trocarEtapa} col={2} label="Etapa" name="etapa" value={ordemServico.etapa} options={etapas} onChange={handleChange}/>
						<UsuarioAutoComplete forceSelection required invalid={messages.responsavel} onBlur={() => {
							messages.responsavel = isEntityRequired(ordemServico.responsavel);
							setMessages({...messages});
						}} name="responsavel" label="Responsável" readOnly={!roles.OSSG} col={6} value={ordemServico.responsavel} onChange={handleChange}/>
						<ClienteAutoComplete readOnly={ordemServico.id || ordemServico.itens?.some(osi => osi.pecas?.length || osi.servicos?.length)} required onBlur={() => {
							messages.cliente = isEntityRequired(ordemServico.cliente);
							setMessages({...messages});
						}} invalid={messages.cliente} col={4} value={ordemServico.cliente} name="cliente" onChange={handleChange}/>
						<ClienteContratoDropdown autoSelect equipamento={ordemServico.tipo === "CORRETIVA" ? ordemServico.itens[0].equipamento : null} tipo={ordemServico.tipo} col={ordemServico.contrato?.valorFranquia ? 3 : 5} className="clienteContrato" disabled={hasOrcamentoAberto} cliente={ordemServico.cliente} value={ordemServico.contrato} onChange={handleChange}/>
						{hasOrcamentoAberto ? <Tooltip position="bottom" target=".clienteContrato" content="Não é possível trocar o contrato com orçamentos em aberto"/> : null}
						<If condition={ordemServico.tipo === "PROJETO"}>
							<InputText readOnly value={ordemServico.contrato?.objeto} col={12} label="Descrição do Projeto"/>
							<InputText style={{height: "10em"}} readOnly value={ordemServico.contrato?.observacoes} col={12} multiline label="Detalhamento do Projeto"/>
						</If>
						{ordemServico.contrato?.valorFranquia ? <Calendar disabled={ordemServico.itens?.some(osi => osi.orcamentos?.some(osio => osio.status === "APROVADO"))} invalid={messages.dataConsumoFranquia} col={2} name="dataConsumoFranquia" value={ordemServico.dataConsumoFranquia} label="Data de Consumo da Franquia" onChange={handleChange}/> : null}
						<CondicaoPagamentoAutoComplete dropdown col={3} value={ordemServico?.condicaoPagamento} name="condicaoPagamento" onChange={handleChange} invalid={messages.condicaoPagamento} onBlur={() => {
							messages.condicaoPagamento = isEntityRequired(ordemServico.condicaoPagamento);
							setMessages({...messages});
						}}/>
						<InputMask readOnly col={2} name="cep" label="CEP"  mask='99999-999' value={ordemServico.cliente?.vwCliente?.cep} />						
						<InputText readOnly col={5} label="Endereço" value={ordemServico.cliente?.vwCliente?.endereco}/>												
						<InputText readOnly col={2} label="Município" value={ordemServico.cliente?.vwCliente?.municipio}/>
						<InputText readOnly col={1} label="UF" value={ordemServico.cliente?.vwCliente?.uf}/>
						<InputText readOnly col={2} label="Contato" value={ordemServico.cliente?.contato}/>
						<ClienteAutoComplete required onBlur={() => {
		                     messages.clienteFaturamento = isEntityRequired(ordemServico.clienteFaturamento);
		                     setMessages({...messages});
	                     }} invalid={messages.clienteFaturamento} col={4} value={ordemServico.clienteFaturamento} name="clienteFaturamento" onChange={handleChange} label="Cliente para Faturamento"/>
						<InputText col={2} name="numeroChamado" label="Nº do Chamado" value={ordemServico.numeroChamado} onChange={handleChange}/>
						<InputText col={2} name="numeroPedidoCliente" label="Nº do Pedido do Cliente" value={ordemServico.numeroPedidoCliente} onChange={handleChange}/>
						<InputText col={2} name="numeroPedidoCompra" label="Nº do Pedido de Compra" value={ordemServico.numeroPedidoCompra} onChange={handleChange}/>
						<InputMask unmask mask="(99) 99999-999?9" col={2} name="solicitanteContato" label="Contato do Solicitante" value={ordemServico.solicitanteContato} onChange={handleChange}/>
						<InputText 
							col={3} 
							name="emailContato" 
							label="Email do Contato" 
							value={ordemServico.emailContato} 
							onChange={handleChange}
							invalid={messages.emailContato}
							onBlur={() => {
								if(ordemServico?.emailContato?.length > 0) {
									messages.emailContato = isRequiredEmail(ordemServico.emailContato);
									setMessages({...messages});
								}else{
									messages.emailContato = null;
									setMessages({...messages});
								}
							}}
						/>
						<InputText col={3} name="solicitanteNome" label="Nome do Solicitante" value={ordemServico.solicitanteNome} onChange={handleChange}/>
						<InputText col={4} name="responsavelInstrumentos" label="Responsável pelos Equipamentos" value={ordemServico.responsavelInstrumentos} onChange={handleChange}/>
						<InputText readOnly col={2} label="SLA" value={ordemServico.contrato?.acordoNivelServico?.descricao}/>
						<InputTextarea
							style={{height: "10em"}} 
							col={12} 
							name="observacao" 
							label="Detalhes do Serviço" 
							value={ordemServico.observacao} 
							onChange={handleChange}					
						/>
						{roles.OSSI ? <Checkbox col={2} label="Não Gerar Retorno" name="naoGerarRetorno" value={ordemServico.naoGerarRetorno} onChange={handleChange}/>:  null}
					</PanelContent>
				</TabPanel>
				<TabPanel headerTemplate={headerTemplate} disabled={!ordemServico.cliente?.id || !ordemServico.tipo?.length} header="Detalhes do Serviço" leftIcon="fa-solid fa-wrench">
					{["PREVENTIVA", "PROJETO"].includes(ordemServico.tipo) ? renderCamposPreventiva() : renderCamposCorretiva()}
				</TabPanel>
				<TabPanel headerTemplate={headerTemplate} disabled={!ordemServico.id || !ordemServico.contrato?.acordoNivelServico} header="Prazos do SLA" leftIcon="fa-solid fa-signature">
					<SLA ordemServico={ordemServico}/>
				</TabPanel>
				<TabPanel headerTemplate={headerTemplate} header="Histórico" leftIcon="fa-solid fa-clock">
					<Acompanhamento ordemServico={ordemServico}/>
				</TabPanel>
				<TabPanel headerTemplate={headerTemplate} header="Movimentação de Estoque" leftIcon="fa-solid fa-cubes">
					<VisualizarMovimentosEstoque movimentosEstoque={ordemServico.movimentosEstoque}/>
				</TabPanel>
				<TabPanel disabled={!ordemServico.id} headerTemplate={headerTemplate} header="Laudos" leftIcon="fa-solid fa-diagnoses">
					<EditarOrdemServicoLaudo laudos={ordemServico.laudos} setLaudo={setLaudo} ordemServico={ordemServico} handleChange={handleChange}/>
				</TabPanel>
				<TabPanel disabled={
							!ordemServico.cliente?.id
							|| ordemServico.tipo !== "CORRETIVA"
							|| ordemServico.operacao !== "LABORATORIO"
							|| !ordemServico.itens?.[0]?.equipamento?.id
				} headerTemplate={headerTemplate} header="Nota Fiscal de Entrada" leftIcon="fa-solid fa-receipt">
					<EditarOrdemServicoNotaFiscal ordemServico={ordemServico} setOrdemServico={setOrdemServico} messages={messages} setMessages={setMessages}/>
				</TabPanel>
			</FormTab>
		</BlockUI>
	);

}
