import React, {useCallback} from "react";
import {AutoComplete as PAutoComplete} from "primereact/autocomplete";
import classNames from "classnames";
import {calcularTamanho} from "../../service/guiHelper";
import {If} from "../conditional/If";

export function AutoComplete(props) {

    function handleChange(event) {
        if (event.originalEvent?.type === "blur") return;
        if (props.onChange) {
            props.onChange({name: props.name, value: event.value, index: props.index});
        }
    }

    function handleBlur(event) {
        if (props.onBlur) {
            props.onBlur(event);
        }
        if (typeof(props.value) === "string" && props.forceSelection !== false) {
            props.onChange({name: props.name, value: null, index: props.index});
        }
    }

    function handleClear(event) {        
        if (props.onClear) {
            props.onClear({name: props.name, value: event.value, index: props.index});
        }
    }

    const className = calcularTamanho(props.col);

    const inputClasses = classNames({
        "p-invalid": props.invalid,
        "autocomplete-dropdown": props.autocomplete_dropdown
    });

    const itemTemplate = useCallback(item => {
        return (
            <div className="flex align-items-center" style={{gap: "0.5rem"}}>
                <i className={props.icon || "pi pi-question-mark"}/>
                <div>{item.autoComplete}</div>
            </div>
        );
    }, [props.icon]);

    return (
        <div className={className} style={{position: "relative"}}>
            <label>{props.label}</label>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                    <i className="fa-solid fa-magnifying-glass"/>
                </span>
                <PAutoComplete
                    field="autoComplete"
                    itemTemplate={itemTemplate}
                    {...props}
                    placeholder={`Pesquise por: ${props.placeholder}`}
                    className={inputClasses}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onClear={handleClear}
                    forceSelection={props.forceSelection != null ? props.forceSelection : true}
                    minLength={3}
                    emptyMessage={"Nenhum registro encontrado"}
                />
            </div>
            {props.reduced ? null : (props.invalid ? <small id="username2-help" className="p-error block">{props.invalid}</small> : props.required ? <small style={{color: props.value ? "transparent" : "#888"}} className="block">{props.multiple ? "Você precisa selecionar ao menos uma opção" : "Campo obrigatório"}</small> : <small>&nbsp;</small>)}
            <If condition={props.loading}>
                <i className="fas fa-spinner fa-spin" style={{float: "right", position: "absolute", top: "2.25em", right: "1.5em"}}/>
            </If>
        </div>
    );

}
