import React from "react";
import {api} from "./api";

export const backofficeEstoqueService = {

    async listar(params) {
        const query = [];
        if (params.status?.length) query.push(`status=${params.status}`);
        if (params.numero) query.push(`numero=${params.numero}`);
        if (params.cliente?.id) query.push(`cliente=${params.cliente.id}`);
        if (params.cliente?.length) query.push(`clienteStr=${params.cliente}`);
        if (params.produto?.id) query.push(`produto=${params.produto.id}`);
        if (params.disponiveis) query.push(`disponiveis`);
        return await api.get(`/backoffice/estoque?${query.join("&")}`).then(res => res.data);
    }

};

export const filterStatusPeca = [
    {label: <><i className="fas fa-asterisk" style={{color: "#000000"}}/> Todos</>, value: null},
    {label: <><i className="fas fa-file" style={{color: "#2196F3"}}/> Solicitado</>, value: "SOLICITADO"},
    {label: <><i className="fas fa-shopping-cart" style={{color: "#673AB7"}}/> Compras</>, value: "COMPRAS"},
    {label: <><i className="fas fa-check-circle" style={{color: "#4CAF50"}}/> Entregue</>, value: "MOVIMENTADO"},
    {label: <><i className="fas fa-cancel" style={{color: "#F44336"}}/> Cancelado</>, value: "CANCELADO"}
];
