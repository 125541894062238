import React, {useEffect, useState} from "react";
import {Dropdown} from "./index";
import {ordemServicoFluxoService} from "../../service/ordemServicoFluxoService";

export function OrdemServicoFluxoDropdown(props) {

	const [fluxos, setFluxos] = useState([]);

	useEffect(() => {
		if (props.fluxos) {
			setFluxos([...props.fluxos.map(f => ({label: f.descricao, value: f}))]);
		} else {
			ordemServicoFluxoService.listar(["query="]).then(fluxos => {
				setFluxos(fluxos.map(f => ({label: f.descricao, value: f})));
			});
		}
	}, [props.fluxos]);

	useEffect(() => {
		switch (props.tipo) {
			case "CORRETIVA":
				if (props.value?.flags.some(f => ["PADRAO_CORRETIVA", "CORRETIVA"].includes(f))) {
					return;
				}
				const ic = fluxos.findIndex(fc => fc.value.flags.includes("PADRAO_CORRETIVA"));
				if (ic > -1) {
					props.onChange({automatico: true, name: props.name, index: props.index, value: fluxos[ic].value});
				} else {
					props.onChange({automatico: true, name: props.name, index: props.index, value: null});
				}
				break;
			case "PREVENTIVA":
				if (props.value?.flags.some(f => ["PADRAO_PREVENTIVA", "PREVENTIVA"].includes(f))) {
					return;
				}
				const ip = fluxos.findIndex(fp => fp.value.flags.includes("PADRAO_PREVENTIVA"));
				if (ip > -1) {
					props.onChange({automatico: true, name: props.name, index: props.index, value: fluxos[ip].value});
				} else {
					props.onChange({automatico: true, name: props.name, index: props.index, value: null});
				}
				break;
			case "PROJETO":
				if (props.value?.flags.some(f => ["PADRAO_PROJETO", "PROJETO"].includes(f))) {
					return;
				}
				const ix = fluxos.findIndex(fp => fp.value.flags.includes("PADRAO_PROJETO"));
				if (ix > -1) {
					props.onChange({automatico: true, name: props.name, index: props.index, value: fluxos[ix].value});
				} else {
					props.onChange({automatico: true, name: props.name, index: props.index, value: null});
				}
				break;
			default:
				break;
		}
	}, [props.tipo, fluxos]);

	return (
		<Dropdown label="Fluxo" options={fluxos.filter(f => f.value.flags.some(ff => ff.includes(props.tipo)))} {...props}/>
	);

}
