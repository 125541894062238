import {baseService} from "./baseService";
import {buildValidator, isArrayNotEmpty, isEntityRequired, isRequired, isRequiredEmail, isRequiredNome} from "../utils/fieldValidator";
import { api } from "./api";

const resouceUrl = "/usuarios";

export const usuarioService = {
    criar: () => {
        return {
            id: null,
            status: "ATIVO",
            nome: "",
            rg: null,
            cpf: null,
            senha: "",
            senha2: "",
            email: "",
            empresa: null,
            perfis: [],
            clientes: [],
        };
    },
    validar: (usuario) => {
        const messages = buildValidator();
        messages.nome = isRequiredNome(usuario.nome);
        messages.email = isRequiredEmail(usuario.email);
        if (!usuario.id) {
            messages.senha = isRequired(usuario.senha);
            messages.senha2 = isRequired(usuario.senha2);
        }
        if (usuario.senha?.length && usuario.senha !== usuario.senha2) {
            messages.senha2 = "As senhas não conferem";
        }
        messages.perfis = isArrayNotEmpty(usuario.perfis);
        messages.empresa = isEntityRequired(usuario.empresa)
        return messages;
    },
    atualizarBancada: async (idUsuario, idPrateleira) => {
        return await api.post(resouceUrl + "/atualizarBancada", {idUsuario, idPrateleira}).then(response => response.data);
    },
    ...baseService(resouceUrl),
    async listarResponsaveis(query) {
        return await api.get(`${resouceUrl}/responsaveis?textoDeBusca=${query}`).then(response => response.data);
    },
    async listarDummy(params) {
        return await api.get(resouceUrl + "/dummy?" + params?.join("&")).then(response => response.data);
    },
    async limparBancada(id) {
        return await api.get(resouceUrl + "/limpar-bancada?id=" + id);
    },
    async listarUsuarios(params) {
        const query = [];
        if (params.nome?.length) query.push(`nome=lk='${params.nome}'`);
        if (params.email?.length) query.push(`email=lk='${params.email}'`);
        if (params.perfil?.length) query.push(`perfis.descricao=lk='${params.perfil}'`);
        if (!params.visualizarDesativados) {
            query.push("status==ATIVO");
        }
        return await usuarioService.listar([`query=${query.join(";")}`]);
    }
};
