import React, {useEffect, useRef, useState} from 'react';

import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';
import {toast, ToastContainer} from 'react-toastify';
import {Tooltip} from 'primereact/tooltip';
import PrimeReact from 'primereact/api';
import classNames from 'classnames';

import {AppFooter} from './AppFooter';
import {DialogProvider} from "./utils/dialogContext";

import {useAppConfiguracoes} from './context/AppConfiguracoesContext';
import {useAuth} from './context/AuthContext';
import {api} from './service/api';

import {EstoqueMovimentoTransferencia} from "./pages/EstoqueMovimento/EstoqueMovimentoTransferencia";
import {EditarEstoqueMovimento} from './pages/EstoqueMovimento/EditarEstoqueMovimento';
import {OrdemServicoNotaFiscal} from "./pages/OrdensServico/OrdemServicoNotaFiscal";
import {OrdemServicoEstoqueMovimento} from "./pages/OrdemServicoEstoqueMovimento";
import {EditarPedidoVenda} from './pages/Comercial/PedidoVenda/EditarPedidoVenda';
import {EditaClausulasTipos} from './pages/ClausulasTipos/EditarClausulasTipos';
import {EditarListaPreco} from './pages/Comercial/ListaPreco/EditarListaPreco';
import {EditarOrdemServico} from "./pages/OrdensServico/EditarOrdemServico";
import {EditarProdutoMarca} from './pages/ProdutoMarcas/EditarProdutoMarca';
import {EditarProdutoTipo} from "./pages/ProdutoTipos/EditarProdutoTipo";
import {EditarEquipamento} from "./pages/Equipamento/EditarEquipamento";
import {EditarContaEmail} from './pages/ContasEmail/EditarContaEmail';
import {OrcamentoParaCliente} from './pages/OrcamentoParaCliente';
import {EditarFluxo} from "./pages/OrdemServicoFluxo/EditarFluxo";
import {EditarContrato} from './pages/Contratos/EditarContratos';
import {EditarSeloIpem} from "./pages/SelosIpem/EditarSeloIpem";
import {EditarANS} from "./pages/AcordoNivelServico/EditarANS";
import {EditarEstoques} from './pages/Estoque/EditarEstoques';
import {EditarEmpresa} from './pages/Empresas/EditarEmpresa';
import {EditarUsuario} from "./pages/Usuarios/EditarUsuario";
import {EditarCliente} from './pages/Clientes/EditarCliente';
import {EditarProduto} from './pages/Produto/EditarProduto';
import {EstoqueMovimentos} from './pages/EstoqueMovimento';
import {PedidoVenda} from './pages/Comercial/PedidoVenda';
import {EditarPerfil} from "./pages/Perfis/EditarPerfil";
import {ListaPreco} from './pages/Comercial/ListaPreco';
import {ClausulasTipos} from './pages/ClausulasTipos';
import {Produtividade} from './pages/Produtividade';
import {ProdutoMarcas} from './pages/ProdutoMarcas';
import {OrdensServico} from "./pages/OrdensServico";
import {ProdutoTipos} from "./pages/ProdutoTipos";
import {AcessoNegado} from './pages/AcessoNegado';
import {Fluxos} from "./pages/OrdemServicoFluxo";
import {Equipamento} from "./pages/Equipamento";
import {ErroConexao} from './pages/ErroConexao';
import {ContasEmail} from './pages/ContasEmail';
import {ANS} from "./pages/AcordoNivelServico";
import {SelosIpem} from "./pages/SelosIpem";
import {Contratos} from './pages/Contratos';
import {Historico} from './pages/Historico';
import {Usuarios} from "./pages/Usuarios";
import {Clientes} from './pages/Clientes';
import {Empresas} from './pages/Empresas';
import {Coletor} from "./pages/Coletor";
import {Estoque} from './pages/Estoque';
import {Produto} from './pages/Produto';
import {Perfis} from "./pages/Perfis";
import {Login} from "./pages/Login";

import {RequireRole} from './routes/Requisitos/RequireRole';
import {RequireAuth} from './routes/Requisitos/RequireAuth';
import {Template} from './routes/Template';

import {ConfirmDialog} from './components/confirmdialog';

import 'react-toastify/dist/ReactToastify.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import './assets/fontawesome-free-6.1.2-web/css/all.css';
import './assets/fontawesome-free-6.1.2-web/css/fontawesome.css';
import './assets/fontawesome-free-6.1.2-web/css/solid.css';
import './assets/layout/layout.scss';
import './App.scss';
import {EditarOrcamentoPedidoVenda} from './pages/Comercial/PedidoVenda/Orcamento/EditarOrcamentoPedidoVenda';
import {Feedback} from './pages/OrcamentoParaCliente/Feedback';
import {Expired} from './pages/OrcamentoParaCliente/Expired';
import {Icms} from './pages/Comercial/ICMS';
import {EditarIcms} from './pages/Comercial/ICMS/EditarIcms';
import {EditarOrdemServicoMovimentoEstoque} from "./pages/OrdemServicoEstoqueMovimento/EditarOrdemServicoMovimentoEstoque";
import {Compras} from "./pages/Compras";
import {Relatorios} from './pages/Relatorios';
import {PrepararFaturamento} from "./pages/faturamento/PrepararFaturamento";
import {EditarFaturamento} from "./pages/faturamento/EditarFaturamento";
import {Faturamento} from "./pages/faturamento";
import {RelatorioOrcamento} from './pages/RelatorioOrcamento';
import {RelatorioOrdemServicoPeca} from './pages/RelatorioOrdemServicoPeca';
import {BackOfficeRecepcao} from "./pages/BackOffice/Recepcao";
import {BackOfficeRecepcaoEditar} from "./pages/BackOffice/Recepcao/Editar";
import {menuGroups, menuItems} from "./utils/menu";
import {Prateleiras} from "./pages/Prateleiras";
import {EditarPrateleira} from "./pages/Prateleiras/EditarPrateleira";
import {BackOfficeRecepcaoNotaFiscal} from "./pages/BackOffice/Recepcao/RecepcaoNotaFiscal";
import {LaboratorioLaudo} from "./pages/Laboratorio/Laudo";
import {LaboratorioLaudoEditar} from "./pages/Laboratorio/Laudo/Editar";
import {LaboratorioManutencaoEditar} from "./pages/Laboratorio/Manutencao/Editar";
import {LaboratorioLimpezaEditar} from "./pages/Laboratorio/Limpeza/Editar";
import {LaboratorioTesteEditar} from "./pages/Laboratorio/Teste/Editar";
import {BackOfficeExpedicaoEditar} from "./pages/BackOffice/Expedicao/Editar";
import {BackOfficeEstoque} from "./pages/BackOffice/Estoque";
import {BackOfficeEstoqueEditar} from "./pages/BackOffice/Estoque/Editar";
import {RelatoriosLaboratorio} from "./pages/Laboratorio/Relatorios";
import {Calendarios} from "./pages/Calendarios";
import {EditarCalendario} from "./pages/Calendarios/Edit";
import {EstoqueRecondicionado} from "./pages/BackOffice/Estoque/EstoqueRecondicionado";
import {EditarEstoqueRecondicionado} from "./pages/BackOffice/Estoque/EditarEstoqueRecondicionado";
import {FaixasANS} from "./pages/AcordoNivelServico/FaixasANS";
import {RelatorioOrdemServicoCliente} from './pages/RelatorioOrdemServicoCliente';
import {BacklogOrcamento} from './pages/BacklogOrcamento';
import {Acompanhamento} from './pages/Comercial/Comissao/Acompanhamento';
import {TipoComissao} from './pages/Comercial/Comissao/TipoComissao/index';
import {EditarTipoComissao} from './pages/Comercial/Comissao/TipoComissao/EditarTipoComissao';
import {Fechamento} from './pages/Comercial/Comissao/Fechamento';
import {RelatorioOrdemServicoEtapa} from './pages/RelatorioOrdemServicoEtapa';
import {BacklogSLA} from './pages/BacklogSLA';
import {AcompanhamentoIndividual} from './pages/Comercial/Comissao/AcompanhamentoIndividual';
import {CampoOrdensServico} from "./pages/Campo";
import {EditarOrdemServicoCampo} from "./pages/Campo/EditarOrdemServico";
import {CampoAgendamento} from "./pages/Campo/Agendas";
import {CalendarioProvider} from "./utils/CalendarioContext";
import {Projetos} from "./pages/Campo/Projetos";
import {EditarProjeto} from "./pages/Campo/Projetos/EditarProjeto";
import {RelatorioAgenda} from './pages/Campo/RelatorioAgenda';
import {EditarFechamento} from './pages/Comercial/Comissao/Fechamento/EditarFechamento';
import {QueryParamsProvider} from "./utils/QueryParamContext";
import {PageAndTabProvider} from "./utils/PageAndTabContext";
import { AtualizacaoEmLote } from './pages/Comercial/ListaPreco/AtualizacaoEmLote';
import { RelatorioOperacoes } from './pages/RelatorioOperacoes';
import { RelatorioOrdemServicoRestricao } from './pages/RelatorioOrdemServicoRestricao';
import {RelatorioKPILaboratorio} from "./pages/Laboratorio/Relatorios/KPI";

const App = () => {

	const [staticMenuInactive, setStaticMenuInactive] = useState(false);
	const [mobileMenuActive, setMobileMenuActive] = useState(false);
	const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
	const [modalDeConfirmacao, setModalDeConfirmacao] = useState(false);
	const [dialogs, setDialogs] = useState([]);
	const copyTooltipRef = useRef();
	const location = useLocation();
	const {signOut, token, roles, carregando} = useAuth();
	const {layoutColorMode} = useAppConfiguracoes();
	PrimeReact.ripple = true;
	const navigate = useNavigate();
	let menuClick = false;
	let mobileTopbarMenuClick = false;

	useEffect(() => {
		if (mobileMenuActive) {
			addClass(document.body, "body-overflow-hidden");
		} else {
			removeClass(document.body, "body-overflow-hidden");
		}
	}, [mobileMenuActive]);

	useEffect(() => {
		copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
	}, [location]);

	const onWrapperClick = () => {
		if (!menuClick) {
			setMobileMenuActive(false);
		}
		if (!mobileTopbarMenuClick) {
			setMobileTopbarMenuActive(false);
		}
		mobileTopbarMenuClick = false;
		menuClick = false;
	}

	const onToggleMenuClick = (event) => {
		menuClick = true;
		if (isDesktop()) {
			setStaticMenuInactive((prevState) => !prevState);
		} else {
			setMobileMenuActive((prevState) => !prevState);
		}
		event.preventDefault();
	}

	const onSidebarClick = () => {
		menuClick = true;
	}

	const onMobileTopbarMenuClick = (event) => {
		mobileTopbarMenuClick = true;

		setMobileTopbarMenuActive((prevState) => !prevState);
		event.preventDefault();
	}

	const onMobileSubTopbarMenuClick = (event) => {
		mobileTopbarMenuClick = true;
		event.preventDefault();
		setModalDeConfirmacao(true)
	}

	const sairDaAplicacao = () => {
		toast(`Saindo da aplicação`, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			progressStyle: {}

		});
		signOut()
		navigate('/')
	}

	const onMenuItemClick = (event) => {
		if (!event.item.items) {
			setMobileMenuActive(false);
		}
	}

	const isDesktop = () => {
		return window.innerWidth >= 992;
	}

	const [items, setItems] = useState([]);
	const enabled = roles && Object.keys(roles);

	function buildMenu(items) {
		const menu = [];
		for (const item of items) {
			if (!item.role || enabled.includes(item.role)) {
				if (item.items?.length) {
					item.items = buildMenu(item.items);
				}
				menu.push(item);
			}
		}
		return menu;
	}

	useEffect(() => {
		if (!roles || carregando) return;
		let menus = [...menuGroups];
		for (const menu of menus) {
			menu.items = buildMenu(menuItems.filter(mi => mi.parent === menu.key));
		}
		menus.push(...menuItems.filter(mi => !mi.parent));
		menus = menus.filter(mg => mg.to || mg.items?.length);
		setItems(menus);
	}, [carregando, roles]);

	const menu = [
		{items: items},
	];

	const addClass = (element, className) => {
		if (element.classList)
			element.classList.add(className);
		else
			element.className += ' ' + className;
	}

	const removeClass = (element, className) => {
		if (element.classList)
			element.classList.remove(className);
		else
			element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
	}

	const wrapperClass = classNames('layout-wrapper', {
		'layout-static': true,
		'layout-static-sidebar-inactive': staticMenuInactive,
		'layout-mobile-sidebar-active': mobileMenuActive,
		'layout-theme-light': layoutColorMode === 'light'
	});

	api.interceptors.response.use(
		response => response,
		error => {
			if (!error.response?.status && !error.status) {
				navigate('/networkError')
			} else {
				return error
			}
		}
	);

	function showDialog(dialog) {
		setDialogs(prevDialogs => [...prevDialogs, dialog]);
	}

	return (
		<div className={wrapperClass} onClick={onWrapperClick}>
			<CalendarioProvider>
				<PageAndTabProvider>
					<QueryParamsProvider>
						<DialogProvider value={{dialogs, showDialog}}>
							<Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus"/>
							<div className="layout-main-container">
								<div className="layout-main">
									<Routes>
										<Route path="/" element={<Login/>}/>
										<Route path="/networkError" element={<ErroConexao setStaticMenuInactive={setStaticMenuInactive}/>}/>
										<Route path="/acessoNegado" element={<AcessoNegado setStaticMenuInactive={setStaticMenuInactive}/>}/>
										<Route path="/public/orcamentosCliente/:id/:idDestinatario" element={<OrcamentoParaCliente setStaticMenuInactive={setStaticMenuInactive}/>}/>
										<Route path="/public/orcamentosCliente/feedback" element={<Feedback setStaticMenuInactive={setStaticMenuInactive}/>}/>
										<Route path="/public/orcamentosCliente/expired" element={<Expired setStaticMenuInactive={setStaticMenuInactive}/>}/>
										<Route path="/coletor" element={<Coletor/>}/>
										<Route element={<RequireAuth/>}>
											<Route
												element={
													<Template
														onToggleMenuClick={onToggleMenuClick}
														layoutColorMode={layoutColorMode}
														mobileTopbarMenuActive={mobileTopbarMenuActive}
														onMobileTopbarMenuClick={onMobileTopbarMenuClick}
														onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
														onSidebarClick={onSidebarClick}
														menu={menu}
														onMenuItemClick={onMenuItemClick}
													/>
												}
											>
												<Route element={<RequireRole roleNavigation="OSSB" roleCreate="OSSA" roleEdit="OSSC"/>}>
													<Route path="/ordens-servico" element={<OrdensServico/>}/>
													<Route path="/ordens-servico/:id" element={<EditarOrdemServico/>}/>
													<Route path="/ordens-servico/estoques/movimentos" element={<OrdemServicoEstoqueMovimento/>}/>
													<Route path="/ordens-servico/notas-fiscais" element={<OrdemServicoNotaFiscal/>}/>
													<Route path="/ordens-servico/historico/:id" element={<Historico path={"/ordens-servico"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="OSEB" roleCreate="OSEA" roleEdit="OSEC"/>}>
													<Route path="/ordens-servico/fluxos" element={<Fluxos/>}/>
													<Route path="/ordens-servico/fluxos/:id" element={<EditarFluxo/>}/>
													<Route path="/ordens-servico/fluxos/historico/:id" element={<Historico path={"/ordens-servico/fluxos"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="ANSB" roleCreate="ANSA" roleEdit="ANSC"/>}>
													<Route path="/sla/modelos" element={<ANS/>}/>
													<Route path="/sla/modelos/:id" element={<EditarANS/>}/>
													<Route path="/sla/modelos/historico/:id" element={<Historico path={"/acordos-nivel-servico"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="PROB" roleCreate="PROA" roleEdit="PROC"/>}>
													<Route path="/produtos" element={<Produto/>}/>
													<Route path="/produtos/:id" element={<EditarProduto/>}/>
													<Route path="/produtos/historico/:id" element={<Historico path={"/produtos"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="MARB" roleCreate="MARA" roleEdit="MARC"/>}>
													<Route path="/produtos/marcas" element={<ProdutoMarcas/>}/>
													<Route path="/produtos/marcas/:id" element={<EditarProdutoMarca/>}/>
													<Route path="/produtos/marcas/historico/:id" element={<Historico path={"/produtos/marcas"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="PRTB" roleCreate="PRTA" roleEdit="PRTC"/>}>
													<Route path="/produtos/tipos" element={<ProdutoTipos/>}/>
													<Route path="/produtos/tipos/:id" element={<EditarProdutoTipo/>}/>
													<Route path="/produtos/tipos/historico/:id" element={<Historico path={"/produtos/tipos"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation='CLIB' roleCreate='CLIA' roleEdit='CLIC'/>}>
													<Route path='/clientes' element={<Clientes/>}/>
													<Route path='/clientes/:id' element={<EditarCliente/>}/>
													<Route path='/clientes/historico/:id' element={<Historico path={"/clientes"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="EMPB" roleCreate="EMPA" roleEdit="EMPC"/>}>
													<Route path="/empresas" element={<Empresas/>}/>
													<Route path="/empresas/:id" element={<EditarEmpresa/>}/>
													<Route path="/empresas/historico/:id" element={<Historico path={"/empresas"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="PRFB" roleCreate="PRFA" roleEdit="PRFC"/>}>
													<Route path="/perfis" element={<Perfis/>}/>
													<Route path="/perfis/:id" element={<EditarPerfil/>}/>
													<Route path="/perfis/historico/:id" element={<Historico path={"/perfis"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="USRB" roleCreate="USRA" roleEdit="USRC"/>}>
													<Route path="/usuarios" element={<Usuarios/>}/>
													<Route path="/usuarios/:id" element={<EditarUsuario/>}/>
													<Route path="/usuarios/historico/:id" element={<Historico path={"/usuarios"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="CONB" roleCreate="CONA" roleEdit="CONC"/>}>
													<Route path="/contratos" element={<Contratos/>}/>
													<Route path="/contratos/:id" element={<EditarContrato/>}/>
													<Route path="/contratos/historico/:id" element={<Historico path={"/contratos"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="EQPB" roleCreate="EQPA" roleEdit="EQPC"/>}>
													<Route path="/equipamentos" element={<Equipamento/>}/>
													<Route path="/equipamentos/:id" element={<EditarEquipamento/>}/>
													<Route path="/equipamentos/historico/:id" element={<Historico path={"/equipamentos"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="PDVB" roleCreate="PDVA" roleEdit="PDVC"/>}>
													<Route path="/pedidos-venda" element={<PedidoVenda/>}/>
													<Route path="/pedidos-venda/:sistema/:id" element={<EditarPedidoVenda/>}/>
													<Route path="/pedidos-venda/:sistema/historico/:id" element={<Historico path={"/pedidos-venda"}/>}/>
													<Route path="/pedidos-venda/:sistema/orcamento/:id" element={<EditarOrcamentoPedidoVenda />}/>
													<Route path="/pedidos-venda/:sistema/orcamento/historico/:id" element={<Historico path={"/pedidos-venda"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="TDCB" roleCreate="TDCA" roleEdit="TDCC"/>}>
													<Route path="/tipos-de-clausulas" element={<ClausulasTipos/>}/>
													<Route path="/tipos-de-clausulas/:id" element={<EditaClausulasTipos/>}/>
													<Route path="/clausulasTipos/historico/:id" element={<Historico path={"/contratos/clausulas/tipo"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="ESTB" roleCreate="ESTA" roleEdit="ESTC"/>}>
													<Route path="/estoques" element={<Estoque/>}/>
													<Route path="/estoques/:id" element={<EditarEstoques/>}/>
													<Route path="/estoques/historico/:id" element={<Historico path={"/estoques"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="EMVB" roleCreate="EMVA" roleEdit="EMVC"/>}>
													<Route path="/estoques/movimentos" element={<EstoqueMovimentos/>}/>
													<Route path="/estoques/movimentos/:id" element={<EditarEstoqueMovimento/>}/>
													<Route path="/estoques/movimentos/ordem-servico" element={<EditarOrdemServicoMovimentoEstoque/>}/>
													<Route path="/estoques/movimentos/compras" element={<Compras/>}/>
													<Route path="/estoques/movimentos/transferencia" element={<EstoqueMovimentoTransferencia/>}/>
													<Route path="/estoques/movimentos/historico/:id" element={<Historico path={"/estoques/movimentos"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="LTPB" roleCreate="LTPA" roleEdit="LTPC"/>}>
													<Route path="/listas-preco" element={<ListaPreco/>}/>
													<Route path="/listas-preco/:id" element={<EditarListaPreco/>}/>
													<Route path="/listas-preco/historico/:id" element={<Historico path={"/listas-preco"}/>}/>
													<Route path="/listas-preco/atualizacao/lote" element={<AtualizacaoEmLote/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="ICMB" roleCreate="ICMA" roleEdit="ICMC"/>}>
													<Route path="/icms" element={<Icms/>}/>
													<Route path="/icms/:id" element={<EditarIcms/>}/>
													<Route path="/icms/historico/:id" element={<Historico path={"/icms"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="SIPB" roleCreate="SIPA" roleEdit="SIPC"/>}>
													<Route path="/selos-ipem" element={<SelosIpem/>}/>
													<Route path="/selos-ipem/:id" element={<EditarSeloIpem/>}/>
													<Route path="/selos-ipem/historico/:id" element={<Historico path={"/selos-ipem"}/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation='EMLB' roleCreate='EMLA' roleEdit='EMLC'/>}>
													<Route path='/contas-email' element={<ContasEmail/>}/>
													<Route path='/contas-email/:id' element={<EditarContaEmail/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="RPRB"/>}>
													<Route path="/relatorios/produtividade" element={<Produtividade/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="RORC"/>}>
													<Route path="/relatorios/relatorioOrcamento" element={<RelatorioOrcamento/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="RPEC"/>}>
													<Route path="/relatorios/relatorioOrdemServicoPeca" element={<RelatorioOrdemServicoPeca/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="RELB" roleCreate='RELA' roleEdit='RELC'/>}>
													<Route path="/relatorios" element={<Relatorios/>}/>
												</Route>
												<Route element={<RequireRole roleNavigation="FATB" roleCreate="FATA" roleEdit="FATC"/>}>
													<Route path="/faturamentos" element={<Faturamento/>}/>
													<Route path="/faturamentos/composicao" element={<PrepararFaturamento/>}/>
													<Route path="/faturamentos/:id" element={<EditarFaturamento/>}/>
													<Route path="/faturamentos/new" element={<EditarFaturamento/>}/>
												</Route>
												<Route path="/backoffice/recepcao" element={<BackOfficeRecepcao/>}/>
												<Route path="/backoffice/recepcao/:id" element={<BackOfficeRecepcaoEditar/>}/>
												<Route path="/backoffice/recepcao/nota-fiscal" element={<BackOfficeRecepcaoNotaFiscal/>}/>
												<Route path="/backoffice/expedicao/:id" element={<BackOfficeExpedicaoEditar/>}/>
												<Route path="/backoffice/estoque" element={<BackOfficeEstoque/>}/>
												<Route path="/backoffice/estoque/movimentar" element={<BackOfficeEstoqueEditar/>}/>
												<Route path="/backoffice/estoque-recondicionado" element={<EstoqueRecondicionado/>}/>
												<Route path="/backoffice/estoque-recondicionado/:id" element={<EditarEstoqueRecondicionado/>}/>
												<Route path="/laboratorio/laudo" element={<LaboratorioLaudo/>}/>
												<Route path="/laboratorio/laudo/:id" element={<LaboratorioLaudoEditar/>}/>
												<Route path="/laboratorio/manutencao/:id" element={<LaboratorioManutencaoEditar/>}/>
												<Route path="/laboratorio/limpeza/:id" element={<LaboratorioLimpezaEditar/>}/>
												<Route path="/laboratorio/teste/:id" element={<LaboratorioTesteEditar/>}/>
												<Route path="/laboratorio/prateleiras" element={<Prateleiras/>}/>
												<Route path="/laboratorio/prateleiras/:id" element={<EditarPrateleira/>}/>
												<Route path="/laboratorio/relatorios" element={<RelatoriosLaboratorio/>}/>
												<Route path="/calendarios" element={<Calendarios/>}/>
												<Route path="/calendarios/:id" element={<EditarCalendario/>}/>
												<Route path="/sla/calendarios" element={<Calendarios/>}/>
												<Route path="/sla/calendarios/:id" element={<EditarCalendario/>}/>
												<Route path="/sla/configuracoes" element={<FaixasANS/>}/>
												<Route path="/relatorios/relatorioOrdemServicoCliente" element={<RelatorioOrdemServicoCliente/>}/>
												<Route path="/relatorios/backlogOrcamento" element={<BacklogOrcamento/>}/>
												<Route path="/relatorios/backlogSLA" element={<BacklogSLA/>}/>
												<Route path='/comercial/comissao/acompanhamento' element={<Acompanhamento/>}/>
												<Route path='/comercial/comissao/acompanhamentoIndividual' element={<AcompanhamentoIndividual/>}/>
												<Route path='/comercial/comissao/fechamento' element={<Fechamento/>}/>
												<Route path='/comercial/comissao/fechamento/:id'element={<EditarFechamento/>}/>
												<Route path='/comercial/comissao/tipoComissao' element={<TipoComissao/>}/>
												<Route path='/comercial/comissao/tipoComissao/:id' element={<EditarTipoComissao/>}/>
												<Route path="/relatorios/relatorioOrdemServicoEtapa" element={<RelatorioOrdemServicoEtapa/>}/>
												<Route path="/relatorios/relatorioOrdemServicoRestricao" element={<RelatorioOrdemServicoRestricao/>}/>
												<Route path="/relatorios/relatorioOperacoes" element={<RelatorioOperacoes/>}/>
												<Route path="/relatorios/kpi/laboratorio" element={<RelatorioKPILaboratorio/>}/>
												<Route path="/campo" element={<CampoOrdensServico/>}/>
												<Route path="/campo/:id" element={<EditarOrdemServicoCampo/>}/>
												<Route path="/campo/agendas" element={<CampoAgendamento/>}/>
												<Route path="/campo/relatorioagenda" element={<RelatorioAgenda/>}/>
												<Route path="/projetos" element={<Projetos/>}/>
												<Route path="/projetos/:id" element={<EditarProjeto/>}/>
											</Route>
										</Route>
									</Routes>
								</div>
								{!!token ? <AppFooter layoutColorMode={layoutColorMode}/> : null}
							</div>
							<CSSTransition classNames="layout-mask" timeout={{enter: 200, exit: 200}} in={mobileMenuActive} unmountOnExit>
								<div className="layout-mask p-component-overlay"/>
							</CSSTransition>
							<ToastContainer position="top-right"
											autoClose={2000}
											hideProgressBar={false}
											newestOnTop={false}
											closeOnClick
											rtl={false}
											pauseOnFocusLoss
											draggable
											pauseOnHover
							/>
							<ConfirmDialog accept={sairDaAplicacao}
										   message="Tem certeza que deseja sair da aplicação?"
										   onHide={() => setModalDeConfirmacao(false)}
										   reject={() => {}} visible={modalDeConfirmacao}
							/>
							{dialogs}
						</DialogProvider>
					</QueryParamsProvider>
				</PageAndTabProvider>
			</CalendarioProvider>
		</div>
	);
}

export default App;
