import React, {useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {Button} from "../../../components/button";
import {relatorioOrdemServicoLaboratorio} from "../../../service/relatorioOrdemServicoLaboratorio";
import moment from "moment";
import {BlockUI} from "primereact/blockui";
import {saveAs} from "file-saver";
import {Checkbox} from "../../../components/checkbox";
import {optionsFilterRestricoesLaboratorio, restricoesButtons} from "../../../service/ordemServicoService";
import {SelectButton as PSelectButton} from "primereact/selectbutton";
import {SelectButton} from "../../../components/selectbutton";
import {If} from "../../../components/conditional/If";

const etapasLaboratorio = [
    {label: <><i className="fas fa-asterisk"/> Todas</>, value: 0},
    {label: <><i className="fas fa-user-md"/> Laudo</>, value: "Laudo"},
    {label: <><i className="fas fa-screwdriver-wrench"/> Manutenção</>, value: "Manutenção"},
    {label: <><i className="fas fa-broom"/> Limpeza</>, value: "Limpeza"},
    {label: <><i className="fas fa-bolt"/> Teste</>, value: "Teste"},
    {label: <><i className="fas fa-arrow-right" style={{transform: "rotate(-45deg)"}}/> Concluído</>, value: "Concluído"}
];

export function RelatoriosLaboratorio() {

    const [params, setParams] = useState({
        etapa: 0,
        restricoes: null,
        separarRestricoes: false,
        somenteSelecionadas: true
    });
    const [loading, setLoading] = useState(false);

    async function handleImprimir() {
        setLoading(true);
        const query = [];
        if (params.etapa?.length) query.push(`etapa=${params.etapa}`);
        if (params.separarRestricoes) {
            query.push(`separarRestricoes`);
            if (params.somenteSelecionadas) query.push(`somenteSelecionadas`);
        }
        if (params.restricoes >= 0 && params.restricoes !== null) query.push(`restricoes=${params.restricoes}`);
        const data = await relatorioOrdemServicoLaboratorio.imprimir(query);
        saveAs(new Blob([data], {type: "text/csv"}), `Laboratorio-${moment().format("YYYYDDMMHHmmss")}.csv`);
        setLoading(false);
    }

    function handleChange(event) {
        setParams(prevParams => ({...prevParams, [event.name]: event.value}));
    }

    function handleChangeRestricoes(event) {
        setParams(prevParams => ({...prevParams, "restricoes": event.value}));
    }

    return (
        <BlockUI blocked={loading}>
            <Panel header="Relatório de Ordens de Serviço em Laboratório">
                <PanelContent>
                    <SelectButton col={6} options={etapasLaboratorio} label="Etapa" name="etapa" value={params.etapa} onChange={handleChange}/>
                    <div className="col-6 xm">
                        <label>Restrições</label>
                        <div>
                            <PSelectButton style={{float: "left"}} itemTemplate={restricoesButtons} label="Restrições" options={optionsFilterRestricoesLaboratorio} name="restricoes" value={params.restricoes} onChange={handleChangeRestricoes}/>
                        </div>
                    </div>
                    <div className="col-6"/>
                    <div className="col-6">
                        <Checkbox style={{marginTop: ".25em"}} name="separarRestricoes" value={params.separarRestricoes} onChange={handleChange} label="Imprimir uma restrição por linha"/>
                        <If condition={params.separarRestricoes} children={<Checkbox style={{marginTop: ".25em"}} name="somenteSelecionadas" value={params.somenteSelecionadas} onChange={handleChange} label="Imprimir apenas restrições selecionadas"/>}/>
                    </div>
                </PanelContent>
                <PanelFooter>
                    <Button label="Gerar CSV" icon="fas fa-print" onClick={handleImprimir}/>
                </PanelFooter>
            </Panel>
        </BlockUI>
    );

}
