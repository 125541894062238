export const menuGroups = [
    {key: "BOF", label: "BackOffice", icon: "fas fa-warehouse", items: []},
    {key: "LAB", label: "Laboratório", icon: "fas fa-house-flag", items: []},
    {key: "CAM", label: "Campo", icon: "fas fa-road", items: []},
    {key: "EST", label: "Estoque", icon: "fas fa-boxes", items: []},
    {key: "SLA", label: "SLA", icon: "fas fa-signature", items: []},
    {key: "COM", label: "Comercial", icon: "fa-regular fa-handshake", items: []},
];

export const menuLaboratorioItems = [
    {label: "Ordens de Serviços", icon: "fas fa-user-md", to: "/laboratorio/laudo", role: "LABA", parent: "LAB"},
    {label: "Prateleiras", icon: "fa-solid fa-inbox", to: "/laboratorio/prateleiras", role: "PRAB", parent: "LAB"},
    {
        label: "Relatórios",
        icon: "fa-solid fa-file-lines",
        parent: "LAB",
        items: [
            {label: "Ordens de Serviço", icon: "fa-solid fa-file-lines", to: "/laboratorio/relatorios", role: "LABA", parent: "LAB"},
            {label: "Produtividade (KPI)", icon: "fa-solid fa-file-lines", to: "/relatorios/kpi/laboratorio", role: "RKPIL", parent: "LAB"},
            {label: "Relatório de Operações", icon: "fa-solid fa-file-lines", to: "/relatorios/relatorioOperacoes", role: "ROPE", parent: "LAB"},
        ]
    },
];

export const menuCampoItems = [
    {label: "Ordens de Serviços", icon: "fas fa-user-md", to: "/campo", role: "CAMA", parent: "CAM"},
    {label: "Agendas", icon: "fas fa-calendar-check", to: "/campo/agendas", role: "CAMB", parent: "CAM"},
    {label: "Projetos", icon: "fas fa-star", to: "/projetos", role: "CONB", parent: "CAM"},
    {label: "Relatório de Agendas", icon: "fa-solid fa-file-lines", to: "/campo/relatorioagenda", role: "RAGE", parent: "CAM"}
];

export const menuBackOfficeItems = [
    {label: "Recepção/Expedição", icon: "fas fa-inbox", to: "/backoffice/recepcao", role: "BOFA", parent: "BOF"}
];

export const menuEstoqueItems = [
    {label: "Solicitações de Peças", icon: "fas fa-boxes", to: "/backoffice/estoque", role: "BOFB", parent: "EST"},
    {label: "Gestão Estoque Recondicionado", icon: "fas fa-boxes", to: "/backoffice/estoque-recondicionado", role: "EMVB", parent: "EST"}
];

export const menuSlaItems = [
    {label: "Modelos", icon: "fas fa-signature", to: "/sla/modelos", role: "ANSB", parent: "SLA"},
    {label: "Faixas", icon: "fas fa-percent", to: "/sla/configuracoes", role: "ANSB", parent: "SLA"},
    {label: "Calendários", icon: "fas fa-calendar", to: "/sla/calendarios", role: "CALB", parent: "SLA"},
];

export const menuComercialItems = [
    {label: "Ordens de Serviço", icon: "fa-solid fa-scroll", to: "/ordens-servico", role: "OSSB", parent: "COM"},
    {
        label: "Relatórios",
        icon: "fa-solid fa-file-lines",
        parent: "COM",
        items: [
            {label: "Relatório de OS - Cliente", icon: "fa-solid fa-file-lines", to: "/relatorios/relatorioOrdemServicoCliente", role: "ROSC"},
            {label: "Backlog de Orçamentos", icon: "fa-solid fa-file-lines", to: "/relatorios/backlogOrcamento", role: "BKORC"},
            {label: "Relatório de OS por Etapa", icon: "fa-solid fa-file-lines", to: "/relatorios/relatorioOrdemServicoEtapa", role: "ROSE"},
            {label: "Relatório de OS por Restrição", icon: "fa-solid fa-file-lines", to: "/relatorios/relatorioOrdemServicoRestricao", role: "RRES"},
            {label: "Backlog de SLA", icon: "fa-solid fa-file-lines", to: "/relatorios/backlogSLA", role: "BKSLA"},
        ]
    },
    {
        label: "Comissão",
        icon: "fa-solid fa-coins",
        parent: "COM",
        items: [
            {
                label: "Acompanhamento",
                icon: "fa-solid fa-sack-dollar",
                to: "/comercial/comissao/acompanhamento",
                role: "ACSB"
            },
            {
                label: "Acompanhamento Consultor",
                icon: "fa-solid fa-magnifying-glass-dollar",
                to: "/comercial/comissao/acompanhamentoIndividual",
                parent: "COM",
                role: "ACVB"
            },
            {
                label: "Fechamento",
                icon: "fa-solid fa-lock",
                to: "/comercial/comissao/fechamento",
                role: "FCSB"
            },
            {
                label: "Tipo de Comissão",
                icon: "fa-solid fa-percent",
                to: "/comercial/comissao/tipoComissao",
                parent: "TICB"
            },
        ]
    }
];

export const menuItems = [
    ...menuBackOfficeItems,
    ...menuLaboratorioItems,
    ...menuCampoItems,
    ...menuEstoqueItems,
    ...menuSlaItems,
    ...menuComercialItems,
    {label: "Previsão de Compras", icon: "fa-solid fa-shopping-basket", to: "/estoques/movimentos/compras", role: "COMB"},
    {label: "Estoque & Compras de OS", icon: "fa-solid fa-scroll", to: "/ordens-servico/estoques/movimentos", role: "OSSQ"},
    {label: "Fluxos de Ordens de Serviço", icon: "fa-solid fa-list", to: "/ordens-servico/fluxos", role: "OSEB"},
    {label: "Faturamento", icon: "fa-solid fa-wallet", to: "/faturamentos", role: "FATB"},
    {label: "Contratos", icon: "fa-solid fa-file-signature", to: "/contratos", role: "CONB"},
    {label: "Tipos de Clausulas", icon: "fa-solid fa-handshake", to: "/tipos-de-clausulas", role: "TDCB"},
    {label: "Produto", icon: "fa-solid fa-box", to: "/produtos", role: "PROB"},
    {label: "Empresas", icon: "fa-solid fa-city", to: "/empresas", role: "EMPB"},
    {label: "Marcas de Produto", icon: "fa-solid fa-tag", to: "/produtos/marcas", role: "MARB"},
    {label: "Tipos de Produto", icon: "fa-solid fa-box", to: "/produtos/tipos", role: "PRTB"},
    {label: "Clientes", icon: "fa-solid fa-user-tie", to: "/clientes", role: "CLIB"},
    {label: "Perfis", icon: "fa-solid fa-th", to: "/perfis", role: "PRFB"},
    {label: "Usuários", icon: "fa-solid fa-user", to: "/usuarios", role: "USRB"},
    {label: "Equipamentos", icon: "fa-solid fa-terminal", to: "/equipamentos", role: "EQPB"},
    {label: "Pedido de Venda", icon: "fa-solid fa-file-invoice", to: "/pedidos-venda", role: "PDVB"},
    // {label: "Estoque", icon: "fa-solid fa-warehouse", to: "/estoques", role: "ESTB"},
    {label: "Movimento de Estoque", icon: "fa-solid fa-plus-minus", to: "/estoques/movimentos", role: "EMVB"},
    {label: "Lista de Preço", icon: "fa-solid fa-file-invoice-dollar", to: "/listas-preco", role: "LTPB"},
    {label: "Selos do IPEM", icon: "fa-solid fa-tag", to: "/selos-ipem", role: "SIPB"},
    {label: "Contas de E-mail", icon: "fa-solid fa-envelope", to: "/contas-email", role: "EMLB"},
    {label: "Relatório de Ordens de Serviço", icon: "fa-solid fa-chart-bar", to: "/relatorios/", role: "RELB"},
    {label: "Relatório de Produtividade", icon: "fa-solid fa-chart-bar", to: "/relatorios/produtividade", role: "RPRB"},
    {label: "Relatório de Orçamentos", icon: "fa-solid fa-file-lines", to: "/relatorios/relatorioOrcamento", role: "RORC"},
    {label: "Relatório de Peças", icon: "fa-solid fa-file-lines", to: "/relatorios/relatorioOrdemServicoPeca", role: "RPEC"},
    {label: "Alíquotas de ICMS", icon: "fa-solid fa-money-bill-transfer", to: "/icms", role: "ICMB"},
    {label: "[Tela do Coletor]", icon: "fa-solid fa-scroll", to: "/coletor", role: "OSSP"}
];
