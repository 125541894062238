import React, {useEffect, useState} from 'react';
import {FormEdit} from "../../components/form/Edit";
import {useParams} from "react-router-dom";
import {useAuth} from '../../context/AuthContext';
import {ordemServicoFluxoService} from '../../service/ordemServicoFluxoService';
import {InputText} from "../../components/inputtext";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {isStacked} from "../../context/BreakpointContext";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {Button} from "../../components/button";
import {optionsFluxoFlags, ordemServicoEtapaService} from "../../service/ordemServicoEtapaService";
import {MultiSelect} from "../../components/multiselect";
import {optionsStatus} from "../../service/ordemServicoService";
import {Dropdown} from "../../components/dropdown";
import {buildValidator, isRequired} from "../../utils/fieldValidator";
import {withDialog} from "../../utils/dialogContext";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {FluxoOperacaoDropdown} from "../../components/dropdown/fluxoOperacaoDropdown";

export const EditarFluxo = withDialog(({showDialog}) => {

	const {id} = useParams();
	const {roles} = useAuth();

	const [fluxo, setFluxo] = useState(ordemServicoFluxoService.criar());
	const [etapasSelecionadas, setEtapasSelecionadas] = useState([]);
	const [messages, setMessages] = useState(buildValidator());
	const [proximas, setProximas] = useState([]);

	function handleChange(event) {
		setFluxo({...fluxo, [event.name]: event.value});
	}

	function handleChangeEtapa(event) {
		fluxo.etapas[event.index][event.name] = event.value;
		fluxo.etapas[event.index]._alterado = true;
		if (event.name === "proximas") {
			if (!fluxo.etapas[event.index].proximas.includes(fluxo.etapas[event.index].proximaPadrao)) {
				fluxo.etapas[event.index].proximaPadrao = null;
			}
			if (fluxo.etapas[event.index].proximas.length === 1) {
				fluxo.etapas[event.index].proximaPadrao = fluxo.etapas[event.index].proximas[0];
			}
		}
		setFluxo({...fluxo});
		setProximas(fluxo.etapas.map(e => ({label: e.descricao, value: e.sequencia})));
	}

	useEffect(() => {
		if (id !== "new") {
			ordemServicoFluxoService.buscar(id).then(fluxo => {
				setFluxo(fluxo);
				setProximas(fluxo.etapas.map(e => ({label: e.descricao, value: e.sequencia})));
			});
		}
	}, [id]);

	function adicionarEtapa() {
		const etapa = ordemServicoEtapaService.criar();
		etapa.sequencia = fluxo.etapas.map(e => e.sequencia).reduce((a, b) => Math.max(a, b), 0) + 1;
		fluxo.etapas.push(etapa);
		setFluxo({...fluxo});
	}

	function removerEtapas() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover as etapas selecionadas?" onYes={() => {
			fluxo.etapas = fluxo.etapas.filter(fe => !etapasSelecionadas.some(e => (e._key && e._key === fe._key) || (e.id && e.id === fe.id)));
			setFluxo({...fluxo});
		}}/>);
	}

	const etapaControlSet = (
		<div>
			<Button icon="pi pi-plus" onClick={adicionarEtapa}/>
			<Button disabled={!etapasSelecionadas?.length} onClick={removerEtapas} danger icon="pi pi-minus"/>
		</div>
	);

	const etapaHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={6} header={etapaControlSet}/>
			</Row>
			<Row>
				<Column style={{width: "3em"}}/>
				<Column style={{width: "3em"}} selectionMode="multiple"/>
				<Column style={{width: "20em"}} header="Descrição"/>
				<Column header="Sequências Possíveis"/>
				<Column style={{width: "20em"}} header="Sequência Padrão"/>
				<Column style={{width: "15em"}} header="Status da OS"/>
			</Row>
		</ColumnGroup>
	);

	const etapaFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={6} footer={etapaControlSet}/>
			</Row>
		</ColumnGroup>
	);

	async function preSalvar(fluxo) {
		for (const etapa of fluxo.etapas) {
			etapa.id = (await ordemServicoEtapaService.salvar({...etapa, id: null, postBack: true})).id;
		}
	}

	function handleRowReorder(event) {
		fluxo.etapas = event.value;
		setFluxo({...fluxo});
		setProximas(fluxo.etapas.map(e => ({label: e.descricao, value: e.sequencia})));
	}

	function validarEtapas() {
		messages.etapas = [...Array(fluxo.etapas.length)].map((_, i) => ({
			descricao: isRequired(fluxo.etapas[i].descricao)
		}));
		setMessages({...messages});
	}

	return (
		<FormEdit podeDeletar={!!roles.OSED}
		          podeSalvar={!!roles.OSEC}
		          descricao="Fluxo de Ordem de Serviço"
		          service={ordemServicoFluxoService}
		          value={fluxo}
		          onValidate={setMessages}
		          blocked={id !== "new" && !fluxo.id}
		          preSalvar={preSalvar}
		>
			<InputText invalid={messages.descricao} onBlur={() => {
				messages.descricao = isRequired(fluxo.descricao);
				setMessages({...messages});
			}} required col={7} label="Descrição" name="descricao" value={fluxo.descricao} onChange={handleChange}/>
			<FluxoOperacaoDropdown col={2} name="operacao" value={fluxo.operacao} onChange={handleChange}/>
			<MultiSelect col={3} label="Opções" name="flags" options={optionsFluxoFlags} value={fluxo.flags} onChange={handleChange}/>
			<div className="col-12">
				<DataTable value={fluxo.etapas}
				           emptyMessage="Nenhuma etapa adicionada"
				           selection={etapasSelecionadas}
				           onSelectionChange={e => setEtapasSelecionadas(e.value)}
				           headerColumnGroup={etapaHeaderGroup}
				           footerColumnGroup={etapaFooterGroup}
				           selectionMode="checkbox"
				           reorderableRows
				           onRowReorder={handleRowReorder}
				           header={isStacked() ? null : etapaControlSet}
				           footer={isStacked() ? null : etapaControlSet}
				>
					<Column rowReorder/>
					<Column selectionMode="multiple"/>
					<Column style={{flexDirection: "column", alignItems: "start"}} header="Descrição" field="descricao" body={(osfe, column) => (
						<InputText onBlur={validarEtapas} required invalid={messages.etapas?.[column.rowIndex]?.descricao} name="descricao" index={column.rowIndex} value={osfe.descricao} onChange={handleChangeEtapa}/>
					)}/>
					<Column style={{flexDirection: "column", alignItems: "start"}} header="Sequências Possíveis" field="proximas" body={(osfe, column) => (
						<MultiSelect onChange={handleChangeEtapa} options={proximas.filter((e, i) => i !== column.rowIndex)} index={column.rowIndex} name="proximas" value={osfe.proximas}/>
					)}/>
					<Column style={{flexDirection: "column", alignItems: "start"}} header="Sequência Padrão" field="proximaPadrao" body={(osfe, column) => (
						<Dropdown index={column.rowIndex} name="proximaPadrao" value={osfe.proximaPadrao} onChange={handleChangeEtapa} options={[{label: "Nenhuma", value: null}, ...proximas.filter(e => osfe.proximas.includes(e.value))]}/>
					)}/>
					<Column style={{flexDirection: "column", alignItems: "start"}} body={(osfe, column) => (
						<Dropdown options={[{label: "Não Alterar", value: null}, ...optionsStatus]} index={column.rowIndex} name="status" value={osfe.status} onChange={handleChangeEtapa}/>
					)}/>
				</DataTable>
			</div>
		</FormEdit>
	);

});
