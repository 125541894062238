import React from "react";
import {RadioButton} from "primereact/radiobutton";

export function SelectEstoquePeca(props) {

    function handleChange(value) {
        return props?.onChange({name: props.name, index: props.index, value});
    }

    return (
        <div className={`col-${props.col || "12"}`}>
            <div className="flex align-items-center">
                <RadioButton disabled={props.disabled || !props.estoqueNovas} inputId="pc_NOVAS" onChange={() => handleChange("NOVAS")} checked={props.value === "NOVAS"}/>
                <label htmlFor="pc_NOVAS" className="ml-2">Nova</label>
            </div>
            <div className="flex align-items-center">
                <RadioButton disabled={props.disabled} inputId="pc_RECONDICIONADAS" onChange={() => handleChange("RECONDICIONADAS")} checked={props.value === "RECONDICIONADAS"}/>
                <label htmlFor="pc_RECONDICIONADAS" className="ml-2">Recondicionada</label>
            </div>
        </div>
    );

}
