import React, {useState} from 'react';
import {AutoComplete} from '.';
import {clienteService} from '../../service/clienteService';
import {useDialog} from "../../utils/dialogContext";
import {CadastrarCliente} from "../../pages/Clientes/CadastrarCliente";

export function ClienteAutoComplete(props) {

	const [loading, setLoading] = useState(false);
	const {showDialog} = useDialog();
	const [clientes, setClientes] = useState([]);
	const [emptyMessage, setEmptyMessage] = useState("");

	function completeMethod(event) {
		const query = [
			"page=0",
			"size=100"
		];
		if (event.query.length) query.push(`textoDeBusca=${encodeURIComponent(event.query)}`);
		if (props?.repetidos?.length > 0) query.push(`repetidos=${props.repetidos}`);
		if (props.grupo?.clienteId) query.push(`grupo=${props.grupo.clienteId}`);
		clienteService.autocomplete(query).then(clientes => {
			if (!clientes?.length) {
				if (props.offerNew) {
					clientes.push({id: -1, query: event.query, autoComplete: `Cadastrar cliente ${event.query}...`});
				} else {
					setEmptyMessage("Nenhum registro encontrado");
				}
			} else {
				setEmptyMessage("");    
			}
			setClientes(clientes);
		});
	}

	const itemTemplate = cliente => {
        if (cliente.id !== -1) {
			return (
				<div className="flex align-items-center" style={{gap: '0.5rem'}}>
					<i className={"fa-solid fa-user-tie"}/>
					<div>{cliente.cpfCnpj} - {cliente.nomeFantasia} ({cliente.municipio})</div>
				</div>
			);
		} else {
			return (
				<div className="flex align-items-center" style={{gap: '0.5rem'}}>
					<i className={"fa-solid fa-user-tie"}/>
					<div>{cliente.autoComplete}</div>
				</div>
			);
		}
    }

	function handleChange(event) {
		if (event.value?.id === -1) {
			showDialog(<CadastrarCliente matriz={props.matriz} showMatriz={props.showMatriz} query={event.value.query} onModalClose={value => {
				props.onChange({...event, value});
			}}/>);
		} else {
			props.onChange(event);
		}
	}

	async function handleChange(event) {
		if (event.value?.id) {
			setLoading(true);
			props.onChange({...event, value: await clienteService.buscar(event.value.id)});
			setLoading(false);
		} else {
			props.onChange(event);
		}
	}

	return (
		<AutoComplete
			col={3}
			placeholder="razão social, nome fantasia, CPF ou CNPJ"
			label="Cliente"
			name="cliente"
			value={props.value}
			itemTemplate={itemTemplate}
			forceSelection
			completeMethod={completeMethod}
			suggestions={clientes}
			loading={loading}
			disabled={loading}
			invalid={emptyMessage}
			{...props}
			onChange={handleChange}
		/>
	);

}
