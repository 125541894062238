import React, {useEffect, useState, useContext} from "react"
import {useNavigate, useSearchParams} from "react-router-dom";
import {DataTable} from "../../../components/datatable";
import {BlockUI} from "primereact/blockui";
import {Column} from "primereact/column";
import {ProdutoAutoComplete} from "../../../components/autocomplete/produtoAutoComplete";
import {InputNumber} from "../../../components/inputnumber";
import {ordemServicoItemService} from "../../../service/ordemServicoItemService";
import {SelectButton} from "../../../components/selectbutton";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {Button} from "../../../components/button";
import {Checkbox} from "../../../components/checkbox";
import {RegistrarEvento} from "../../__Commons/RegistrarEvento";
import { ordemServicoService } from "../../../service/ordemServicoService";
import { eventoService } from "../../../service/eventoService";
import { DialogContext } from "../../../utils/dialogContext";
import {InputText} from "../../../components/inputtext";
import {SelectEstoquePeca} from "../../__Commons/SelectEstoquePeca";
import {printSaldo} from "../../Produto/TabelaProduto";

const optionsStatusPeca = [
    {label: "Solicitado", value: "SOLICITADO"},
    {label: "Compras", value: "COMPRAS"},
    {label: "Entregue", value: "MOVIMENTADO"},
    {label: "Cancelado", value: "CANCELADO"}
];

function blockOptions(peca, o) {
    if (!peca.produto?.id) {
        return true;
    }
    switch (peca.movimentosEstoque.length) {
        case 0:
            switch (peca.estoque) {
                case "NOVAS":
                    if (peca.produto?.saldoDisponivel < peca.quantidade) {
                        return ["MOVIMENTADO"].includes(o.value);
                    }
                    break;
            }
            return false;
        case 1:
            return ["SOLICITADO", "COMPRAS"].includes(o.value);
        default:
            return ["SOLICITADO", "COMPRAS", "MOVIMENTADO"].includes(o.value);
    }
}

export function BackOfficeEstoqueEditar() {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const numero = searchParams.get("numero");
    const [itens, setItens] = useState([]);
    const [blocked, setBlocked] = useState(true);
    const [ultimoAcompanhamento, setUltimoAcompanhamento] = useState(null)
	const {showDialog} = useContext(DialogContext);

    useEffect(() => {
        ordemServicoItemService.listarPecas(numero).then(pecas => {
            const itens = {};
            for (const peca of pecas) {
                if (!itens[peca.id]) {
                    itens[peca.id] = {
                        id: peca.id,
                        equipamento: peca.equipamento,
                        _alterado: false,
                        pecas: []
                    };
                }
                itens[peca.id].pecas.push(peca);
            }
            setUltimoAcompanhamento(pecas[0].ultimoAcompanhamento)
            setItens(Object.keys(itens).map(k => itens[k]));
            setBlocked(false);
        });
    }, [numero]);

    function registrarEvento() {
		showDialog(<RegistrarEvento onModalClose={async evento => {
			evento = await eventoService.salvar({...evento, postBack: true});
			await ordemServicoService.salvarAcompanhamentoEvento({eventos: evento.id, acompanhamento: ultimoAcompanhamento});
		}}/>);
	}

    function mostrarPecas(item, index) {
        function handleChange(event) {
            if (event.name === "status" && !event.value) {
                return;
            }
            setItens(prevItens => {
                prevItens[index]._alterado = true;
                prevItens[index].pecas[event.index][event.name] = event.value;
                if (event.name === "status" && event.value === "COMPRAS") {
                    prevItens[index].pecas[event.index].estoque = "NOVAS";
                }
                return [...prevItens];
            });
        }
        return (
            <DataTable paginator={false} footer={null} key={item.id} header={(
                <div className="grid" style={{marginTop: "1em"}}>
                    <InputText col={12} label="Equipamento" readOnly value={item.equipamento.autoComplete}/>
                    <InputText col={12} label="Laudo Interno" style={{height: "8em"}} readOnly multiline value={item.pecas?.[0]?.laudoInterno}/>
                </div>
            )} value={item.pecas}>
                <Column style={{width: "5em"}} header="Item" field="sequencia"/>
                <Column header="Produto" field="produto" body={(osip, c) => <ProdutoAutoComplete readOnly={osip.status !== "SOLICITADO"} index={c.rowIndex} name="produto" value={osip.produto} col={12} label={null} onChange={handleChange}/>}/>
                <Column style={{width: "10em"}} header="Quantidade" field="quantidade" body={(osip, c) => <InputNumber readOnly={osip.status !== "SOLICITADO"} index={c.rowIndex} name="quantidade" value={osip.quantidade} col={12} label={null} onChange={handleChange}/>}/>
                <Column style={{width: "10em"}} header="Saldo Disponível" field="produto.saldoDisponivel" body={osip => printSaldo(osip.produto, "")}/>
                <Column style={{width: "10em"}} header="Estoque" field="estoque" body={(osip, c) => (
                    <SelectEstoquePeca estoqueNovas={osip.produto?.saldoDisponivel}
                                       estoqueRecondicionadas={osip.produto?.saldoRecondicionadas}
                                       disabled={osip.status !== "SOLICITADO"}
                                       onChange={handleChange}
                                       name="estoque"
                                       value={osip.estoque}
                                       index={c.rowIndex}
                    />
                )}/>
                <Column style={{width: "32em"}} header="Status" field="status" body={(osip, c) => <SelectButton optionDisabled={o => blockOptions(osip, o)} name="status" index={c.rowIndex} value={osip.status} onChange={handleChange} options={optionsStatusPeca}/>}/>
            </DataTable>
        );
    }

    async function handleSalvar() {
        setBlocked(true);
        for (const item of itens) {
            item._alterado = false;
            item.pecas = await ordemServicoItemService.salvarPecas(numero, item.pecas);
        }
        setItens(prevItens => [...prevItens]);
        setBlocked(false);
    }

    function handleVoltar() {
        navigate(-1);
    }

    return (
        <BlockUI blocked={blocked}>
            <Panel header={`Movimentação de Estoque dos Itens da Ordem de Serviço nº${numero}`}>
                <PanelContent>
                    <div className="col-12">{itens.length ? itens.map(mostrarPecas) : "Nenhuma peça adicionada a essa ordem de serviço"}</div>
                </PanelContent>
                <PanelFooter>
                    <Button label="Registrar Evento" warning icon="fa-solid fa-file-lines" onClick={registrarEvento}/>
                    <Button success disabled={!itens.some(i => i._alterado) || itens.some(i => i.pecas?.some(ip => !ip.produto?.id || ip.quantidade == null))} icon="fas fa-save" label="Salvar" onClick={handleSalvar}/>
                    <Button icon="fas fa-arrow-left" secondary label="Voltar" onClick={handleVoltar}/>
                </PanelFooter>
            </Panel>
        </BlockUI>
    );

}
