import React, {useContext, useEffect, useState} from "react";
import {Dialog, DialogFooter} from "../../../components/dialog";
import {PanelContent} from "../../../components/panel";
import {Button} from "../../../components/button";
import {ConfirmDialogV2} from "../../../components/confirmdialog";
import {DialogContext} from "../../../utils/dialogContext";
import {DataTable} from "../../../components/datatable";
import {Column} from "primereact/column";
import {estoqueMovimentoService} from "../../../service/estoqueMovimentoService";
import {useAuth} from "../../../context/AuthContext";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {ProgressDialog} from "../../../components/progressdialog/ProgressDialog";
import {estoqueService} from "../../../service/estoqueService";
import {Observacao} from "../../__Commons/Observacao";

export function AjusteEstoqueRecondicionada({produtos, onSearch}) {

    const {usuario} = useAuth();
    const {showDialog} = useContext(DialogContext);
    const [visible, setVisible] = useState(true);
    const [movimentos, setMovimentos] = useState([]);

    useEffect(() => {
        estoqueService.listar(["textoDeBusca=3"]).then(estoques => {
            setMovimentos(produtos.filter(produto => produto.saldo - produto.saldoRecondicionadas).map(produto => ({
                ...estoqueMovimentoService.criar(),
                quantidade: produto.saldo - produto.saldoRecondicionadas,
                responsavel: usuario,
                produto,
                estoque: estoques[0]
            })));
        });
    }, [produtos]);

    function handleClose() {
        setVisible(false);
    }

    function onYes() {
        showDialog(<Observacao message="Adicione uma observação para o movimento ou clique em OK" onModalClose={descricao => {
            showDialog(<ProgressDialog onProgress={async setProgress => {
                let progress = 0;
                for (const movimento of movimentos) {
                    await estoqueMovimentoService.salvar({...movimento, descricao});
                    setProgress(++progress / movimentos.length);
                }
                await onSearch();
                handleClose();
            }}/>);
        }}/>);
    }

    function onNo() {
        if (movimentos.length) {
            showDialog(<ConfirmDialogV2 message="Deseja descartar as alterações feitas?" onYes={async () => {
                await onSearch();
                handleClose();
            }} onNo={handleClose}/>);
        } else {
            handleClose();
        }
    }

    const headerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column rowSpan={2} header="Produto"/>
                <Column style={{textAlign: "center"}} colSpan={3} header="Saldo"/>
            </Row>
            <Row>
                <Column header="Antes" style={{width: "8em", textAlign: "center"}}/>
                <Column header="Diferença" style={{width: "8em", textAlign: "center"}}/>
                <Column header="Agora" style={{width: "8em", textAlign: "center"}}/>
            </Row>
        </ColumnGroup>
    );

    return (
        <Dialog style={{width: "850px"}} header="Confirmação" onHide={handleClose} visible={visible}>
            <PanelContent>
                <div className="col-12">
                    Confirma a atualização dos saldos dos seguintes produtos?
                </div>
                <div className="col-12">
                    <DataTable headerColumnGroup={headerColumnGroup} value={movimentos}>
                        <Column field="produto.autoComplete"/>
                        <Column style={{textAlign: "right"}} field="produto.saldoRecondicionadas"/>
                        <Column style={{textAlign: "right"}} field="quantidade"/>
                        <Column style={{textAlign: "right"}} field="produto.saldo"/>
                    </DataTable>
                </div>
            </PanelContent>
            <DialogFooter>
                <Button label="Sim" disabled={!movimentos.length} success onClick={onYes} icon="fas fa-check"/>
                <Button label="Não" secondary onClick={onNo} icon="fas fa-times"/>
            </DialogFooter>
        </Dialog>
    );

}
