import React, {useEffect, useState} from 'react';
import {FormEdit} from "../../components/form/Edit";
import {useNavigate, useParams} from "react-router-dom";
import {buildValidator} from "../../utils/fieldValidator";
import {ordemServicoService} from "../../service/ordemServicoService";
import {RadioButton} from "primereact/radiobutton";
import {DataTable} from "../../components/datatable";
import {emailOrcamentoService} from "../../service/emailOrcamentoService";
import {Column} from "primereact/column";

import "./styles.scss";
import {Dropdown} from "../../components/dropdown";
import {toast} from "react-toastify";
import {ConfirmDialog} from "../../components/confirmdialog";
import {InputText} from "../../components/inputtext";
import {InputTextarea} from "../../components/inputtextarea";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {Spinner} from "../../components/spinner";
import {PanelContent} from "../../components/panel";
import moment from "moment";
import {useDialog} from "../../utils/dialogContext";

export function OrcamentoParaCliente(props) {

	const {id, idDestinatario} = useParams();
    const navigate = useNavigate();
    const {showDialog} = useDialog();
	const [dtoAprovacaoOrcamento, setDtoAprovacaoOrcamento] = useState();
    const [expandedRows, setExpandedRows] = useState(null);
	const [messages, setMessages] = useState(buildValidator());
	const [loading, setLoading] = useState(true);
	const [confirmacao, setConfirmacao] = useState(false);

	function handleChange(event) {
		setDtoAprovacaoOrcamento({...dtoAprovacaoOrcamento, [event.name]: event.value});
	}

    useEffect(() => {
        props.setStaticMenuInactive(true)
    }, [props])

    useEffect(() => {
        emailOrcamentoService.buscarDtoAprovacaoOrcamento(id).then((response) => {
            let clienteFaturamento = null;
            if (response.isExpired) {
                navigate("/public/orcamentosCliente/expired");
            }
            if(response?.clientes.length === 1) {
                clienteFaturamento = response?.clientes[0]
            }
            const orcamentosRecusados = {...response, clienteFaturamento, orcamentos: response?.orcamentos?.map((os) => {
                return {
                    ...os,
                    orcamento: {
                        ...os.orcamento,
                        status: "APROVADO",
                    },
                }
            })}
            setDtoAprovacaoOrcamento(orcamentosRecusados);
        }).finally(() => {
            setLoading(false);
        })
    }, [id]);

    const handleAprovacao = (i) => {
        setDtoAprovacaoOrcamento((dtoAprovacaoOrcamento) => {
            let orcamentosAtualizados = [...dtoAprovacaoOrcamento.orcamentos];
            orcamentosAtualizados[i] = {
                ...orcamentosAtualizados[i],
                orcamento: {
                    ...orcamentosAtualizados[i].orcamento,
                    status: orcamentosAtualizados[i].orcamento.status === 'RECUSADO' ? 'APROVADO' : 'RECUSADO'
                }
            }
            return {
                ...dtoAprovacaoOrcamento,
                orcamentos: orcamentosAtualizados
            };
        });
    }

    const toggleTempalte = (data, row) => {
        return (
            <div className="flex">
                <button
                    className={`
                        max-w-10rem
                        min-w-10rem
                        w-10rem
                        ${dtoAprovacaoOrcamento.orcamentos[row.rowIndex].orcamento.status === 'APROVADO' ? 'text-white' : 'text-500'}
                        border-round-left
                        border-1
                        surface-border
                        py-3
                        cursor-pointer
                        font-bold
                        ${dtoAprovacaoOrcamento.orcamentos[row.rowIndex].orcamento.status === 'APROVADO'? 'bg-green-700' : 'bg-white-alpha-10'}
                        transition-colors
                        transition-duration-500
                    `}
                    onClick={() => handleAprovacao(row.rowIndex)}
                >
                    APROVAR
                </button>
                        <button
                            className={`
                                w-10rem
                                min-w-10rem
                                max-w-10rem
                                ${dtoAprovacaoOrcamento.orcamentos[row.rowIndex].orcamento.status === 'APROVADO'? 'text-500' :  'text-white' }
                                border-round-right
                                border-1
                                surface-border
                                py-3
                                cursor-pointer
                                font-bold
                                ${dtoAprovacaoOrcamento.orcamentos[row.rowIndex].orcamento.status === 'APROVADO'? 'bg-white-alpha-10' : 'tzm-bg-red'}
                                transition-colors
                                transition-duration-500
                            `}
                            onClick={() => handleAprovacao(row.rowIndex)}
                        >
                            RECUSAR
                        </button>
            </div>
        )
    }       

    if (loading) return <Spinner basico/>;

    const handleSalvar = () => {
        setLoading(true);
        toast.promise(
            emailOrcamentoService.salvarDtoAprovacaoOrcamento({...dtoAprovacaoOrcamento, aprovadoOuRecusadoPorID: idDestinatario || null})
                .then(() => {
                    navigate('/public/orcamentosCliente/feedback')
                })
                .finally(() => setLoading(false)),
            {
                "pending": `Salvando aprovações de OS. Aguarde...`,
                "success": `Apovações salvas com sucesso salvo com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar aprovações de orçamento.`;
                    }
                }
            }
        );
    }

    const rowExpansionTemplate = (data) => {
        return (
            <PanelContent>
                <InputText
                    col={12}
                    value={data?.item?.problemaIndicado}
                    label='Problema Indicado'
                />
                <InputTextarea
                    col={12}
                    rows={1}
                    value={data?.item?.laudoExterno}
                    label='Laudo'
                />

                {   
                    data.item?.servicos?.length > 0 &&
                    <div className='xl:col-6 lg:col-6 md:col-12 sm:col-12'>
                        <DataTable 
                            value={data.item.servicos}
                            paginator={false}
                            footer={false}
                        >
                            <Column
                                field='servico.autoComplete'
                                header='Serviço executado'
                            />
                            <Column
                                field='quantidade'
                                header='Quantidade Aplicada'
                                style={{textAlign:'right'}}
                            />
                            <Column
                                field='valorUnitario'
                                header='Valor Individual'
                                style={{textAlign:'right'}}
                            />
                            <Column
                                field='valorTotal'
                                header='Valor Total'
                                style={{textAlign:'right'}}
                            />
                        </DataTable>
                    </div>
                }
                {   
                    data.item?.pecas?.length > 0 &&
                    <div className='xl:col-6 lg:col-6 md:col-12 sm:col-12'>
                        <DataTable 
                            value={data.item.pecas} 
                            paginator={false}
                            footer={false}
                        >
                            <Column
                                field='produto.autoComplete'
                                header='Peça substituida'
                            />
                            <Column
                                field='quantidade'
                                header='Quantidade Aplicada'
                                style={{textAlign:'right'}}
                            />
                            <Column
                                field='valorUnitario'
                                header='Valor Individual'
                                style={{textAlign:'right'}}
                            />
                            <Column
                                field='valorTotal'
                                header='Valor Total'
                                style={{textAlign:'right'}}
                            />
                        </DataTable>
                    </div>
                }
            </PanelContent>
        );
    };

    const salvar = () => {
        const messages = emailOrcamentoService.validar(dtoAprovacaoOrcamento);
        if (!messages.isEmpty()) {
            setMessages(messages);
            showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
            return;
        }
        setConfirmacao(true)
    }

	return (
		<FormEdit
			podeDeletar={false}
            podeVoltar={false}
			descricao="Formulário para aprovação de orçamentos"
			onValidate={setMessages}
			service={ordemServicoService}
			value={dtoAprovacaoOrcamento}
            salvar={salvar} 
		>   
            {
                dtoAprovacaoOrcamento?.clientes?.length === 1 
                ? 
                    <InputText
                        col={4}
                        label="Cliente"
                        value={dtoAprovacaoOrcamento.clienteFaturamento.razaoSocial}
                        readOnly
                    />                
                :    <Dropdown
                        col={4}
                        label='Selecione qual cliente irá efetuar o pagamento'
                        name='clienteFaturamento'
                        dataKey="id" 
                        options={dtoAprovacaoOrcamento?.clientes} 
                        optionLabel="autoComplete"
                        value={dtoAprovacaoOrcamento?.clienteFaturamento}
                        onChange={handleChange}
                        invalid={messages.clienteFaturamento}
                        onBlur={() => setMessages(messages => {
                            return {
                                ...messages, clienteFaturamento: null
                            }
                        })}
                    />
            }
            <InputText
                col={4}
                label='Número da Ordem de Compra'
                name='numeroPedidoCompra'
                value={dtoAprovacaoOrcamento?.numeroPedidoCompra}
                onChange={handleChange}
            />
            <InputTextarea
                col={12}
                rows={5}
                label='Observações'
                name='observacaoPedidoCompra'
                value={dtoAprovacaoOrcamento?.observacaoPedidoCompra}
                onChange={handleChange}
            />
            <div className='col-12'>
                <div className='flex align-items-center' >
                    <label className='mr-2'>Selecione a forma de faturamento:</label>
                    <div className="flex align-items-center mr-2">
                        <RadioButton 
                            inputId="r1" 
                            name="formaFaturamento" 
                            value="SERVICO" 
                            onChange={(e) => handleChange({value: e.value, name: e.target.name})} 
                            checked={dtoAprovacaoOrcamento?.formaFaturamento === 'SERVICO'} 
                        />
                        <label htmlFor="r1" className="ml-2">Serviço</label>
                    </div>
                    <div className="flex align-items-center">
                        <RadioButton 
                            inputId="r2" 
                            name="formaFaturamento" 
                            value="PRODUTO" 
                            onChange={(e) => handleChange({value: e.value, name: e.target.name})} 
                            checked={dtoAprovacaoOrcamento?.formaFaturamento === 'PRODUTO'} 
                        />
                        <label htmlFor="r2" className="ml-2">Produto</label>
                    </div>
                </div>
            </div>
            <div className='col-12'>
                <DataTable
                    emptyMessage='Nenhum orçamento encontrado.' 
                    paginator={true}                        
                    rows={10}       
                    value={dtoAprovacaoOrcamento?.orcamentos} 
                    valuelength={dtoAprovacaoOrcamento?.orcamentos?.length}   
                    header='Orçamentos' 
                    size='large'
                    expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                >
                    <Column expander style={{ width: '5rem' }} />
                    <Column
                        field='orcamento.numero'
                        header='Nº'
                        alignHeader={'center'}
                        style={{width: '3em'}}
                    />
                    <Column
                        field='orcamento.ordemServicoNumero'
                        header='OS Nº'
                        alignHeader={'center'}
                        style={{width: '7em', textAlign:'center'}}
                    />
                    <Column
                        field='orcamento.inicioOrdemServico'
                        header='Data de Inicio'
                        alignHeader={'center'}
                        style={{width: '10em', textAlign:'center'}}
                        body={data => <div>{moment(data.orcamento.inicioOrdemServico).format('DD/MM/YY HH:mm')} </div>}
                    />
                    {/* <Column
                       body={() => dtoAprovacaoOrcamento}
                        header='Validade'
                    /> */}
                    <Column
                        field='item.equipamento.autoComplete'
                        header='Equipamento'
                    />
                    <Column
                        field='item.equipamento.serial'
                        header='Nº de Série'
                    />
                    <Column
                        field='orcamento.responsavel.autoComplete'
                        header='Responsável'
                    />
                    <Column
                        header='Aprovação'
                        body={toggleTempalte}
                        style={{width: '10em'}}
                    />
                </DataTable>
            </div>
            <ConfirmDialog
                message={
                    <div>
                        {dtoAprovacaoOrcamento?.orcamentos?.some((orcamento => orcamento.orcamento.status === 'APROVADO'))
                            ? <div>
                                {`Os seguintes orçamentos estão sendo aprovados: Orçamento nº ${dtoAprovacaoOrcamento?.orcamentos?.filter(orcamento => orcamento.orcamento.status === 'APROVADO').map((orcamento) => orcamento.orcamento.numero ).join(", ")}.`}
                            </div>  
                            : ''
                        }
                        {dtoAprovacaoOrcamento?.orcamentos?.some((orcamento => orcamento.orcamento.status === 'RECUSADO'))
                            ? <div className='font-bold'>
                                {`Os seguintes orçamentos estão sendo recusados: Orçamento nº ${dtoAprovacaoOrcamento?.orcamentos?.filter(orcamento => orcamento.orcamento.status === 'RECUSADO').map((orcamento) => orcamento.orcamento.numero).join(", ")}.`}
                            </div>  
                            : ''
                        }

                        <div>
                            Deseja seguir com esta ação?
                        </div>

                    </div>    
                }
                accept={handleSalvar}
                reject={() => {
                    return
                }}
                visible={confirmacao}
                onHide={() => setConfirmacao(false)}
            />
		</FormEdit>
	);

}
