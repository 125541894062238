import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import React, {useEffect, useMemo, useState} from "react";
import {ServicoAutoComplete} from "../../components/autocomplete/servicoAutoComplete";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {Button} from "../../components/button";
import {Calendar} from "../../components/calendar";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {DataTable} from "../../components/datatable";
import {InputCurrency} from "../../components/inputnumber";
import {PanelContent} from "../../components/panel";
import {useAuth} from "../../context/AuthContext";
import {isStacked} from "../../context/BreakpointContext";
import {ordemServicoItemService} from "../../service/ordemServicoItemService";
import {servicoService} from "../../service/servicoService";
import {withDialog} from "../../utils/dialogContext";
import {formatCurrency} from "../../utils/numberFormatter";
import {InputText} from "../../components/inputtext";
import {
	codigosRessolda,
	isRessolda,
	optionsStatusRessolda,
	origensPreco,
	restricoesButtons
} from "../../service/ordemServicoService";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {SelectButton} from "../../components/selectbutton";
import {If} from "../../components/conditional/If";
import {Tooltip} from "primereact/tooltip";

export const EditarOrdemServicoItemServico = withDialog(({ordemServico, messages, index, item, handleChange, validarItem, showDialog}) => {

	const {roles} = useAuth();
	const [servicosSelecionados, setServicosSelecionados] = useState([]);
	const [sequencia, setSequencia] = useState(0);

	const isFranquia = useMemo(() => ordemServico.contrato?.valorFranquia > 0, [ordemServico.contrato]);
	const disableByOrcamento = !roles.OSSU && item.orcamentos?.some(osio => !["RECUSADO", "CANCELADO"].includes(osio.status));

	useEffect(() => {
		if (item.servicos?.length) {
			setSequencia(Math.max(...item.servicos.map(osip => osip.sequencia)) || 0);
		}
	}, [item.id]);

	function corretivaServico(osis, column) {
		return (
			<>
				<Calendar readOnly={disableByOrcamento} reduced label="Início" showTime col={3} placeholder="Início" value={osis.inicio} name="inicio" index={column.rowIndex} onChange={handleChangeServico}/>
				<Calendar readOnly={disableByOrcamento} reduced label="Término" showTime col={3} placeholder="Término" value={osis.fim} name="fim" index={column.rowIndex} onChange={handleChangeServico}/>
				<UsuarioAutoComplete readOnly={disableByOrcamento} reduced label="Responsável" col={6} placeholder="Responsável" index={column.rowIndex} value={osis.responsavel} name="responsavel" onChange={handleChangeServico}/>
				<InputText readOnly={disableByOrcamento} maxLength={3800} style={{height: "6em"}} reduced label="Observações" index={column.rowIndex} multiline col={12} placeholder="Informações adicionais" value={osis.observacoes} name="observacoes" onChange={handleChangeServico}/>
			</>
		);
	}
	
	async function handleChangeServico(event) {
		const servico = item.servicos[event.index];
		servico[event.name] = event.value;
		switch (event.name) {
			case "servico":
				if (event.value?.id) {
					const resposta = await servicoService.buscarPreco({
						servico: event.value.codigo,
						cliente: ordemServico.cliente?.id,
						ordemServico: ordemServico.id,
						contrato: ordemServico.contrato?.id,
						equipamento: item.equipamento?.id,
						data: ordemServico.inicio,
						mauUso: item.flags?.includes("MAU_USO"),
						quantidade: servico.quantidade
					});
					servico.valorUnitario = resposta.valorUnitario;
					servico.valorTabela = resposta.valorTabela;
					servico.valorFranquia = resposta.valorTabela;
					servico.origemPreco = resposta.origem;
					if (resposta.listaPrecoItemId) {
						servico.listaPrecoItem = {id: resposta.listaPrecoItemId};
					}
					servico.valorTotal = servico.valorUnitario * servico.quantidade;
				}
				break;
				case "valorUnitario":
				if (event.value) {					
					servico.valorTotal = event.value * servico.quantidade;
					servico.origemPreco = "MANUAL";
				} else {
					servico.valorTotal = 0;
				}
				break;
			case "quantidade":
				if (event.value) {
					servico.valorTotal = servico.valorUnitario * event.value;
				}
				break;
			default:
				break;
		}
		handleChange({name: "servicos", value: item.servicos});
	}

	function adicionarServico() {
		const servico = ordemServicoItemService.criarServico();
		servico.sequencia = sequencia + 1;
		item.servicos.push(servico);
		setSequencia(sequencia + 1);
		handleChange({name: "servicos", value: item.servicos});
	}

	function removerServicos() {
		if (servicosSelecionados.some(ps => isRessolda(ps.servico?.codigo) && ps.status !== "PENDENTE")) {
			showDialog(<InformationDialog header="Aviso" message="Não é possível remover serviços de recuperação de placa concluídos."/>);
			return;
		}
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja remover os serviços selecionados?" onYes={() => {
			item.servicos = item.servicos.filter(osi => !servicosSelecionados.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
			if (!item.id) {
				item.servicos.forEach((osi, i) => osi.sequencia = i + 1);
			}
			handleChange({name: "servicos", value: item.servicos});
			setServicosSelecionados([]);
			validarItem();
		}}/>);
	}


	const servicoControlSet = (
		<div>
			<Button disabled={disableByOrcamento || ordemServico.faturamentoData} icon="pi pi-plus" onClick={adicionarServico}/>
			<Button disabled={disableByOrcamento || !servicosSelecionados?.length || ordemServico.faturamentoData} onClick={removerServicos} danger icon="pi pi-minus"/>
		</div>
	);

	const servicoHeaderGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={roles.OSSO ? 4 : 3} header={servicoControlSet}/>
				{roles.OSSO ? <Column style={{width: "15em"}} headerStyle={{textAlign: "right"}} header={formatCurrency(item.servicos.map(osis => osis.valorTotal || 0).reduce((a, b) => a + b, 0))}/> : null}
			</Row>
			<Row>
				<Column selectionMode="multiple" headerStyle={{width: "3em"}}/>
				<Column style={{width: "6em"}} header="Item"/>
				<Column header="Dados Principais"/>
				{roles.OSSO ? <Column style={{width: "15em"}} header="Valor Unitário"/> : null}
				{roles.OSSO ? <Column style={{width: "15em"}} header="Valor Total"/> : null}
			</Row>
		</ColumnGroup>
	);

	function toggleStatus(osis, column) {
		return (
			<SelectButton pt={{button: ({context}) => ({className: context.selected ? osis.status === "CONCLUIDO" ? "sb-success" : "sb-unsuccess" : null})}}
						  index={column.rowIndex}
						  readOnly
						  itemTemplate={restricoesButtons}
						  label="&nbsp;"
						  col={2}
						  name="status"
						  value={osis.status}
						  options={optionsStatusRessolda}
			/>
		);
	}

	const servicoFooterGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={roles.OSSO ? 4 : 3} footer={servicoControlSet}/>
				{roles.OSSO ? <Column footerStyle={{textAlign: "right"}} footer={formatCurrency(item.servicos.map(osis => osis.valorTotal || 0).reduce((a, b) => a + b, 0))}/> : null}
			</Row>
		</ColumnGroup>
	);

	return (
		<PanelContent>
			<div className="col-12">
				<DataTable paginator
				           selection={servicosSelecionados}
				           onSelectionChange={e => setServicosSelecionados(e.value)}
				           value={item.servicos}
				           headerColumnGroup={servicoHeaderGroup}
				           footerColumnGroup={servicoFooterGroup}
				           selectionMode="checkbox"
				           header={isStacked() ? null : servicoControlSet}
				           footer={isStacked() ? null : servicoControlSet}
				>
					<Column selectionMode="multiple"/>
					<Column header="Item" field="sequencia"/>
					<Column header="Dados Principais" field="servico" style={{flexDirection: "column", alignItems: "start"}} body={(osis, column) => (
						<div className="grid">
							<ServicoAutoComplete readOnly={disableByOrcamento || ordemServico.faturamentoData} onBlur={validarItem} required dropdown={!disableByOrcamento} invalid={messages.itens?.[index]?.servicos?.[column.rowIndex]?.servico} label="Serviço" col={isRessolda(osis.servico?.codigo) ? 10 : 12} index={column.rowIndex} onChange={handleChangeServico} name="servico" value={osis.servico}/>
							<If condition={osis.status !== "PENDENTE" && isRessolda(osis.servico?.codigo)} children={toggleStatus(osis, column)}/>
							{ordemServico.tipo === "CORRETIVA" ? corretivaServico(osis, column) : null}
						</div>
					)}/>
					{
						roles.OSSO ? (
							<Column header="Valor Unitário" style={{flexDirection: "column", alignItems: "start"}} field="valorUnitario" body={(osis, column) => (
								<div>
									<InputCurrency readOnly={disableByOrcamento} label="Valor de Tabela" value={osis.valorTabela} name="valorTabela" index={column.rowIndex} onChange={handleChangeServico}/>
									<InputCurrency title={osis.status === "MALSUCEDIDO" ? "O serviço foi malsucedido e não deve ser cobrado" : ""} readOnly={disableByOrcamento || osis.status === "MALSUCEDIDO"} label="Valor Unitário" name="valorUnitario" index={column.rowIndex} value={osis.valorUnitario} onChange={handleChangeServico} invalid={messages.itens?.[index]?.servicos?.[column.rowIndex]?.valorUnitario} onBlur={validarItem}/>
									{isFranquia ? <InputCurrency readOnly={disableByOrcamento} label="Valor de Franquia" onBlur={validarItem} invalid={messages.itens?.[index]?.servicos?.[column.rowIndex]?.valorFranquia} name="valorFranquia" index={column.rowIndex} value={osis.valorFranquia} onChange={handleChangeServico}/> : null}
									{origensPreco[osis.origemPreco]}
								</div>
							)}
							/>
						): null
					}
					{roles.OSSO ? <Column header="Valor Total" field="valorTotal" style={{textAlign: "right"}} body={osis => formatCurrency(osis.valorTotal)}/> : null}
				</DataTable>
			</div>
			{disableByOrcamento ? <Tooltip target="input,textarea" content="Não é possível alterar serviços com orçamento em aberto" position="bottom"/> : null}
		</PanelContent>
	);

});
