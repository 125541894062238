import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {DataTable} from "../../components/datatable";
import {useAuth} from "../../context/AuthContext";
import {Column} from "primereact/column";
import {contagemExtensa, formatCurrency, formatDate, formatMinutes} from "../../utils/numberFormatter";
import {iconStatus, labelTipos, ordemServicoService} from "../../service/ordemServicoService";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {Button} from "../../components/button";
import {EditarAcompanhamento} from "./EditarAcompanhamento";
import {OrdemServicoOrcamentoEmail} from "./OrdemServicoOrcamentoEmail";
import moment from "moment";
import {withDialog} from "../../utils/dialogContext";
import {BlockUI} from "primereact/blockui";
import {imprimirEtiquetasLaudoZebra, imprimirEtiquetasOsZebra} from "../../service/etiquetaService";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {ReatribuirOrdemServico} from "./ReatribuirOrdemServico";
import {ParamContext} from "../../utils/ParamStore";
import {ProgressDialog} from "../../components/progressdialog/ProgressDialog";
import {optionsOperacaoComercial} from "../__Commons/FiltrosOrdemServicoComercial";
import {usePagesAndTabs} from "../../utils/PageAndTabContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";

export const TabelaOrdemServico = withDialog(({value, setHideFilters, showDialog}) => {

    const navigate = useNavigate();
    const {roles} = useAuth();

    const [ordemServico, setOrdemServico] = useState(null);
    const [acompanhamento, setAcompanhamento] = useState(null);
    const [servicosSelecionados, setServicosSelecionados] = useState([]);
    const [loadingAvancarEtapas, setLoadingAvancarEtapas] = useState(false);
    const {globalParams, handleResults} = useContext(ParamContext);
    const {pageAndTab, setPage, setScroll} = usePagesAndTabs(QueryParamsKeys.Comercial);

    function handleEditar(event) {
        if (!roles.OSSC) return;
        setScroll();
        navigate(`/ordens-servico/${event.data.id}`);
    }

    function avancarEtapas() {
        const ordensServico = [...servicosSelecionados];
        if (ordensServico.length) {
            const ordemServico = ordensServico[0];
            if (ordensServico.some(os => os.fluxo !== ordemServico.fluxo || os.etapa?.id !== ordemServico.etapa?.id)) {
                showDialog(<InformationDialog header="Informação" message="Não é possível avançar ordens de serviço em etapas ou fluxos em versões diferentes"/>);
            } else {
                setLoadingAvancarEtapas(true);
                ordemServicoService.buscar(ordemServico.id).then(ordemServico => {
                    setHideFilters(true);
                    setOrdemServico(ordemServico);
                    setAcompanhamento(ordemServicoService.criarAcompanhamento());
                    setLoadingAvancarEtapas(false);
                });
            }
        }
    }

    async function imprimirEtiquetasOs() {
        await imprimirEtiquetasOsZebra(servicosSelecionados);
    }

    async function imprimirEtiquetasLaudo() {
        await imprimirEtiquetasLaudoZebra(servicosSelecionados);
    }

    function enviarOrcamentos() {
        const ordensServico = [...servicosSelecionados];
        if (ordensServico.length) {
            const ordemServico = ordensServico[0];
            if (ordensServico.some(os => os.cliente?.id !== ordemServico.cliente?.id)) {
                showDialog(<InformationDialog header="Informação" message="Para o envio de orçamentos, todas as ordens de serviço selecionadas devem ser do mesmo cliente."/>);
            } else if (ordensServico.some(os => !os.countOrcamentoGerado)) {
                showDialog(<InformationDialog header="Informação" message="Para o envio de orçamentos, os orçamentos precisam já estarem gerados."/>);
            } else {
                showDialog(<OrdemServicoOrcamentoEmail ordensServico={ordensServico}/>);
            }
        }
    }

    function reatribuirOrdensServico() {
        showDialog(<ReatribuirOrdemServico key={Math.random()} ordensServico={[...servicosSelecionados]} onModalClose={() => setServicosSelecionados([])}/>);
    }

    const servicoControlSet = (
        <div>
            <Button style={{whiteSpace: "nowrap"}} icon="fa-solid fa-arrow-right" loading={loadingAvancarEtapas} disabled={!servicosSelecionados?.length || loadingAvancarEtapas} onClick={avancarEtapas} label="Avançar Etapas"/>
            <Button style={{whiteSpace: "nowrap"}} icon="fa-solid fa-envelope" disabled={!servicosSelecionados?.length || loadingAvancarEtapas} onClick={enviarOrcamentos} label="Enviar Orçamentos"/>
            <Button style={{whiteSpace: "nowrap"}} icon="fa-solid fa-users" disabled={!servicosSelecionados?.length} label="Reatribuir OS" onClick={reatribuirOrdensServico}/>
            <div style={{float: "right"}}>
                {!!roles.OSSR ? <Button style={{whiteSpace: "nowrap"}} icon="fa-solid fa-barcode" disabled={!servicosSelecionados?.length} onClick={imprimirEtiquetasOs} label="Etiqueta OS"/> : null}
                {!!roles.OSSR ? <Button clear style={{whiteSpace: "nowrap"}} icon="fa-solid fa-barcode" disabled={!servicosSelecionados?.length} onClick={imprimirEtiquetasLaudo} label="Etiqueta Laudo"/> : null}
            </div>
        </div>
    );

    const servicoHeaderGroup = (
        <ColumnGroup>
            <Row>
                <Column header={servicoControlSet} colSpan={8}/>
                <Column headerStyle={{textAlign: "right"}} header={"Quantidade: " + value?.length}/>
                {roles.OSSO ? <Column headerStyle={{textAlign: "right"}} header={formatCurrency(value?.map(os => os.valorTotal || 0).reduce((a, b) => a + b, 0))}/> : null}
            </Row>
            <Row>
                <Column selectionMode="multiple" headerStyle={{width: "3em"}}/>
                <Column sortable field="status" headerStyle={{width: "3em", textAlign: "center"}} header=""/>
                <Column sortable field="numero" header="Nº"/>
                <Column sortable field="inicio" header="Inicio" alignHeader='center'/>
                <Column sortable field="previsaoAtendimento" header="Previsão" alignHeader='center'/>
                <Column sortable field="etapa.descricao" header="Etapa"/>
                <Column sortable field="tipo" header="Tipo"/>
                <Column sortable field="cliente.nomeFantasia" header="Cliente"/>
                <Column sortable field="equipamento.serial" header="Equipamento"/>
                {roles.OSSO ? <Column sortable field="valorTotal" header="Valor Total"/> : null}
            </Row>
        </ColumnGroup>
    );

    const servicoFooterGroup = (
        <ColumnGroup>
            <Row>
                <Column colSpan={9}/>
                {roles.OSSO ? <Column footerStyle={{textAlign: "right"}} footer={formatCurrency(value?.map(os => os.valorTotal || 0).reduce((a, b) => a + b, 0))}/> : null}
            </Row>
        </ColumnGroup>
    );

    async function salvarAcompanhamento(acompanhamento) {
        showDialog(<ProgressDialog onProgress={async setProgresso => {
            let progresso = 0;
            for (const ordemServico of servicosSelecionados) {
                await ordemServicoService.buscar(ordemServico.id).then(async ordemServico => {
                    ordemServico.acompanhamentos.push(acompanhamento);
                    ordemServico.etapa = acompanhamento.etapa;
                    ordemServico.responsavel = acompanhamento.responsavel;
                    if (acompanhamento.etapa?.status) {
                        ordemServico.status = acompanhamento.etapa.status;
                    }
                    await ordemServicoService.salvar(ordemServico);
                    await ordemServicoService.atualizarValoresOrdemServico(ordemServico.id);
                });
                ++progresso;
                setProgresso(progresso / servicosSelecionados.length);
            }
            voltarAcompanhamento();
            ordemServicoService.listarOrdensServico(globalParams.OrdemServico).then(ordensServico => handleResults("OrdemServico", ordensServico));
        }}/>);
    }

    function voltarAcompanhamento() {
        setOrdemServico(null);
        setAcompanhamento(null);
        setServicosSelecionados([]);
        setHideFilters(false);
    }

    if (acompanhamento && ordemServico) {
        return (
            <EditarAcompanhamento multiple
                                  ordemServico={ordemServico}
                                  acompanhamento={acompanhamento}
                                  setAcompanhamento={setAcompanhamento}
                                  onVoltar={voltarAcompanhamento}
                                  onSalvar={salvarAcompanhamento}
            />
        );
    }

    const onSelectionChange = (e, e2) => {
        setServicosSelecionados(e.value)
    }

    const numeroTemplate = (data) => {
        let operacao = optionsOperacaoComercial.find(op => op.value === data.operacao);
        return (
            <div className="flex">
                <i style={{color: `${operacao.color}`}} className={`fa-solid ${operacao.icon}`} title={operacao.label}/>
                <b className="ml-2">{data.numero}</b>
            </div>   
        );
    }

    return (
        <BlockUI blocked={loadingAvancarEtapas}>
            <DataTable
                value={value}
                onRowDoubleClick={handleEditar}
                valuelength={value?.length}
                selection={servicosSelecionados}
                onSelectionChange={onSelectionChange}
                selectionMode="checkbox"
                first={20 * pageAndTab.page}
                onPage={e => setPage(e.page)}
                headerColumnGroup={servicoHeaderGroup}
                footerColumnGroup={servicoFooterGroup}
            >
                <Column selectionMode="multiple" style={{cursor: "default"}}/>
                <Column sortable field="status" style={{textAlign: "center", cursor: "default"}} body={os => iconStatus[os.status]}/>
                <Column sortable field="numero" style={{textAlign: "right", cursor: "default"}} body={numeroTemplate}/>
                <Column sortable field="inicio" style={{textAlign: "center", cursor: "default"}} body={r => formatDate(r.inicio)}/>
                <Column sortable field="previsaoAtendimento" style={{textAlign: "center", cursor: "default"}} body={r => formatDate(r.previsaoAtendimento)}/>
                <Column sortable field="etapa.descricao" style={{cursor: "default"}} body={os => (
                    <div>
                        <a id={os.id}/>
                        <div>{os.etapa?.descricao + " " + ((os.ultimoAcompanhamento && `(${formatMinutes(Math.abs(moment().diff(os.ultimoAcompanhamento.inicio, "minutes")))})`) || "")}</div>
                        <div>{os.responsavel.nome}</div>
                        <div>{os.ultimoAcompanhamento ? moment(os.ultimoAcompanhamento.inicio).format("DD/MM/YYYY HH:mm:ss") : null}</div>
                        <div>{os.ultimoAcompanhamento ? os.ultimoAcompanhamento.observacoes : null}</div>
                        <div>
                            <span style={{marginRight: "1em"}} title="Orçamentos Gerados"><i style={{color: "#FF9800"}} className="fa-solid fa-file-pdf"/> {os.countOrcamentoGerado}</span>
                            <span style={{marginRight: "1em"}} title="Orçamentos Enviados"><i style={{color: "#2196F3"}} className="fa-solid fa-envelope"/> {os.countOrcamentoEnviado}</span>
                            <span style={{marginRight: "1em"}} title="Orçamentos Aprovados"><i style={{color: "#4CAF50"}} className="fa-solid fa-check"/> {os.countOrcamentoAprovado}</span>
                            <span style={{marginRight: "1em"}} title="Orçamentos Recusados"><i style={{color: "#E91E63"}} className="fa-solid fa-times"/> {os.countOrcamentoRecusado}</span>
                        </div>
                    </div>
                )}/>
                <Column sortable style={{cursor: "default"}} field="tipo" body={os => labelTipos[os.tipo]}/>
                <Column sortable style={{cursor: "default"}} field="cliente.nomeFantasia" body={os => (
                    <div>
                        <div style={{fontWeight: "bold"}}>{os.cliente.nomeFantasia}</div>
                        <div>{os.cliente.endereco}</div>
                    </div>
                )}/>
                <Column sortable field="equipamento.produto.descricao" style={{cursor: "default"}} body={os => (
                    os.tipo === "CORRETIVA" ? (
                        <div>
                            <div>{os.equipamento?.produto?.descricao}</div>
                            <div>N/S: {os.equipamento?.serial}</div>
                            {os.ultimaMovimentacao ? (
                                <div>
                                    <div style={{borderTop: "1px solid rgba(0, 0, 0, .2)", margin: ".5em 0"}}/>
                                    <div style={{fontStyle: "italic"}}>Última movimentação:</div>
                                    <div>{os.ultimaMovimentacao.prateleira?.descricao} ({os.ultimaMovimentacao.responsavel.nome}) {moment(os.ultimaMovimentacao.registro).format("DD/MM/YYYY HH:mm")}</div>
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        <div style={{fontStyle: "italic"}}>{contagemExtensa(os.equipamentos, "equipamento")}</div>
                    )
                )}/>
                {roles.OSSO ? <Column sortable field="valorTotal" style={{textAlign: "right" , cursor: "default"}} body={os => formatCurrency(os.valorTotal)}/> : null}
            </DataTable>
        </BlockUI>
    );

});
