import React from "react";
import {BlockUI} from "primereact/blockui";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../../components/panel";
import {filterStatusPeca} from "../../../service/backofficeEstoqueService";
import {InputNumber} from "../../../components/inputnumber";
import {ClienteAutoComplete} from "../../../components/autocomplete/ClienteAutoComplete";
import {ProdutoAutoComplete} from "../../../components/autocomplete/produtoAutoComplete";
import {Dropdown} from "../../../components/dropdown";
import {RefetchIndicator} from "../../../components/refetchindicator/RefetchIndicator";
import {DataTable} from "../../../components/datatable";
import {Column} from "primereact/column";
import {useNavigate} from "react-router-dom";
import {CardCliente} from "../../../components/cards/CardCliente";
import {CardOrdemServico} from "../../../components/cards/CardOrdemServico";
import {formatNumber} from "chart.js/helpers";
import {CardSolicitacaoPeca} from "../../../components/cards/CardSolicitacaoPeca";
import {Checkbox} from "../../../components/checkbox";
import {If} from "../../../components/conditional/If";
import {sum} from "../../../utils/mathUtils";
import {useQueryClientBackofficeEstoque} from "../../../utils/query-clients/queryClients";
import {useQueryParams} from "../../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../../utils/defaultQueryParams";
import {SearchButton} from "../../../components/button/SearchButton";

function tabelaTotais(data) {

    if (!data?.length) {
        return "";
    }

    const counters = {
        solicitado: 0,
        movimentado: 0,
        compras: 0,
        cancelado: 0
    };

    data.forEach(os => os.pecas?.forEach(osip => ++counters[osip.status.toLowerCase()]));

    return (
        <>
            <div style={{float: "right"}}>
                <table style={{color: "#666666"}}>
                    <tbody>
                        <tr>
                            <td>Solicitados:</td>
                            <td style={{minWidth: "5em", textAlign: "right", borderRight: "1px solid #666666", padding: "0 .5em"}}>{formatNumber(counters.solicitado || 0)}</td>
                            <td style={{padding: "0 .5em"}}>Entregues:</td>
                            <td style={{minWidth: "5em", textAlign: "right"}}>{formatNumber(counters.movimentado || 0)}</td>
                        </tr>
                        <tr>
                            <td>Compras:</td>
                            <td style={{minWidth: "5em", textAlign: "right", borderRight: "1px solid #666666", padding: "0 .5em"}}>{formatNumber(counters.compras || 0)}</td>
                            <td style={{padding: "0 .5em"}}>Cancelados:</td>
                            <td style={{minWidth: "5em", textAlign: "right", paddingBottom: ".5em"}}>{formatNumber(counters.cancelado || 0)}</td>
                        </tr>
                        <tr style={{borderTop: "1px solid #666666"}}>
                            <td colSpan={2}>Total de registros: </td>
                            <td colSpan={2} style={{textAlign: "right", paddingTop: ".5em"}}>{formatNumber(Object.values(counters).reduce(...sum) || 0)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{clear: "both"}}/>
        </>
    );

}

export function BackOfficeEstoque() {

    const navigate = useNavigate();
    const {params, handleChange} = useQueryParams(QueryParamsKeys.BackofficeEstoque);
    const {query, handleSearch} = useQueryClientBackofficeEstoque();

    function handleEditar(event) {
        navigate(`/backoffice/estoque/movimentar?numero=${event.data.numero}`);
    }

    return (
        <BlockUI blocked={query.isLoading}>
            <Panel header="BackOffice - Estoque">
                <PanelContent>
                    <div className="col-2" style={{margin: 0, padding: 0}}>
                        <Dropdown reduced options={filterStatusPeca} col={12} name="status" value={params.status} label="Status da Peça" onChange={handleChange}/>
                        <If condition={params.status === "SOLICITADO"} children={<Checkbox style={{marginTop: ".25em"}} label="Incluir compras já disponíveis" name="disponiveis" value={params.disponiveis} onChange={handleChange}/>}/>
                    </div>
                    <InputNumber col={2} label="Nº da OS" name="numero" value={params.numero} onChange={handleChange}/>
                    <ClienteAutoComplete forceSelection={false} col={4} name="cliente" value={params.cliente} onChange={handleChange}/>
                    <ProdutoAutoComplete col={4} name="produto" value={params.produto} onChange={handleChange}/>
                </PanelContent>
                <PanelFooter>
                    <SearchButton query={query} onSearch={handleSearch}/>
                </PanelFooter>
            </Panel>
            <DataTable header={(
                <div>
                    {tabelaTotais(query.data)}
                </div>
            )} value={query.data || []} onRowDoubleClick={handleEditar}>
                <Column field="numero" header="Nº da OS" body={os => <CardOrdemServico hideOrcamento ordemServico={os}/>}/>
                <Column header="Cliente" field="cliente.autoComplete" body={os => <CardCliente cliente={os.cliente} ordemServico={os}/>}/>
                <Column header="Peças" body={os => os.pecas.map(osip => <CardSolicitacaoPeca key={osip.id} peca={osip}/>)}/>
            </DataTable>
            <RefetchIndicator isRefetching={query.isRefetching}/>
        </BlockUI>
    );

}
